import React, {Component} from 'react';
import './sidebar.scss';
import {Link} from "react-router-dom";

class SidebarAppOverview extends Component {

    render() {

        return (
            <div>


                <ul>
                    <li>
                        <Link to="/apps">My Apps</Link>
                    </li>

                    <li>
                        <a
                            href="https://app9.freshdesk.com/support/home"
                            target="_blank"
                            rel="noopener noreferrer"
                        >Help</a>
                    </li>


                    <li className="divider"/>

                    <li>
                        <Link to="/settings">Settings</Link>
                    </li>
                </ul>
            </div>
        );
    }
}


export default SidebarAppOverview;
