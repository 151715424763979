import {
    FETCH_IMAGES,
    RECEIVE_IMAGES,
    FETCH_STOCK_IMAGES,
    RECEIVE_STOCK_IMAGES
} from '../actions/imageActions';


function imageReducer(
    state = {
        isFetchingImages: false,
        images: null,
        isFetchingStockImages: false,
        queryResult: null
    },
    action
) {
    switch (action.type) {
        case FETCH_IMAGES:
            return Object.assign({}, state, {
                images: null,
                isFetchingImages: true
            });

        case RECEIVE_IMAGES:
            return Object.assign({}, state, {
                isFetchingImages: false,
                images: action.images
            });

        case FETCH_STOCK_IMAGES:
            return Object.assign({}, state, {
                queryResult: null,
                isFetchingStockImages: true
            });

        case RECEIVE_STOCK_IMAGES:
            return Object.assign({}, state, {
                isFetchingStockImages: false,
                queryResult: action.queryResult
            });

        default:
            return state
    }
}

export default imageReducer;