import React, {Component} from 'react';
import ScreenshotLanguageBlock from './ScreenshotLanguageBlock';
import SelectControl from '../../Components/SelectControl';

class Screenshots extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedScreenId: null
        }
    };

    componentDidMount() {
        const {project} = this.props;
        const screens = project.getIn(['data', 'screens']);

        if (screens.size > 0) {
            this.setState({
                selectedScreenId: screens.getIn([0, 'id'])
            });
        }
    };

    handleScreenChange = (screenId) => {
        this.setState({
            selectedScreenId: screenId
        });
    };

    render() {
        const {project, sizes, screenshots} = this.props;
        const {selectedScreenId} = this.state;

        return (
            <div className="editor-sidebar sidebar-position">
                <div className="sidebar-header">
                    <p>Screenshots</p>
                </div>

                {selectedScreenId == null &&
                <div className="sidebar-section">
                    <p className="heading">Upload Screenshots</p>

                    <div className="sidebar-section-inner">
                        <p>Please add a screen first...</p>
                    </div>
                </div>
                }

                {selectedScreenId != null &&
                <div>
                    <div className="sidebar-section">
                        <p className="heading">Screen</p>

                        <div className="sidebar-section-inner">

                            <SelectControl
                                value={selectedScreenId}
                                onChange={this.handleScreenChange}
                            >
                                {project.getIn(['data', 'screens']).map((screen, idx) => (
                                    <option key={screen.get('id')} value={screen.get('id')}>{screen.get('name')}</option>
                                ))}
                            </SelectControl>
                        </div>
                    </div>

                    {project.get('languages').map(languageCode => {
                        return (
                            <ScreenshotLanguageBlock
                                key={languageCode}
                                project={project}
                                sizes={sizes}
                                screenshots={screenshots}
                                selectedScreenId={selectedScreenId}
                                languageCode={languageCode}
                            />
                        )
                    })}
                </div>
                }




            </div>
        );
    }
}

export default Screenshots;