import React, {Component} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import Card from "../../../Components/Card/Card";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import auth0Client from "../../../Auth";
import {apiHost} from '../../../constants';
import Page from '../../../Components/Page/Page';
import {Button} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTrashAlt} from '@fortawesome/free-solid-svg-icons'


class SettingsAdvanced extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isDeletingAccount: false
        };
    }

    deleteAccountClick = () => {
        if (window.confirm('Do you really want to delete your account? This cannot be undone.')) {
            this.setState({isDeletingAccount: true});

            return fetch(apiHost + '/account/delete', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${auth0Client.getIdToken()}`,
                    'Content-Type': 'application/json'
                }
            })
                .then(() => {
                    //auth0Client.signOut();
                    window.location.href = 'https://screenshotbuilder.com';
                })
                .catch(err => {
                    console.error('delete account error:', err);
                });
        }
    };


    render() {
        const {isDeletingAccount} = this.state;
        const {currentUser, accountInfo} = this.props;

        if (currentUser != null) {
            return (
                <div className="main">
                    <Sidebar
                        page="settings"
                        subpage="advanced"
                        currentUser={currentUser}
                        accountInfo={accountInfo}
                    />

                    <Page
                        className="page-settings-advanced"
                        currentUser={currentUser}
                        accountInfo={accountInfo}
                    >
                        <Container fluid={true}>
                            <Row>
                                <Col sm={12}>
                                    <h1>Settings - Advanced</h1>

                                    <Card header="Delete account" className="card-delete-account">
                                        <p>If you delete your account:</p>
                                        <ul>
                                            <li>All account information will be deleted, including all apps and
                                                projects.
                                            </li>
                                            <li>Our helpdesk will close any open cases without any further possibility
                                                for our team to respond.
                                            </li>
                                            <li>You will no longer have access to App9.</li>
                                        </ul>

                                        <p>This action cannot be undone!</p>

                                        <Button
                                            variant='danger'
                                            loading={isDeletingAccount}
                                            onClick={this.deleteAccountClick}
                                        >
                                            <FontAwesomeIcon icon={faTrashAlt}/>
                                            Delete account
                                        </Button>
                                    </Card>

                                </Col>
                            </Row>

                        </Container>
                    </Page>
                </div>
            );
        } else {
            return (
                <div/>
            )
        }
    }
}

export default SettingsAdvanced;