import React, {Component} from 'react';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Button from "../Button/Button";


class Library extends Component {
    render() {
        const {isFetchingImages, images} = this.props;

        if (!isFetchingImages && images != null) {
            return (
                <div className="image-library-page library-page">
                    <h1>User Library</h1>
                    <div className="images-container">
                        {images.map(image => (
                            <div
                                className="image-container"
                                key={image.get('uuid')}
                                style={{backgroundImage: `url(${image.get('url')})`}}

                            >

                                <div className="btn-container">
                                    <a
                                        href={image.get('url')}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        className="btn btn-primary"
                                    >View</a>

                                    <Button
                                        className="btn-primary"
                                        onClick={()=>this.props.onImageSelected(image)}
                                    >Use</Button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            );
        } else {
            return (
                <LoadingSpinner/>
            )
        }
    }
}

export default Library;