import {
    FETCH_APPS,
    RECEIVE_APPS,
    UPDATE_APP
} from '../actions/appActions';


function appReducer(
    state = {
        isFetchingApps: false,
        apps: null
    },
    action
) {
    switch (action.type) {
        case FETCH_APPS:
            return Object.assign({}, state, {
                isFetchingApps: true,
                apps: null
            });

        case RECEIVE_APPS:
            return Object.assign({}, state, {
                isFetchingApps: false,
                apps: action.apps
            });

        case UPDATE_APP:
            let apps = state.apps;
            for (let i = 0; i < apps.size; i++) {
                if (apps.getIn([i, 'uuid']) === action.newApp.get('uuid')) {
                    apps = apps.set(i, action.newApp);
                    break;
                }
            }

            return Object.assign({}, state, {
                apps: apps
            });

        default:
            return state
    }
}


export default appReducer;