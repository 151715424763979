function tokenizeText(text) {
    const regex = /\[(.*?)\]/gi;
    const matches = text.matchAll(regex);

    let prelimTokens = [];

    let lastEnd = 0;

    for (const match of matches) {
        let textLength = match.index - lastEnd;
        if (textLength > 0) {
            prelimTokens.push({
                type: 'text',
                text: text.substring(lastEnd, match.index)
            });
        }

        prelimTokens.push({
            type: 'format',
            text: match[0]
        });

        lastEnd = match.index + match[0].length;
    }

    let tokens = [];
    prelimTokens.forEach(token => {
        if (token.type === 'format') {
            tokens.push(token);
        }
        else {
            const characters = token.text.split('');

            let words = [];
            let word = '';
            for (let i = 0; i < characters.length; i++) {
                if (characters[i] === ' ') {
                    if (word.length > 0) {
                        words.push(word);
                    }
                    word = '';
                    words.push(' ');
                }
                else if (characters[i] === '\n') {
                    if (word.length > 0) {
                        words.push(word);
                    }
                    word = '';
                    words.push('\n');
                }
                else {
                    word += characters[i];
                }
            }

            if (word.length > 0) {
                words.push(word);
            }

            words.forEach(word => {
                tokens.push({
                    type: 'text',
                    text: word
                });
            });
        }
    });

    return tokens;
}

function foldFormatIntoTokens(text, screen, size) {
    let tokens = tokenizeText(text);

    let foldedTokens = [];
    let fontFamily = screen.getIn(['headingStyles', 'fontFamily']);
    let scaleFactor = size.get('scale_factor');
    let fontSize = Math.floor(screen.getIn(['headingStyles', 'fontSize']) * scaleFactor);
    let fontWeight = screen.getIn(['headingStyles', 'fontWeight']);
    let fontStyle = screen.getIn(['headingStyles', 'fontStyle']);
    let textDecoration = screen.getIn(['headingStyles', 'textDecoration']);
    let color = screen.getIn(['headingStyles', 'color']);

    tokens.forEach(token => {
        if (token.type === 'text') {
            foldedTokens.push({
                text: token.text,
                font:  fontWeight + ' ' + fontStyle + ' ' + fontSize + 'px ' + fontFamily,
                fontSize: fontSize,
                color: color,
                textDecoration: textDecoration
            });
        }
        else if (token.type === 'format') {
            const colorRegex = /\[color=(.*?)\]/i;
            const colorMatch = token.text.match(colorRegex);

            const sizeRegex = /\[size=(.*?)\]/i;
            const sizeMatch = token.text.match(sizeRegex);

            if (token.text === '[b]') {
                fontWeight = 'bold';
            }
            else if (token.text === '[/b]') {
                fontWeight = screen.getIn(['headingStyles', 'fontWeight']);
            }
            else if (token.text === '[i]') {
                fontStyle = 'italic';
            }
            else if (token.text === '[/i]') {
                fontStyle = screen.getIn(['headingStyles', 'fontStyle']);
            }
            else if (token.text === '[u]') {
                textDecoration = 'underline';
            }
            else if (token.text === '[/u]') {
                textDecoration = screen.getIn(['headingStyles', 'textDecoration']);
            }
            else if (sizeMatch != null) {
                fontSize = sizeMatch[1];
            }
            else if (token.text === '[/size]') {
                fontSize = screen.getIn(['headingStyles', 'fontSize']);
            }
            else if (colorMatch != null) {
                color = colorMatch[1];
            }
            else if (token.text === '[/color]') {
                color = screen.getIn(['headingStyles', 'color']);
            }
        }
    });

    return foldedTokens;


}

export function parseText(text, screen, size, canvas, ctx) {
    let _text = '[text]' + text + '[/text]';
    const tokens = foldFormatIntoTokens(_text, screen, size);
    const letterSpacing = screen.getIn(['headingStyles', 'letterSpacing']);

    const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

    for (let i=0; i<tokens.length; i++) {
        ctx.font = tokens[i].font;
        ctx.fillStyle = tokens[i].color;
        const textMetrics = ctx.measureText(tokens[i].text);
        tokens[i].width = textMetrics.width + letterSpacing;

        const alphabetTextMetrics = ctx.measureText(alphabet);
        tokens[i].height = alphabetTextMetrics.actualBoundingBoxAscent + alphabetTextMetrics.actualBoundingBoxDescent;
        tokens[i].baselineBelow = alphabetTextMetrics.actualBoundingBoxAscent + alphabetTextMetrics.actualBoundingBoxDescent;
    }

    let lines = [];
    let paddingH = screen.getIn(['styles', 'paddingHPct']) * canvas.width;
    const maxLineWidth = canvas.width - 2 * paddingH;
    let line = {
        width: 0,
        height: 0,
        tokens: []
    };
    lines.push(line);

    tokens.forEach(token => {

        if ((line.width + token.width > maxLineWidth) || (token.text === '\n')) {
            line = {
                width: token.width,
                height: token.height,
                tokens: [token]
            };
            lines.push(line);


        } else {
            line.width += token.width;
            line.height = Math.max(line.height, token.height);
            line.tokens.push(token);
        }
    });

    return lines;
}