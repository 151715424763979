const shortid = require('shortid');
const {fromJS} = require('immutable');


export function getProject(projects, projectUUID) {
    let projectIdx;
    for (projectIdx = 0; projectIdx < projects.size; projectIdx++) {
        if (projects.getIn([projectIdx, 'uuid']) === projectUUID) {
            break;
        }
    }
    return projects.get(projectIdx);
}

export function getDefaultScreen() {
    let screen = {
        id: '_' + shortid.generate(),
        name: 'Screen',
        overwrite: [],
        orientation: 'portrait',
        styles: {
            paddingHPct: 0.05,
            paddingTop: 50,
            paddingHeadingFrame: 50,
            minHeadingHeight: 100
        },
        heading: 'Create [b]Beautiful[/b] Screen [i]Headings[/i] with [u][color=yellow]Tags[/color][/u]',
        headingStyles: {
            textAlign: 'center',
            lineHeight: 1.2,
            textDecoration: 'none',
            fontStyle: 'normal',
            color: '#ffffff',
            fontSize: 40,
            fontWeight: 400,
            fontFamily: 'Roboto',
            letterSpacing: 0
        },
        frame: {
            style: 'flatWhite',
            shadow: {
                type: 'real',
                offsetX: 5,
                offsetY: 5,
                color: 'rgba(184,164,164,1)',
                blur: 30

            }
        },
        backgroundColor: {
            type: 'gradient',
            color: '#daedf7',
            gradient: {
                angle: 180,
                stops: [
                    {offset: 0.00, color: '#ffe158'},
                    {offset: 1.00, color: '#ffa951'}
                ]
            },
            imageUrl: 'https://app9-public.s3.us-east-2.amazonaws.com/images/stock/bg1.jpg'
        }
    };

    return fromJS(screen);
}

