import {
    FETCH_INVOICES,
    RECEIVE_INVOICES
} from '../actions/invoiceActions';


function websiteReducer(
    state = {
        isFetchingInvoices: false,
        invoices: null
    },
    action
) {
    switch (action.type) {
        case FETCH_INVOICES:
            return Object.assign({}, state, {
                isFetchingInvoices: true,
                invoices: null
            });

        case RECEIVE_INVOICES:
            return Object.assign({}, state, {
                isFetchingInvoices: false,
                invoices: action.invoices
            });

        default:
            return state
    }
}


export default websiteReducer;