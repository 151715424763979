import React, {Component} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import Button from '../../../Components/Button/Button';
import {getDefaultScreen} from "../editorUtils";


class NoScreen extends Component {

    handleAddScreen = () => {
        const {project} = this.props;
        const newScreen = getDefaultScreen();
        const screens = project.getIn(['data', 'screens']).push(newScreen);
        const newProject = project.setIn(['data', 'screens'], screens);
        this.props.onProjectChange(newProject);
        this.props.onScreenClick(newScreen.get('id'));
    };

    render() {
        return (
            <div className="no-screen-container">
                <div className="btn-container">
                    <Button
                        className="btn-primaryNew"
                        onClick={this.handleAddScreen}
                    >
                        <FontAwesomeIcon icon={faPlus}/>
                        Add Your First Screen
                    </Button>
                </div>
            </div>

        )
    }
}

export default NoScreen;