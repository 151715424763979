import React, {Component} from 'react';


class SelectControl extends Component {

    handleChange = (e) => {
        this.props.onChange(e.target.value);
    };


    render() {
        const {value} = this.props;
        const {name} = this.props;

        return (
            <div className="select-control">

                {name && (<label>{name}</label>)}

                <div className="select-control-inner">
                    <i className="material-icons">arrow_drop_down</i>
                    <select
                        value={value}
                        onChange={this.handleChange}
                    >
                        {this.props.children}
                    </select>
                </div>
            </div>
        );
    }
}


export default SelectControl;
