import 'whatwg-fetch'
import auth0Client from "../Auth";
import {apiHost} from '../constants';

const { fromJS } = require('immutable');


export const FETCH_INVOICES = 'FETCH_INVOICES';
export const RECEIVE_INVOICES = 'RECEIVE_INVOICES';


export function requestInvoices() {
    return {
        type: FETCH_INVOICES
    }
}


export function receiveInvoices(invoices) {
    return {
        type: RECEIVE_INVOICES,
        invoices
    }
}


export function fetchInvoices() {
    return (dispatch, getState) => {
        const state = getState();

        if (!state.isFetchingInvoices) {
            dispatch(requestInvoices());
            return fetch(apiHost + '/account/invoices', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${auth0Client.getIdToken()}`
                }
            })
                .then(response => response.json())
                .then(json => {
                    const invoices = fromJS(json);
                    dispatch(receiveInvoices(invoices));
                })
                .catch(err => {
                    console.error('fetchInvoices error:', err);
                });
        }
        else {
            return Promise.resolve()
        }
    }
}