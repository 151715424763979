import React, {Component} from 'react';
import './sidebar.scss';
import {Link} from "react-router-dom";

class SidebarApps extends Component {

    render() {

        return (
            <div>
                <div className="main-menu-link-container">
                    <Link to="/apps">
                        <span className="arrow-container">
                            <svg viewBox="0 0 24 24">
                                <path d="M14.05 16.25l-3.749-3.749L14.05 8.75 13.3 8l-4.5 4.501 4.5 4.5z"/>
                            </svg>
                        </span>
                        Apps
                    </Link>
                </div>

                <ul>
                    <li className="divider"/>
                </ul>
                <ul>

                    <li>
                        <Link to="/settings">Settings</Link>
                    </li>
                </ul>
            </div>
        );
    }
}


export default SidebarApps;
