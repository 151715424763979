import React, {Component} from 'react';
import './page.scss';

const classNames = require('classnames');


class Page extends Component {

    render() {
        return (
            <div className={classNames('page', this.props.className)}>

                <div className="page-inner">

                    {this.props.children}

                </div>

            </div>
        );
    }
}


export default Page;