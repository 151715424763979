import React, {Component} from 'react';
import Screen from "./Screen/Screen";
import NoScreen from './NoScreen';

const classNames = require('classnames');

const defaultWidth = 250;


class Screens extends Component {

    getContainerStyle = () => {
        const {project, zoomFactor} = this.props;

        const columnWidth = Math.floor(defaultWidth * zoomFactor) + 'px';
        let columns = [];
        for (let i = 0; i < project.getIn(['data', 'screens']).size; i++) {
            columns.push(columnWidth);
        }
        return {gridTemplateColumns: columns.join(' ')};
    };

    render() {
        const {project, sizes, screenshots, frameImages, activeScreen, renderingScreenItems} = this.props;

        if (project.getIn(['data', 'screens']).size > 0) {

            return (
                <div className="screens-container-outer">
                    <div className={classNames('screens-container')} style={this.getContainerStyle()}>
                        {project.getIn(['data', 'screens']).map((screen, idx) => (
                            <Screen
                                key={screen.get('id')}
                                screenIdx={idx}
                                screen={screen}
                                project={project}
                                sizes={sizes}
                                screenshots={screenshots}
                                frameImages={frameImages}
                                activeScreen={activeScreen}
                                renderingScreenItems={renderingScreenItems}
                                onScreenClick={this.props.onScreenClick}
                            />
                        ))}
                    </div>
                </div>

            )
        }
        else {
            return (
                <NoScreen
                    project={project}
                    onProjectChange={this.props.onProjectChange}
                    onScreenClick={this.props.onScreenClick}
                />
            )
        }
    }
}

export default Screens;