import React, {Component} from 'react';
import './sidebar.scss';
import {Link} from "react-router-dom";

class SidebarSettings extends Component {

    render() {
        const {subpage} = this.props;

        return (
            <div>
                <div className="main-menu-link-container">
                    <Link to={'/apps'}>
                        <span className="arrow-container">
                            <svg viewBox="0 0 24 24">
                                <path d="M14.05 16.25l-3.749-3.749L14.05 8.75 13.3 8l-4.5 4.501 4.5 4.5z"/>
                            </svg>
                        </span>
                        Apps
                    </Link>
                </div>

                <ul>
                    <li className="divider"/>
                </ul>
                <ul>
                    <li className={subpage === 'general' ? 'active' : ''}>
                        <Link to="/settings">General</Link>
                    </li>

                    <li className={subpage === 'billing' ? 'active' : ''}>
                        <Link to="/settings/billing">Billing</Link>
                    </li>

                    <li className={subpage === 'advanced' ? 'active' : ''}>
                        <Link to="/settings/advanced">Advanced</Link>
                    </li>

                    <li className="divider"/>

                    <li>
                        <a
                            href="https://app9.freshdesk.com/support/home"
                            target="_blank"
                            rel="noopener noreferrer"
                        >Help</a>
                    </li>
                </ul>
            </div>
        );
    }
}


export default SidebarSettings;
