import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {ReactComponent as RobotImage} from './robot.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


class NotFound extends Component {

    render() {

        return (
            <div className="main">
                <div className="page page-not-found">
                    <div>
                        <div className="row">
                            <div className="col-sm-6">

                                <div className="hero-container">
                                    <h1>We Looked Everywhere For This Page!</h1>
                                    <h2>But maybe we can still help you find what you're looking for:</h2>
                                    <ul>
                                        <li>
                                            <Link to={'/apps'}>
                                                Go to your Apps <FontAwesomeIcon icon="arrow-right" />
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={'/support'}>
                                                Get in touch with our Support team <FontAwesomeIcon icon="arrow-right" />
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="img-container">
                                    <RobotImage/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default NotFound;
