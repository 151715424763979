import 'whatwg-fetch'
import auth0Client from "../Auth";
import {apiHost} from '../constants';

const { fromJS } = require('immutable');


export const FETCH_APPS = 'FETCH_APPS';
export const RECEIVE_APPS = 'RECEIVE_APPS';

export const UPDATE_APP = 'UPDATE_APP';



export function requestApps() {
    return {
        type: FETCH_APPS
    }
}


export function receiveApps(apps) {
    return {
        type: RECEIVE_APPS,
        apps
    }
}


export function fetchApps() {
    return (dispatch, getState) => {
        const state = getState();

        if (!state.isFetchingApps) {
            dispatch(requestApps());
            return fetch(apiHost + '/apps', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${auth0Client.getIdToken()}`
                }
            })
                .then(response => response.json())
                .then(json => {
                    const apps = fromJS(json);
                    dispatch(receiveApps(apps));
                })
                .catch(err => {
                    console.error('fetchAppData error:', err);
                });
        }
        else {
            return Promise.resolve()
        }
    }
}


// add app
export function addAppAction(name) {
    return (dispatch) => {
        const data = {
            name: name
        };
        return fetch(apiHost + '/apps/create', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${auth0Client.getIdToken()}`,
                'Content-Type':'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(json => {
                const appUUID = json.app_uuid;
                const apps = fromJS(json.apps);
                dispatch(receiveApps(apps));
                return Promise.resolve(appUUID);
            })
            .catch(err => {
                console.error('addApp error:', err);
            });
    }
}


// delete app
export function deleteAppAction(appUUID) {
    return () => {
        const data = {
            app_uuid: appUUID
        };
        return fetch(apiHost + '/apps/delete', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${auth0Client.getIdToken()}`,
                'Content-Type':'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(() => {
                return Promise.resolve();
            })
            .catch(err => {
                console.error('deleteAppAction error:', err);
            });
    }
}

// update app
function updateApp(newApp) {
    return {
        type: UPDATE_APP,
        newApp
    }
}

export function updateAppAction(app) {
    return (dispatch) => {
        dispatch(updateApp(app));

        const data = {
            uuid: app.get('uuid'),
            name: app.get('name'),
            image_url: app.get('image_url'),
        };

        return fetch(apiHost + '/apps/save', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${auth0Client.getIdToken()}`,
                'Content-Type':'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                return Promise.resolve(response.status)
            })
            .catch(err => {
                console.error('updateAppAction error:', err);
            });
    }
}
