import React, {Component} from 'react';
import './toggleSwitch.scss';


class ToggleSwitch extends Component {
    constructor(props) {
        super(props);

        this.state = {
            toggleSwitchId: this.idGen()
        };
    }

    idGen() {
        return '_' + Math.random().toString(36).substr(2, 9);
    };


    render() {

        return (
            <div className="toggle-switch">
                <input type="checkbox" id={this.state.toggleSwitchId}
                       className="cbx hidden activate-website-switch"
                       checked={this.props.checked}
                       onChange={this.props.onChange}
                />
                <label htmlFor={this.state.toggleSwitchId} className="lbl"/>
            </div>
        );
    }
}


export default ToggleSwitch;
