import {parseText} from "./textUtils";
import {imageHost} from "../../../../constants";

let images = [];
function loadImage(url) {
    return new Promise((resolve) => {
       let loaded = false;
       for (let i=0; i<images.length; i++) {
           if (images[i].url === url) {
               loaded = true;
               resolve(images[i].img);
           }
       }

       if (!loaded) {
           let img = new Image();
           img.crossOrigin = 'anonymous';

           img.onload = () => {
               images.push({
                   url: url,
                   img: img
               });

               resolve(img);
           };

           img.src = url;
       }
    });
}

function renderBackground(params) {
    return new Promise((resolve) => {
        const canvas = params.canvas;
        const ctx = params.ctx;
        const screen = params.screen;

        // background - solid, gradient, or image

        if (screen.getIn(['backgroundColor', 'type']) === 'solid') {
            ctx.fillStyle = screen.getIn(['backgroundColor', 'color']);
            ctx.fillRect(0, 0, canvas.width, canvas.height);
            resolve();
        } else if (screen.getIn(['backgroundColor', 'type']) === 'gradient') {
            let gradient = screen.getIn(['backgroundColor', 'gradient']).toJS();
            let angle = gradient.angle - 90;
            let radians = angle * Math.PI / 180;
            let maxLength = Math.sqrt(Math.pow(canvas.width, 2) + Math.pow(canvas.height, 2));

            let x1 = 0;
            let y1 = 0;
            let x2 = maxLength * Math.cos(radians);
            let y2 = maxLength * Math.sin(radians);

            if (x2 < 0) {
                x1 = -x2;
                x2 = 0
            }

            if (y2 < 0) {
                y1 = -y2;
                y2 = 0
            }

            let g = ctx.createLinearGradient(x1, y1, x2, y2);

            gradient.stops.forEach(stop => {
                g.addColorStop(stop.offset, stop.color);
            });

            ctx.fillStyle = g;
            ctx.fillRect(0, 0, canvas.width, canvas.height);
            resolve();
        }
        else if (screen.getIn(['backgroundColor', 'type']) === 'image') {
            const url = screen.getIn(['backgroundColor', 'imageUrl']);
            loadImage(url).then(img => {
                const scale = Math.max(canvas.width / img.width, canvas.height / img.height);

                const x = (canvas.width / 2) - (img.width / 2) * scale;
                const y = (canvas.height / 2) - (img.height / 2) * scale;
                ctx.drawImage(img, x, y, img.width * scale, img.height * scale);
                resolve();
            });
        }
    });
}

function renderCanvas(params) {
    return new Promise((resolve) => {
        const canvas = params.canvas;
        const ctx = params.ctx;
        const screen = params.screen;
        const size = params.size;
        const frameImages = params.frameImages;
        const screenshot = params.screenshot;

        renderBackground({
            canvas: params.canvas,
            ctx: params.ctx,
            screen: params.screen
        }).then(() => {
            //heading
            let headerBottom = renderHeading(screen, size, canvas, ctx);

            renderFrame({
                canvas: canvas,
                ctx: ctx,
                screen: screen,
                size: size,
                frameImages: frameImages,
                screenshot: screenshot,
                headerBottom: headerBottom
            }).then(() => {
                resolve();
            });
        });
    });
}

function renderHeading(screen, size, canvas, ctx) {
    ctx.textBaseline = 'top';

    let text = screen.get('heading');
    const lines = parseText(text, screen, size, canvas, ctx);

    let y = screen.getIn(['styles', 'paddingTop']) * size.get('scale_factor');

    const paddingH = screen.getIn(['styles', 'paddingHPct']) * canvas.width;
    const textAlign = screen.getIn(['headingStyles', 'textAlign']);

    lines.forEach(line => {
        let x;
        if (textAlign === 'left') {
            x = paddingH;
        }
        else if (textAlign === 'center') {
            x = canvas.width / 2 - line.width / 2;
        }
        else {
            x = canvas.width - paddingH - line.width;
        }

        line.tokens.forEach(token => {
            ctx.font = token.font;
            ctx.fillStyle = token.color;
            ctx.fillText(token.text, x, y);

            if (token.textDecoration === 'underline') {
                ctx.strokeStyle = token.color;
                ctx.lineWidth = Math.floor(token.fontSize / 10);
                ctx.beginPath();
                ctx.moveTo(x, y + token.baselineBelow);
                ctx.lineTo(x + token.width, y + token.baselineBelow);
                ctx.stroke();
            }

            x += token.width;
        });

        y += line.height * screen.getIn(['headingStyles', 'lineHeight']);
    });

    return y;
}

function getFrameImageForName(frameImages, frameName) {
    for (let i=0; i<frameImages.count(); i++) {
        if (frameImages.getIn([i, 'name']) === frameName) {
            return frameImages.get(i);
        }
    }
}

function renderFrame(params) {
    return new Promise((resolve) => {
        const canvas = params.canvas;
        const ctx = params.ctx;
        const screen = params.screen;
        const size = params.size;
        const frameImages = params.frameImages;
        const screenshot = params.screenshot;
        const headerBottom = params.headerBottom;

        let frameImgTop = headerBottom + screen.getIn(['styles', 'paddingHeadingFrame']) * size.get('scale_factor');
        frameImgTop = Math.max(frameImgTop, screen.getIn(['styles', 'minHeadingHeight']) * size.get('scale_factor'));

        let paddingH = screen.getIn(['styles', 'paddingHPct']) * canvas.width;
        if (screen.getIn(['frame', 'style']) !== 'none') {

            let frameName = (size.get('name') + screen.getIn(['frame', 'style'])).toLowerCase();
            let frameImage = getFrameImageForName(frameImages, frameName);

            // load frame image
            let url =  (imageHost + 'frames/' + size.get('platform') + '/' + frameImage.get('filename')).toLowerCase();

            loadImage(url).then(frameImg => {
                let w = frameImg.naturalWidth;
                let h = frameImg.naturalHeight;
                let paddingH = screen.getIn(['styles', 'paddingHPct']) * canvas.width;
                let scaledW = canvas.width - 2 * paddingH;
                let scaledH = scaledW / w * h;

                const tmpCanvas = document.createElement('canvas');
                tmpCanvas.width = canvas.width;
                tmpCanvas.height = canvas.height;
                const tmpCtx = tmpCanvas.getContext('2d');

                //screenshot -- with frame
                renderScreenshot({
                    canvas: tmpCanvas,
                    ctx: tmpCtx,
                    frameImg: frameImg,
                    frameImage: frameImage,
                    frameImgTop: frameImgTop,
                    withFrame: true,
                    paddingH: paddingH,
                    screenshot: screenshot,
                    screen: screen
                }).then(() => {
                    if (screen.get('orientation') === 'portrait') {
                        tmpCtx.drawImage(frameImg, paddingH, frameImgTop, scaledW, scaledH);
                    } else {
                        tmpCtx.save();
                        tmpCtx.translate(canvas.width / 2, canvas.height / 2);

                        if (screen.get('orientation') === 'landscapeLeft') {
                            tmpCtx.scale(-1, 1);
                        }

                        tmpCtx.rotate(90 * Math.PI / 180);
                        tmpCtx.drawImage(frameImg, frameImgTop - canvas.height / 2, paddingH - canvas.width / 2, scaledW / h * w, scaledW);
                        tmpCtx.restore();
                    }

                    //shadow
                    if (screen.getIn(['frame', 'shadow', 'type']) === 'real') {
                        ctx.save();

                        ctx.shadowOffsetX = screen.getIn(['frame', 'shadow', 'offsetX']);
                        ctx.shadowOffsetY = screen.getIn(['frame', 'shadow', 'offsetY']);
                        ctx.shadowColor = screen.getIn(['frame', 'shadow', 'color']);
                        ctx.shadowBlur = screen.getIn(['frame', 'shadow', 'blur']);

                        ctx.drawImage(tmpCanvas, 0, 0);

                        ctx.restore();
                    }
                    else {
                        ctx.drawImage(tmpCanvas, 0, 0);
                    }
                    resolve();
                });
            });
        }
        else {

            renderScreenshot({
                canvas: canvas,
                ctx: ctx,
                frameImg: null,
                frameImage: null,
                frameImgTop: frameImgTop,
                withFrame: false,
                paddingH: paddingH,
                screenshot: screenshot,
                screen: screen
            }).then(() => {
                resolve();
            });
        }
    });
}

function renderScreenshot(params) {
    return new Promise((resolve) => {
        const canvas = params.canvas;
        const ctx = params.ctx;
        const frameImg = params.frameImg;
        const frameImage = params.frameImage;
        const frameImgTop = params.frameImgTop;
        const withFrame = params.withFrame;
        const paddingH = params.paddingH;
        const screenshot = params.screenshot;
        const screen = params.screen;

        if (screenshot != null) {
            loadImage(screenshot.get('url')).then(screenshotImg => {
                let x;
                let y;
                let w;
                let h;

                if (withFrame) {
                    let scale;
                    let screenshotX;
                    let screenshotY;
                    let screenshotW;

                    if (screen.get('orientation') === 'portrait') {
                        screenshotX = parseInt(frameImage.get('portrait_screenshot_x'));
                        screenshotY = parseInt(frameImage.get('portrait_screenshot_y'));
                        screenshotW = parseInt(frameImage.get('portrait_screenshot_w'));

                        scale = (canvas.width - 2 * paddingH) / frameImg.naturalWidth;

                        x = paddingH + screenshotX * scale;
                        y = frameImgTop + screenshotY * scale;
                        w = screenshotW * scale;
                        h = w / screenshotImg.naturalWidth * screenshotImg.naturalHeight;
                    }
                    else {
                        if (screen.get('orientation') === 'landscapeLeft') {
                            screenshotX = parseInt(frameImage.get('landscape_l_screenshot_x'));
                            screenshotY = parseInt(frameImage.get('landscape_l_screenshot_y'));
                        }
                        else {
                            screenshotX = parseInt(frameImage.get('landscape_r_screenshot_x'));
                            screenshotY = parseInt(frameImage.get('landscape_r_screenshot_y'));
                        }

                        screenshotW = parseInt(frameImage.get('landscape_screenshot_w'));

                        scale = (canvas.width - 2 * paddingH) / frameImg.naturalHeight;

                        x = paddingH + screenshotX * scale;
                        y = frameImgTop + screenshotY * scale;
                        w = screenshotW * scale;
                        h = w / screenshotImg.naturalWidth * screenshotImg.naturalHeight;
                    }

                    ctx.drawImage(screenshotImg, x, y, w, h);
                }
                else {
                    x = paddingH;
                    w = canvas.width - 2 * paddingH;
                    y = frameImgTop;
                    h = w * canvas.height / canvas.width;
                    ctx.drawImage(screenshotImg, x, y, w, h);
                }

                resolve();
            });
        }
        else {
            resolve();
        }
    });
}

export function createCanvas(params) {
    return new Promise((resolve) => {
        const size = params.size;
        const screen = params.screen;
        const frameImages = params.frameImages;
        const screenshot = params.screenshot;

        let canvas = document.createElement('canvas');

        if (screen.get('orientation') === 'portrait') {
            canvas.width = size.get('portrait_width');
            canvas.height = size.get('portrait_height');
        }
        else {
            canvas.width = size.get('portrait_height');
            canvas.height = size.get('portrait_width');
        }

        const ctx = canvas.getContext('2d');

        renderCanvas({
            canvas: canvas,
            ctx: ctx,
            screen: screen,
            size: size,
            frameImages: frameImages,
            screenshot: screenshot,
        }).then(() => {
            resolve(canvas);
        });
    });
}

