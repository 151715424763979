import {
    FETCH_LOCATION_DATA,
    RECEIVE_LOCATION_DATA,
    SET_COUNTRY_CODE
} from '../actions/locationActions';


function locationReducer(
    state = {
        isFetchingLocationData: false,
        locationData: null
    },
    action
) {
    switch (action.type) {
        case FETCH_LOCATION_DATA:
            return Object.assign({}, state, {
                isFetchingLocationData: true
            });

        case RECEIVE_LOCATION_DATA:
            return Object.assign({}, state, {
                isFetchingLocationData: false,
                locationData: action.locationData
            });

        case SET_COUNTRY_CODE:
            return Object.assign({}, state, {
                locationData: state.locationData.set('country_code', action.countryCode)
            });

        default:
            return state
    }
}

export default locationReducer;