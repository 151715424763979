import React, {Component} from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

class SliderControl extends Component {
    handleSliderChange = (value) => {
        const {multiplier} = this.props;
        let v = value;

        if (multiplier != null) {
            v = v / multiplier;
        }
        this.props.onChange(v);
    };

    handleInputChange = (e) => {
        const {multiplier} = this.props;

        let v = e.target.value;

        if (typeof (v) === 'string' && v.length > 0) {
            v = Number(v);
        }

        if (Number.isNaN(v)) {
            v = e.target.value;
        } else if (multiplier != null && v !== '') {
            v = v / multiplier;
        }

        this.props.onChange(v);
    };

    handleInputBlur = (e) => {
        const {min, max, multiplier} = this.props;
        let v = e.target.value;

        v = Math.min(max, Math.max(min, v || 0));

        if (multiplier != null) {
            v = v / multiplier;
        }

        this.props.onChange(v);
    };

    getSliderValue = () => {
        const {multiplier} = this.props;

        let v = this.props.value;
        if (typeof (v) === 'string') {
            v = Number(v);
        }

        if (Number.isNaN(v)) {
            v = 0;
        } else if (multiplier != null) {
            v = Math.round(v * multiplier);
        }

        return v;
    };

    getInputValue = () => {
        const {multiplier} = this.props;

        let v = this.props.value;
        if (typeof (v) === 'string' && v.length > 0) {
            v = Number(v);
        }

        if (Number.isNaN(v) || v === '') {
            v = this.props.value;
        } else if (multiplier != null) {
            v = Math.round(v * multiplier);
        }

        return v;
    };

    render() {
        const {name, unit, min, max, disabled} = this.props;

        return (
            <div className="slider-control">
                <label>{name}</label>
                <div className="slider-option">
                    <Slider
                        value={this.getSliderValue()}
                        min={min}
                        max={max}
                        disabled={disabled}
                        onChange={this.handleSliderChange}
                        trackStyle={{backgroundColor: '#7fccf7'}}
                        handleStyle={{
                            borderColor: '#449fed',
                            backgroundColor: '#449fed',
                        }}
                    />
                    <input
                        type="number"
                        className="value-input"
                        disabled={disabled}
                        value={this.getInputValue()}
                        onChange={this.handleInputChange}
                        onBlur={this.handleInputBlur}
                    />
                    <p>{unit}</p>
                </div>
            </div>
        );
    }
}


export default SliderControl;
