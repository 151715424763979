import React, {Component} from 'react';
import Button from '../../Components/Button/Button';
import {getDefaultScreen} from "./editorUtils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {getLanguageTitle} from "../../languages";
import SelectControl from './Components/SelectControl';


class ToolbarTop extends Component {
    handleBtnClick = (e, btnName, params) => {
        if (e != null) {
            e.preventDefault();
        }
        this.props.onClick(btnName, params);
    };

    handleNameChange = (e) => {
        const newProject = this.props.project.set('name', e.target.value);
        this.props.onProjectChange(newProject);
    };

    handleAddScreen = () => {
        const {project} = this.props;
        const newScreen = getDefaultScreen();
        const screens = project.getIn(['data', 'screens']).push(newScreen);
        const newProject = project.setIn(['data', 'screens'], screens);
        this.props.onProjectChange(newProject);
        this.props.onScreenClick(newScreen.get('id'));
    };

    getLanguageOptions = () => {
        const {project} = this.props;
        let options = [];
        project.get('languages').forEach(languageCode => {
            options.push({value: languageCode, title: getLanguageTitle(languageCode)})
        });

        return options;
    };

    handleLanguageChange = (languageCode) => {
        const newProject = this.props.project.set('last_language', languageCode);
        this.props.onProjectChange(newProject);
    };

    render() {
        const {project, projectHasUnsavedChanges} = this.props;
        const projectName = project.get('name') || '';

        return (
            <div className="toolbar-top">

                <div className="tools-container tools-container-left">

                    <div className="tool-group tool-group-add-screen">
                        <div className="tool">
                            <Button
                                className="btn-primaryNew"
                                onClick={this.handleAddScreen}
                            >
                                <FontAwesomeIcon icon={faPlus}/>
                                Add Screen
                            </Button>
                        </div>
                    </div>

                    <div className="tool-group tool-group-select-language">
                        <div className="tool">
                            <SelectControl
                                value={project.get('last_language')}
                                onChange={this.handleLanguageChange}
                            >
                                {this.getLanguageOptions().map(option => {
                                    return (
                                        <option key={option.value} value={option.value}>
                                            {option.title}
                                        </option>
                                    )
                                })}
                            </SelectControl>
                        </div>
                    </div>

                    <div className="tool-group tool-group-name">
                        <div className="tool tool-campaign-name">
                            <input
                                className="form-control"
                                value={projectName}
                                onChange={this.handleNameChange}
                            />
                        </div>
                    </div>

                </div>

                <div className="tools-container tools-container-right">

                    <div className="tool-group tool-group-save">
                        <div className="tool">

                            {projectHasUnsavedChanges && (
                                <div className="save-status">
                                    <p>Unsaved</p>
                                    <p>changes</p>
                                </div>
                            )}

                            {!projectHasUnsavedChanges && (
                                <div className="save-status">
                                    <p>All</p>
                                    <p>saved</p>
                                </div>
                            )}

                            <Button
                                className="btn-primaryNew"
                                waiting={this.props.isSaving}
                                onClick={(e) => this.handleBtnClick(e, 'save')}
                            >Save</Button>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default ToolbarTop;