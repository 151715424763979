import 'whatwg-fetch'
import React, {Component} from 'react';
import Button from '../../../../Components/Button/Button';
import {createScreenshots} from './downloadUtils';
import SelectControl from '../../Components/SelectControl';
import './downloadStyles.scss';


class Download extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isGeneratingZip: false
        }
    }

    updateProject = (path, value) => {
        const {project} = this.props;
        const newProject = project.setIn(path, value);
        this.props.onProjectChange(newProject);
    };

    handleBtnDownloadClick = () => {
        const {project, sizes, frameImages, screenshots} = this.props;

        this.setState({isGeneratingZip: true});

        createScreenshots({
            project: project,
            sizes: sizes,
            frameImages: frameImages,
            screenshots: screenshots
        }).then(() => {
            this.setState({isGeneratingZip: false})
        }).catch(() => {
            console.log('error...')
        });
    };

    render() {
        const {isGeneratingZip} = this.state;
        const {accountInfo, project} = this.props;

        const isTrialPlan = accountInfo.getIn(['plan', 'is_trial']);

        if (!isTrialPlan) {
            return (
                <div className="editor-sidebar sidebar-download">
                    <div className="sidebar-header">
                        <p>Download</p>
                    </div>

                    <div className="sidebar-section">
                        <p className="heading">Format</p>

                        <div className="sidebar-section-inner">

                            <SelectControl
                                value={project.get('last_download_format')}
                                onChange={(value) => this.updateProject(['last_download_format'], value)}
                            >
                                <option key="jpg" value="jpg">JPG</option>
                                <option key="png" value="png">PNG</option>
                            </SelectControl>
                        </div>
                    </div>

                    <div className="sidebar-section">
                        <p className="heading">Download Screenshots</p>

                        <div className="sidebar-section-inner">

                            <div className="btn-download-container">
                                <Button
                                    className="btn-primaryNew btn-download"
                                    waiting={isGeneratingZip}
                                    onClick={this.handleBtnDownloadClick}
                                >Download</Button>
                            </div>

                        </div>
                    </div>

                </div>
            );
        }
        else {
            return (
                <div className="editor-sidebar sidebar-download">
                    <div className="sidebar-header">
                        <p>Download</p>
                    </div>

                    <div className="sidebar-section">
                        <p className="heading">Plan</p>

                        <div className="sidebar-section-inner">
                            <div className="purchase-plan-info">
                                <p>You need to sign up to our plan before you can download your screenshots.</p>
                                <div className="btn-container">
                                    <Button
                                        className="btn-primaryNew"
                                        onClick={this.props.onBtnPurchaseClick}
                                    >View Plan</Button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            )
        }
    }
}

export default Download;