import {
    FETCH_CURRENT_USER,
    RECEIVE_CURRENT_USER,
    CHANGE_PLAN,
    SET_LAST_APP,
    TOGGLE_AUTO_RENEWAL
} from '../actions/userActions';


function userReducer(
    state = {
        isFetchingCurrentUser: false,
        currentUser: null
    },
    action
) {
    let currentUser;
    switch (action.type) {
        case FETCH_CURRENT_USER:
            return Object.assign({}, state, {
                isFetchingCurrentUser: true
            });

        case RECEIVE_CURRENT_USER:
            return Object.assign({}, state, {
                isFetchingCurrentUser: false,
                currentUser: action.currentUser
            });

        case CHANGE_PLAN:
            currentUser = state.currentUser.set('plan', action.plan);
            return Object.assign({}, state, {
                currentUser: currentUser
            });

        case SET_LAST_APP:
            currentUser = state.currentUser.set('last_app_uuid', action.appUUID);
            return Object.assign({}, state, {
                currentUser: currentUser
            });

        case TOGGLE_AUTO_RENEWAL:
            currentUser = state.currentUser.setIn(['account', 'subscription_renews'], !state.currentUser.getIn(['account', 'subscription_renews']));
            return Object.assign({}, state, {
                currentUser: currentUser
            });

        default:
            return state
    }
}


export default userReducer;