import React, {Component} from 'react';
import './loadingSpinner.scss';
import {ReactComponent as Circle1} from './circle1.svg';
import {ReactComponent as Circle2} from './circle2.svg';


class LoadingSpinner extends Component {

    render() {
        const {onlyAnimation} = this.props;

        return (
            <div className="main-spinner">
                <div className="spinner-container">
                    <div className="spinner-container-inner">
                        <Circle1/>
                        <Circle2/>
                    </div>

                    {!onlyAnimation && (
                        <p>Loading, please<br/>wait...</p>
                    )}
                </div>
            </div>
        );
    }
}


export default LoadingSpinner;
