import {
    FETCH_SCREENSHOTS,
    RECEIVE_SCREENSHOTS,
    DELETE_SCREENSHOT
} from '../actions/screenshotActions';


function imageReducer(
    state = {
        isFetchingScreenshots: false,
        screenshots: null
    },
    action
) {
    switch (action.type) {
        case FETCH_SCREENSHOTS:
            return Object.assign({}, state, {
                screenshots: null,
                isFetchingScreenshots: true
            });

        case RECEIVE_SCREENSHOTS:
            return Object.assign({}, state, {
                isFetchingScreenshots: false,
                screenshots: action.screenshots
            });

        case DELETE_SCREENSHOT:
            let idx = -1;

            for (let i = 0; i < state.screenshots.size; i++) {
                if (state.screenshots.getIn([i, 'uuid']) === action.screenshotUUID) {
                    idx = i;
                    break;
                }
            }

            return Object.assign({}, state, {
                screenshots: state.screenshots.delete(idx)
            });

        default:
            return state
    }
}

export default imageReducer;