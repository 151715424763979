import React, {Component} from 'react';


class ScreenHeader extends Component {

    render() {
        const {screen} = this.props;

        return (

            <div className="screen-header">
                <p>{screen.get('name')}</p>
                <div className="icon-container">
                    <i className="material-icons">drag_indicator</i>
                </div>
            </div>


        )
    }
}

export default ScreenHeader;