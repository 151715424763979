import React, {Component} from 'react';
import Card from "../../../Components/Card/Card";
import LoadingSpinner from "../../../Components/LoadingSpinner/LoadingSpinner";
import InvoiceEntry from "./InvoiceEntry";


class PaymentHistoryCard extends Component {
    render() {
        const {invoices} = this.props;

        if (invoices != null) {

            return (
                <Card header="Payment history">
                    <div className="table-container">
                        <table className="table default-table">
                            <thead>
                            <tr>
                                <th scope="col">Invoice</th>
                                <th scope="col">Date</th>
                                <th scope="col">Amount</th>
                                <th scope="col">PDF link</th>
                            </tr>
                            </thead>
                            <tbody>

                            {invoices.map(invoice => (
                                <InvoiceEntry
                                    key={invoice.get('number')}
                                    invoice={invoice}
                                />
                            ))}

                            </tbody>
                        </table>
                    </div>
                </Card>
            );
        } else {
            return (
                <Card header="Payment history">
                    <LoadingSpinner/>
                </Card>
            )
        }
    }
}


export default PaymentHistoryCard;