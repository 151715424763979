import React, {Component} from 'react';
import {Link} from "react-router-dom";
import notValidImage from './not_valid.svg';
import './stylesAppNotValid.scss';


class AppNotValid extends Component {

    render() {

        return (
            <div className="campaign-not-valid-container">
                <h1>We've looked everywhere...</h1>
                <img src={notValidImage} alt="not_valid_image"/>

                <div className="row">
                    <div className="col-sm-8 offset-sm-2">
                        <p>It doesn't look like this App exists. Go back to your App overivew <Link to={'/apps'}>here</Link> or,
                            if you think there's something wrong, please get in touch with our support team <a href="mailto:support@screenshotbuilder.com">here</a>.</p>
                    </div>
                </div>

            </div>
        );
    }
}

export default AppNotValid;
