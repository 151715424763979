import {
    FETCH_PLANS,
    RECEIVE_PLANS
} from '../actions/planActions';


function planReducer(
    state = {
        isFetchingPlans: false,
        plans: null
    },
    action
) {
    switch (action.type) {
        case FETCH_PLANS:
            return Object.assign({}, state, {
                isFetchingPlans: true
            });

        case RECEIVE_PLANS:
            return Object.assign({}, state, {
                isFetchingPlans: false,
                plans: action.plans
            });

        default:
            return state
    }
}

export default planReducer;