const FontFaceObserver = require('fontfaceobserver');

class Fonts {
    constructor() {
        this.fontsLoaded = false;

        this.fontFamilies = [
            {
                name: 'Abril Fatface',
                value: 'abrilfatface',
                weights: [{name: 'normal', value: 400}],
                css: "@import url('https://fonts.googleapis.com/css?family=Abril+Fatface');",
                url: "https://fonts.googleapis.com/css?family=Abril+Fatface",
                src: 'google'
            },
            {
                name: 'Archivo',
                value: 'archivo',
                weights: [{name: 'normal', value: 400}, {name: 'bold', value: 700}],
                css: "@import url('https://fonts.googleapis.com/css?family=Archivo:400,700');",
                url: "https://fonts.googleapis.com/css?family=Archivo:400,700",
                src: 'google'
            },
            {
                name: 'Arial',
                value: 'arial',
                weights: [{name: 'normal', value: 400}, {name: 'bold', value: 700}],
                css: "",
                src: 'internal'
            },
            {
                name: 'Arvo',
                value: 'arvo',
                weights: [{name: 'normal', value: 400}, {name: 'bold', value: 700}],
                css: "@import url('https://fonts.googleapis.com/css?family=Arvo:400,700');",
                url: "https://fonts.googleapis.com/css?family=Arvo:400,700",
                src: 'google'
            },
            {
                name: 'Bitter',
                value: 'bitter',
                weights: [{name: 'normal', value: 400}, {name: 'bold', value: 700}],
                css: "@import url('https://fonts.googleapis.com/css?family=Bitter:400,700');",
                url: "https://fonts.googleapis.com/css?family=Bitter:400,700",
                src: 'google'
            },
            {
                name: 'Cormorant',
                value: 'cormorant',
                weights: [{name: 'light', value: 300}, {name: 'normal', value: 400}, {name: 'bold', value: 700}],
                css: "@import url('https://fonts.googleapis.com/css?family=Cormorant:300,400,700');",
                url: "https://fonts.googleapis.com/css?family=Cormorant:300,400,700",
                src: 'google'
            },
            {
                name: 'Fjalla One',
                value: 'fjallaone',
                weights: [{name: 'normal', value: 400}],
                css: "@import url('https://fonts.googleapis.com/css?family=Fjalla+One');",
                url: "https://fonts.googleapis.com/css?family=Fjalla+One",
                src: 'google'
            },
            {
                name: 'Frank Ruhl Libre',
                value: 'frankruhllibre',
                weights: [{name: 'light', value: 300}, {name: 'normal', value: 400}, {name: 'bold', value: 700}],
                css: "@import url('https://fonts.googleapis.com/css?family=Frank+Ruhl+Libre:300,400,700');",
                url: "https://fonts.googleapis.com/css?family=Frank+Ruhl+Libre:300,400,700",
                src: 'google'
            },
            {
                name: 'Karla',
                value: 'karla',
                weights: [{name: 'normal', value: 400}, {name: 'bold', value: 700}],
                css: "@import url('https://fonts.googleapis.com/css?family=Karla:400,700');",
                url: "https://fonts.googleapis.com/css?family=Karla:400,700",
                src: 'google'
            },
            {
                name: 'Lato',
                value: 'lato',
                weights: [{name: 'light', value: 300}, {name: 'normal', value: 400}, {name: 'bold', value: 700}],
                css: "@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700');",
                url: "https://fonts.googleapis.com/css?family=Lato:300,400,700",
                src: 'google'
            },
            {
                name: 'Lora',
                value: 'lora',
                weights: [{name: 'normal', value: 400}, {name: 'bold', value: 700}],
                css: "@import url('https://fonts.googleapis.com/css?family=Lora:400,700');",
                url: "https://fonts.googleapis.com/css?family=Lora:400,700",
                src: 'google'
            },
            {
                name: 'Montserrat',
                value: 'montserrat',
                weights: [{name: 'light', value: 300}, {name: 'normal', value: 400}, {name: 'bold', value: 700}],
                css: "@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,700');",
                url: "https://fonts.googleapis.com/css?family=Montserrat:300,400,700",
                src: 'google'
            },
            {
                name: 'Open Sans',
                value: 'opensans',
                weights: [{name: 'light', value: 300}, {name: 'normal', value: 400}, {name: 'bold', value: 700}],
                css: "@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,700');",
                url: "https://fonts.googleapis.com/css?family=Open+Sans:300,400,700",
                src: 'google'
            },
            {
                name: 'Oswald',
                value: 'oswald',
                weights: [{name: 'light', value: 300}, {name: 'normal', value: 400}, {name: 'bold', value: 700}],
                css: "@import url('https://fonts.googleapis.com/css?family=Oswald:300,400,700');",
                url: "https://fonts.googleapis.com/css?family=Oswald:300,400,700",
                src: 'google'
            },
            {
                name: 'Playfair Display',
                value: 'playfairdisplay',
                weights: [{name: 'normal', value: 400}, {name: 'bold', value: 700}],
                css: "@import url('https://fonts.googleapis.com/css?family=Playfair+Display:400,700');",
                url: "https://fonts.googleapis.com/css?family=Playfair+Display:400,700",
                src: 'google'
            },
            {
                name: 'PT Sans',
                value: 'ptsans',
                weights: [{name: 'normal', value: 400}, {name: 'bold', value: 700}],
                css: "@import url('https://fonts.googleapis.com/css?family=PT+Sans:400,700');",
                url: "https://fonts.googleapis.com/css?family=PT+Sans:400,700",
                src: 'google'
            },
            {
                name: 'PT Serif',
                value: 'ptserif',
                weights: [{name: 'normal', value: 400}, {name: 'bold', value: 700}],
                css: "@import url('https://fonts.googleapis.com/css?family=PT+Serif:400,700');",
                url: "https://fonts.googleapis.com/css?family=PT+Serif:400,700",
                src: 'google'
            },
            {
                name: 'Rakkas',
                value: 'rakkas',
                weights: [{name: 'normal', value: 400}],
                css: "@import url('https://fonts.googleapis.com/css?family=Rakkas');",
                url: "https://fonts.googleapis.com/css?family=Rakkas",
                src: 'google'
            },
            {
                name: 'Raleway',
                value: 'raleway',
                weights: [{name: 'light', value: 300}, {name: 'normal', value: 400}, {name: 'bold', value: 700}],
                css: "@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,700');",
                url: "https://fonts.googleapis.com/css?family=Raleway:300,400,700",
                src: 'google'
            },
            {
                name: 'Roboto',
                value: 'roboto',
                weights: [{name: 'light', value: 300}, {name: 'normal', value: 400}, {name: 'bold', value: 700}],
                css: "@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,700');",
                url: "https://fonts.googleapis.com/css?family=Roboto:300,400,700",
                src: 'google'
            },
            {
                name: 'Roboto Condensed',
                value: 'robotocondensed',
                weights: [{name: 'light', value: 300}, {name: 'normal', value: 400}, {name: 'bold', value: 700}],
                css: "@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400,700');",
                url: "https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400,700",
                src: 'google'
            },
            {
                name: 'Roboto Slab',
                value: 'robotoslab',
                weights: [{name: 'light', value: 300}, {name: 'normal', value: 400}, {name: 'bold', value: 700}],
                css: "@import url('https://fonts.googleapis.com/css?family=Roboto+Slab:300,400,700');",
                url: "https://fonts.googleapis.com/css?family=Roboto+Slab:300,400,700",
                src: 'google'
            },
            {
                name: 'Rubik',
                value: 'rubik',
                weights: [{name: 'light', value: 300}, {name: 'normal', value: 400}, {name: 'bold', value: 700}],
                css: "@import url('https://fonts.googleapis.com/css?family=Rubik:300,400,700');",
                url: "https://fonts.googleapis.com/css?family=Rubik:300,400,700",
                src: 'google'
            },
            {
                name: 'Source Sans Pro',
                value: 'sourcesanspro',
                weights: [{name: 'light', value: 300}, {name: 'normal', value: 400}, {name: 'bold', value: 700}],
                css: "@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,700');",
                url: "https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,700",
                src: 'google'
            },
            {
                name: 'Spectral',
                value: 'spectral',
                weights: [{name: 'light', value: 300}, {name: 'normal', value: 400}, {name: 'bold', value: 700}],
                css: "@import url('https://fonts.googleapis.com/css?family=Spectral:300,400,700');",
                url: "https://fonts.googleapis.com/css?family=Spectral:300,400,700",
                src: 'google'
            },
            {
                name: 'Times New Roman',
                value: 'timesnewroman',
                weights: [{name: 'normal', value: 400}, {name: 'bold', value: 700}],
                css: "",
                src: 'internal'
            },
            {
                name: 'Ubuntu',
                value: 'ubuntu',
                weights: [{name: 'light', value: 300}, {name: 'normal', value: 400}, {name: 'bold', value: 700}],
                css: "@import url('https://fonts.googleapis.com/css?family=Ubuntu:300,400,700');",
                url: "https://fonts.googleapis.com/css?family=Ubuntu:300,400,700",
                src: 'google'
            },
            {
                name: 'Vollkorn',
                value: 'vollkorn',
                weights: [{name: 'normal', value: 400}, {name: 'bold', value: 700}],
                css: "@import url('https://fonts.googleapis.com/css?family=Vollkorn:400,700');",
                url: "https://fonts.googleapis.com/css?family=Vollkorn:400,700",
                src: 'google'
            },
            {
                name: 'Work Sans',
                value: 'worksans',
                weights: [{name: 'normal', value: 400}, {name: 'bold', value: 700}],
                css: "@import url('https://fonts.googleapis.com/css?family=Work+Sans:400,700');",
                url: "https://fonts.googleapis.com/css?family=Work+Sans:400,700",
                src: 'google'
            }
        ];
    }

    loadFonts = () => {
        let observers = [];

        let styles = document.createElement('style');
        styles.type = 'text/css';
        this.fontFamilies.forEach((fontFamily) => {
            if (fontFamily.src === 'google') {
                styles.innerText += fontFamily.css;

                fontFamily.weights.forEach(weight => {
                    const font = new FontFaceObserver(fontFamily.name, {
                        weight: weight.value
                    });
                    observers.push(font.load());
                });
            }
        });
        document.head.appendChild(styles);

        Promise.all(observers)
            .then(() => {
                this.fontsLoaded = true;
            })
            .catch((err) => {
                console.warn('Some font are not available:', err);
            });
    };

    getFontFamilies = () => {
        return this.fontFamilies;
    };

    getFontFamilyValueforName = (fontFamilyName) => {
        for (let i=0; i<this.fontFamilies.length; i++) {
            if (this.fontFamilies[i].name === fontFamilyName.replace(/"/g, '')) {
                return this.fontFamilies[i].value;
            }
        }
        return null;
    };

    getFontFamilyDetailsforNames = (fontFamilyNames) => {
        let details = [];
        for (let i=0; i<this.fontFamilies.length; i++) {
            let fontFamily = this.fontFamilies[i];
            if (fontFamilyNames.indexOf(fontFamily.name) !== -1) {
                if (fontFamily.src === 'google') {
                    details.push({
                        name: fontFamily.name,
                        value: fontFamily.value,
                        css: fontFamily.css,
                    });
                }
            }
        }
        return details;
    };

    getFontFamilyNameForValue = (fontFamilyValue) => {
        for (let i=0; i<this.fontFamilies.length; i++) {
            if (this.fontFamilies[i].value === fontFamilyValue) {
                return this.fontFamilies[i].name;
            }
        }
        return null;
    };

    getFontFamilyWeightsForValue = (fontFamilyValue) => {
        for (let i=0; i<this.fontFamilies.length; i++) {
            if (this.fontFamilies[i].value === fontFamilyValue) {
                return this.fontFamilies[i].weights;
            }
        }
        return null;
    };

    getFontFamilyWeightsForName = (fontFamilyName) => {
        for (let i=0; i<this.fontFamilies.length; i++) {
            if (this.fontFamilies[i].name === fontFamilyName) {
                return this.fontFamilies[i].weights;
            }
        }
        return null;
    };

}

const fonts = new Fonts();

export default fonts;