import 'whatwg-fetch'
import auth0Client from "../Auth";
import {apiHost} from '../constants';

const { fromJS } = require('immutable');

export const FETCH_SCREENSHOTS = 'FETCH_SCREENSHOTS';
export const RECEIVE_SCREENSHOTS = 'RECEIVE_SCREENSHOTS';
export const DELETE_SCREENSHOT = 'DELETE_SCREENSHOT';


//screenshots
export function requestScreenshots() {
    return {
        type: FETCH_SCREENSHOTS
    }
}


export function receiveScreenshots(screenshots) {
    return {
        type: RECEIVE_SCREENSHOTS,
        screenshots
    }
}


export function fetchScreenshots(projectUUID) {
    return (dispatch, getState) => {
        const state = getState();

        if (!state.screenshotReducer.isFetchingScreenshots) {
            dispatch(requestScreenshots());
            const url = apiHost + '/screenshots/' + projectUUID;
            return fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${auth0Client.getIdToken()}`
                }
            })
                .then(response => response.json())
                .then(json => {
                    dispatch(receiveScreenshots(fromJS(json)))
                })
                .catch(err => {
                    console.error('fetchScreenshots error:', err);
                });
        }
        else {
            return Promise.resolve();
        }
    }
}


// add screenshot
export function uploadScreenshotAction(formData) {
    return (dispatch) => {
        return fetch(apiHost + '/screenshots/upload', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${auth0Client.getIdToken()}`
            },
            body: formData
        })
            .then(response => response.json())
            .then(json => {
                dispatch(receiveScreenshots(fromJS(json)));
                return Promise.resolve();
            })
            .catch(err => {
                console.error('uploadScreenshotAction error:', err);
            });
    }
}


// delete screenshot
export function deleteScreenshot(screenshotUUID) {
    return {
        type: DELETE_SCREENSHOT,
        screenshotUUID
    }
}


export function deleteScreenshotAction(screenshotUUID) {
    return dispatch => {
        dispatch(deleteScreenshot(screenshotUUID));
        const data = {
            uuid: screenshotUUID
        };
        return fetch(apiHost + '/screenshots/delete', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${auth0Client.getIdToken()}`,
                'Content-Type':'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(() => {
                //nothing to do
            })
            .catch(err => {
                console.error('deleteScreenshotAction error:', err);
            });
    }
}