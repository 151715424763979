import {Component} from 'react';
import ReactDOM from 'react-dom';

const rootElem = document.getElementById('root');


class ModalWrapper extends Component {
    render() {
        return ReactDOM.createPortal(
            this.props.children,
            rootElem
        );
    }
}

export default ModalWrapper;