import 'whatwg-fetch'
import React, {Component} from 'react';
import SliderControl from '../../Components/SliderControl';
import SelectControl from '../../Components/SelectControl';
import ButtonGroup from "../../Components/ButtonGroup";
import ColorControl from "../../Components/ColorControl";
import GradientControl from "../../Components/GradientControl/GradientControl";
import GradientLibrary from '../../Components/GradientLibrary/GradientLibrary';
import fonts from '../../fonts';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExchangeAlt} from "@fortawesome/free-solid-svg-icons";
import {Button} from "react-bootstrap";
import './globalSettingsStyles.scss';
import ImageLibrary from '../../../../Components/ImageLibrary/ImageLibrary';


class GlobalSettings extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showImageLibrary: false,
        };
    };

    setImageLibrary = (show) => {
        this.setState({
            showImageLibrary: show
        });
    };

    updateProject = (path, value) => {
        const {project} = this.props;
        const newProject = project.setIn(path, value);
        this.props.onProjectChange(newProject);
    };

    handleFontStyleChange = (btnName) => {
        const {project} = this.props;
        let newProject = project;

        switch (btnName) {
            case 'italic':
                newProject = newProject.setIn(['data', 'global', 'headingStyles', 'fontStyle'], newProject.getIn(['data', 'global', 'headingStyles', 'fontStyle']) === 'normal' ? 'italic': 'normal');
                break;

            case 'underline':
                newProject = newProject.setIn(['data', 'global', 'headingStyles', 'textDecoration'], newProject.getIn(['data', 'global', 'headingStyles', 'textDecoration']) === 'none' ? 'underline': 'none');
                break;

            default:
                console.log('unknown error');

        }
        this.props.onProjectChange(newProject);
    };

    handleImageSelected = (url) => {
        const {project} = this.props;
        const newProject = project.setIn(['data', 'global', 'backgroundColor', 'imageUrl'], url);
        this.props.onProjectChange(newProject);

        this.setState({
            showImageLibrary: false
        });
    };

    render() {
        const {showImageLibrary} = this.state;
        const {project} = this.props;
        
        const fontFamily = fonts.getFontFamilyValueforName(project.getIn(['data', 'global', 'headingStyles', 'fontFamily']));
        const fontWeight = project.getIn(['data', 'global', 'headingStyles', 'fontWeight']);
        const fontSize = project.getIn(['data', 'global', 'headingStyles', 'fontSize']);
        const letterSpacing = project.getIn(['data', 'global', 'headingStyles', 'letterSpacing']);
        const lineHeight = project.getIn(['data', 'global', 'headingStyles', 'lineHeight']);
        const fontStyle = project.getIn(['data', 'global', 'headingStyles', 'fontStyle']);
        const textDecoration = project.getIn(['data', 'global', 'headingStyles', 'textDecoration']);
        const textAlign = project.getIn(['data', 'global', 'headingStyles', 'textAlign']);
        const color = project.getIn(['data', 'global', 'headingStyles', 'color']);

        return (
            <div className="editor-sidebar sidebar-global-settings">

                <ImageLibrary
                    show={showImageLibrary}
                    onHide={() => this.setImageLibrary(false)}
                    onImageSelected={this.handleImageSelected}
                />

                <div className="sidebar-header">
                    <p>Global Styles</p>
                </div>

                <div className="sidebar-section">
                    <p className="heading">Background</p>

                    <div className="sidebar-section-inner">

                        <SelectControl
                            value={project.getIn(['data', 'global', 'backgroundColor', 'type'])}
                            onChange={(value) => this.updateProject(['data', 'global', 'backgroundColor', 'type'], value)}
                        >
                            <option key="solid" value="solid">Solid color</option>
                            <option key="gradient" value="gradient">Gradient</option>
                            <option key="image" value="image">Image</option>
                        </SelectControl>

                        {project.getIn(['data', 'global', 'backgroundColor', 'type']) === 'solid' &&

                        <ColorControl
                            name="Color"
                            color={project.getIn(['data', 'global', 'backgroundColor', 'color'])}
                            disableAlpha={false}
                            onChange={(v) => this.updateProject(['data', 'global', 'backgroundColor', 'color'], v)}
                        />

                        }

                        {project.getIn(['data', 'global', 'backgroundColor', 'type']) === 'gradient' &&
                        <div>
                            <GradientControl
                                gradient={project.getIn(['data', 'global', 'backgroundColor', 'gradient'])}
                                onGradientChange={(gradient) => this.updateProject(['data', 'global', 'backgroundColor', 'gradient'], gradient)}
                            />

                            <GradientLibrary
                                onGradientChange={(gradient) => this.updateProject(['data', 'global', 'backgroundColor', 'gradient'], gradient)}
                            />
                        </div>
                        }

                        {project.getIn(['data', 'global', 'backgroundColor', 'type']) === 'image' &&
                        <div className="image-container">

                            <div
                                className="img-container"
                                style={{backgroundImage: 'url(' + project.getIn(['data', 'global', 'backgroundColor', 'imageUrl']) + ')'}}
                            />

                            <Button
                                variant="secondary"
                                onClick={() => this.setImageLibrary(true)}
                            >
                                <FontAwesomeIcon icon={faExchangeAlt}/>
                                Change
                            </Button>
                        </div>
                        }


                    </div>
                </div>

                <div className="sidebar-section">
                    <p className="heading">Frame</p>

                    <div className="sidebar-section-inner">

                        <SelectControl value={project.getIn(['data', 'global', 'frame', 'style'])} onChange={(v) => this.updateProject(['data', 'global', 'frame', 'style'], v)}>
                            <option key="none" value="none">None</option>
                            <option key="flatWhite" value="flatWhite">Flat (white)</option>
                            <option key="flatBlack" value="flatBlack">Flat (black)</option>
                            <option key="realWhite" value="realWhite">Real (white)</option>
                            <option key="realBlack" value="realBlack">Real (black)</option>
                        </SelectControl>

                        <SelectControl
                            value={project.getIn(['data', 'global', 'orientation'])}
                            onChange={(v) => this.updateProject(['data', 'global', 'orientation'], v)}
                        >
                            <option key="portrait" value="portrait">Portrait</option>
                            <option key="landscapeLeft" value="landscapeLeft">Landscape (left)</option>
                            <option key="landscapeRight" value="landscapeRight">Landscape (right)</option>
                        </SelectControl>

                        <SliderControl
                            name="Padding (left / right)"
                            value={parseInt(project.getIn(['data', 'global', 'styles', 'paddingHPct']) * 100)}
                            min={0}
                            max={20}
                            step={1}
                            unit="%"
                            onChange={(v) => this.updateProject(['data', 'global', 'styles', 'paddingHPct'], v / 100)}
                        />

                        <SliderControl
                            name="Padding (top)"
                            value={project.getIn(['data', 'global', 'styles', 'paddingTop'])}
                            min={0}
                            max={100}
                            step={1}
                            unit="px"
                            onChange={(v) => this.updateProject(['data', 'global', 'styles', 'paddingTop'], v)}
                        />

                        <SliderControl
                            name="Padding (heading / frame)"
                            value={project.getIn(['data', 'global', 'styles', 'paddingHeadingFrame'])}
                            min={0}
                            max={100}
                            step={1}
                            unit="px"
                            onChange={(v) => this.updateProject(['data', 'global', 'styles', 'paddingHeadingFrame'], v)}
                        />

                        <SliderControl
                            name="Min. heading height"
                            value={project.getIn(['data', 'global', 'styles', 'minHeadingHeight'])}
                            min={0}
                            max={500}
                            step={1}
                            unit="px"
                            onChange={(v) => this.updateProject(['data', 'global', 'styles', 'minHeadingHeight'], v)}
                        />

                        <div className="sidebar-subsection">
                            <p className="subsection-heading">Shadow</p>

                            <SelectControl value={project.getIn(['data', 'global', 'frame', 'shadow', 'type'])} onChange={(v) => this.updateProject(['data', 'global', 'frame', 'shadow', 'type'], v)}>
                                <option key="none" value="none">None</option>
                                <option key="real" value="real">Real</option>
                            </SelectControl>

                            {project.getIn(['data', 'global', 'frame', 'shadow', 'type']) === 'real' &&
                            <div>
                                <SliderControl
                                    name="Offset (x)"
                                    value={project.getIn(['data', 'global', 'frame', 'shadow', 'offsetX'])}
                                    min={-100}
                                    max={100}
                                    step={1}
                                    unit="px"
                                    onChange={(v) => this.updateProject(['data', 'global', 'frame', 'shadow', 'offsetX'], v)}
                                />

                                <SliderControl
                                    name="Offset (y)"
                                    value={project.getIn(['data', 'global', 'frame', 'shadow', 'offsetY'])}
                                    min={-100}
                                    max={100}
                                    step={1}
                                    unit="px"
                                    onChange={(v) => this.updateProject(['data', 'global', 'frame', 'shadow', 'offsetY'], v)}
                                />

                                <SliderControl
                                    name="Blur"
                                    value={project.getIn(['data', 'global', 'frame', 'shadow', 'blur'])}
                                    min={0}
                                    max={100}
                                    step={1}
                                    unit="px"
                                    onChange={(v) => this.updateProject(['data', 'global', 'frame', 'shadow', 'blur'], v)}
                                />

                                <ColorControl
                                    name="Color"
                                    color={project.getIn(['data', 'global', 'frame', 'shadow', 'color'])}
                                    disableAlpha={false}
                                    onChange={(v) => this.updateProject(['data', 'global', 'frame', 'shadow', 'color'], v)}
                                />
                            </div>
                            }
                        </div>
                    </div>
                </div>

                <div className="sidebar-section">
                    <p className="heading">Font</p>

                    <div className="sidebar-section-inner">

                        <SelectControl
                            value={fontFamily}
                            onChange={(value) => this.updateProject(['data', 'global', 'headingStyles', 'fontFamily'], fonts.getFontFamilyNameForValue(value))}
                        >
                            {fonts.getFontFamilies().map(fontFamily => {
                                return (
                                    <option key={fontFamily.value} value={fontFamily.value}>
                                        {fontFamily.name}
                                    </option>
                                )
                            })}
                        </SelectControl>

                        <SelectControl value={fontWeight}
                                       onChange={(v) => this.updateProject(['data', 'global', 'headingStyles', 'fontWeight'], v)}>
                            {fonts.getFontFamilyWeightsForValue(fontFamily).map(weight => {
                                return (
                                    <option key={weight.value} value={weight.value}>
                                        {weight.name}
                                    </option>
                                )
                            })}
                        </SelectControl>

                        <SliderControl
                            name="Font size"
                            value={fontSize}
                            min={0}
                            max={100}
                            step={1}
                            unit="px"
                            onChange={(v) => this.updateProject(['data', 'global', 'headingStyles', 'fontSize'], v)}
                        />

                        <SliderControl
                            name="Letter spacing"
                            value={letterSpacing}
                            min={-100}
                            max={100}
                            step={1}
                            unit="px"
                            onChange={(v) => this.updateProject(['data', 'global', 'headingStyles', 'letterSpacing'], v)}
                        />

                        <SliderControl
                            name="Line height"
                            value={lineHeight}
                            min={100}
                            max={300}
                            multiplier={100}
                            step={1}
                            unit="%"
                            onChange={(v) => this.updateProject(['data', 'global', 'headingStyles', 'lineHeight'], v)}
                        />

                        <ButtonGroup
                            name="Text style and alignment"
                            buttons={[
                                {
                                    name: 'italic',
                                    icon: 'format_italic',
                                    active: fontStyle === 'italic'
                                },
                                {
                                    name: 'underline',
                                    icon: 'format_underlined',
                                    active: textDecoration.includes('underline')
                                },
                            ]}
                            onClick={this.handleFontStyleChange}
                        />

                        <ButtonGroup
                            buttons={[
                                {
                                    name: 'left',
                                    icon: 'format_align_left',
                                    active: textAlign === 'left'
                                },
                                {
                                    name: 'center',
                                    icon: 'format_align_center',
                                    active: textAlign === 'center'
                                },
                                {
                                    name: 'right',
                                    icon: 'format_align_right',
                                    active: textAlign === 'right'
                                }
                            ]}
                            onClick={(buttonName) => this.updateProject(['data', 'global', 'headingStyles', 'textAlign'], buttonName)}
                        />

                        <ColorControl
                            name="Color"
                            color={color}
                            disableAlpha={false}
                            onChange={(v) => this.updateProject(['data', 'global', 'headingStyles', 'color'], v)}
                        />
                    </div>
                </div>


            </div>
        );
    }
}

export default GlobalSettings;