import React, {Component} from 'react';
import ScreenItem from '../ScreenItem/ScreenItem';
import {getMatchingScreenshot, getApproxScreenshot} from "./screenUtils";
import {getLanguageTitle} from '../../../../languages';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSpinner} from '@fortawesome/free-solid-svg-icons'

const classNames = require('classnames');

class Screen extends Component {

    getSizeForUUID = (uuid) => {
        const {sizes} = this.props;
        for (let i = 0; i < sizes.size; i++) {
            if (sizes.getIn([i, 'uuid']) === uuid) {
                return sizes.get(i);
            }
        }
    };

    render() {
        const {project, screen, screenshots, frameImages, activeScreen, renderingScreenItems} = this.props;

        return (
            <div className="screen-container">

                <div
                    className={classNames("screen-container-inner", screen.get('id') === activeScreen ? 'active' : '')}
                    onClick={() => this.props.onScreenClick(screen.get('id'))}
                >
                    {project.get('sizes').map(sizeUUID => {
                        const size = this.getSizeForUUID(sizeUUID);

                        const matchingScreenshot = getMatchingScreenshot({
                            project: project,
                            screenshots: screenshots,
                            size: size,
                            screen: screen
                        });

                        const approxScreenshot = getApproxScreenshot({
                            project: project,
                            screenshots: screenshots,
                            size: size,
                            screen: screen,
                            languageCode: project.get('last_language')
                        });

                        const screenshot = matchingScreenshot || approxScreenshot;

                        const screenItemId = screen.get('id') + '_' + sizeUUID;
                        const isRendering = renderingScreenItems.includes(screenItemId);

                        return (
                            <div key={sizeUUID} className="screen-item-container">
                                <div className="heading-container">
                                    <p>
                                        {size.get('title')}

                                        {isRendering &&
                                        <FontAwesomeIcon icon={faSpinner} spin/>
                                        }
                                    </p>
                                </div>

                                <ScreenItem
                                    screenItemId={screenItemId}
                                    project={project}
                                    size={size}
                                    screen={screen}
                                    frameImages={frameImages}
                                    screenshot={screenshot}
                                />

                                <div className="info-container">
                                    {(screenshot == null || screenshot.get('size_uuid') !== size.get('uuid')) &&
                                    <p>Missing screenshot</p>
                                    }

                                    {screenshot != null &&
                                    <p>Language: {getLanguageTitle(screenshot.get('language'))}</p>
                                    }
                                </div>
                            </div>
                        )
                    })}


                </div>

            </div>

        )
    }
}

export default Screen;