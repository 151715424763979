import 'whatwg-fetch'
import auth0Client from "../Auth";
import {apiHost} from '../constants';

const { fromJS } = require('immutable');

export const FETCH_IMAGES = 'FETCH_IMAGES';
export const RECEIVE_IMAGES = 'RECEIVE_IMAGES';

export const FETCH_STOCK_IMAGES = 'FETCH_STOCK_IMAGES';
export const RECEIVE_STOCK_IMAGES = 'RECEIVE_STOCK_IMAGES';

//images
export function requestImages() {
    return {
        type: FETCH_IMAGES
    }
}


export function receiveImages(images) {
    return {
        type: RECEIVE_IMAGES,
        images
    }
}


export function fetchImages() {
    return (dispatch, getState) => {
        const state = getState();

        if (!state.imageReducer.isFetchingImages) {
            dispatch(requestImages());
            const url = apiHost + '/images';
            return fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${auth0Client.getIdToken()}`
                }
            })
                .then(response => response.json())
                .then(json => {
                    const images = fromJS(json);
                    dispatch(receiveImages(images))
                })
                .catch(err => {
                    console.error('fetchImages error:', err);
                });
        }
        else {
            return Promise.resolve();
        }
    }
}


//stock photos
export function requestStockImages() {
    return {
        type: FETCH_STOCK_IMAGES
    }
}


export function receiveStockImages(queryResult) {
    return {
        type: RECEIVE_STOCK_IMAGES,
        queryResult
    }
}


export function fetchStockImages(query, page) {
    return (dispatch, getState) => {
        const state = getState();

        if (!state.imageReducer.isFetchingStockImages) {
            dispatch(requestStockImages());
            const url = apiHost + '/images/stock-photos?query=' + query + '&page=' + page;
            return fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${auth0Client.getIdToken()}`
                }
            })
                .then(response => response.json())
                .then(json => {
                    dispatch(receiveStockImages(fromJS(json)));
                })
                .catch(err => {
                    console.error('fetchStockImages error:', err);
                });
        }
        else {
            return Promise.resolve();
        }
    }
}

export function triggerDownloadAction(photoId) {
    return () => {
        const url = apiHost + '/images/trigger-unsplash-download/' + photoId;
        return fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${auth0Client.getIdToken()}`,
                'Content-Type':'application/json'
            }
        })
            .then((response) => {
                console.log('triggerDownloadAction success. status:', response.status)
            })
            .catch(err => {
                console.error('triggerDownloadAction error:', err);
            });
    }
}