import 'whatwg-fetch'
import auth0Client from "../Auth";
import {apiHost} from '../constants';

const { fromJS } = require('immutable');

export const FETCH_PROJECTS = 'FETCH_PROJECTS';
export const RECEIVE_PROJECTS = 'RECEIVE_PROJECTS';

export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const DELETE_PROJECT = 'DELETE_PROJECT';
export const ADD_PROJECT = 'ADD_PROJECT';


//projects
export function requestProjects() {
    return {
        type: FETCH_PROJECTS
    }
}


export function receiveProjects(projects) {
    return {
        type: RECEIVE_PROJECTS,
        projects
    }
}


export function fetchProjects() {
    return (dispatch, getState) => {
        const state = getState();

        if (!state.projectReducer.isFetchingProjects) {
            dispatch(requestProjects());
            const url = apiHost + '/projects';
            return fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${auth0Client.getIdToken()}`
                }
            })
                .then(response => response.json())
                .then(json => {
                    const projects = fromJS(json);
                    dispatch(receiveProjects(projects))
                })
                .catch(err => {
                    console.error('fetchProjects error:', err);
                });
        }
        else {
            return Promise.resolve();
        }
    }
}

export function saveProject(project) {
    return () => {
        const data = {
            project_uuid: project.get('uuid'),
            project_name: project.get('name'),
            project_data: project.get('data').toJS(),
            project_languages: project.get('languages').toJS(),
            last_language: project.get('last_language'),
            screenshot_default_language: project.get('screenshot_default_language'),
            project_notes: project.get('notes'),
            sizes: project.get('sizes')
        };
        return fetch(apiHost + '/projects/save', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${auth0Client.getIdToken()}`,
                'Content-Type':'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                return Promise.resolve(response.status)
            })
            .catch(err => {
                console.error('saveProject error:', err);
            });
    }
}


//create project
export function createProject(appUUID, projectName) {
    return () => {
        const data = {
            app_uuid: appUUID,
            project_name: projectName
        };
        return fetch(apiHost + '/projects/create', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${auth0Client.getIdToken()}`,
                'Content-Type':'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(json => {
                const projectUUID = json.project_uuid;
                return Promise.resolve(projectUUID)
            })
            .catch(err => {
                console.error('createCampaign error:', err);
            });
    }
}

// update project
export function updateProject(newProject) {
    return {
        type: UPDATE_PROJECT,
        newProject
    }
}


// delete project
export function deleteProject(projectUUID) {
    return {
        type: DELETE_PROJECT,
        projectUUID
    }
}


export function deleteProjectAction(projectUUID) {
    return dispatch => {
        dispatch(deleteProject(projectUUID));
        const data = {
            project_uuid: projectUUID
        };
        return fetch(apiHost + '/projects/delete', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${auth0Client.getIdToken()}`,
                'Content-Type':'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(() => {
                //nothing to do
            })
            .catch(err => {
                console.error('deleteProjectAction error:', err);
            });
    }
}

// duplicate project
export function addProject(project) {
    return {
        type: ADD_PROJECT,
        project
    }
}


export function duplicateProjectAction(projectUUID, projectName) {
    return dispatch => {
        const data = {
            project_uuid: projectUUID,
            project_name: projectName
        };
        return fetch(apiHost + '/projects/duplicate', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${auth0Client.getIdToken()}`,
                'Content-Type':'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(json => {
                const newProject = fromJS(json);
                dispatch(addProject(newProject))
            })
            .catch(err => {
                console.error('duplicateProjectAction error:', err);
            });
    }
}