import React, {Component} from 'react';
import {connect} from 'react-redux'
import {Link, withRouter} from "react-router-dom";
import {fetchApps} from "../../actions/appActions";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import Page from '../../Components/Page/Page';
import NoAppsPage from './NoAppsPage';
import { Button } from 'react-bootstrap';
import {Container, Row, Col} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import './stylesApps.scss';


class Apps extends Component {

    componentDidMount() {
        this.props.dispatch(fetchApps());
    }

    handleAppClick = (appUUID) => {
        this.props.history.push('/apps/' + appUUID);
    };

    render() {
        const {currentUser, accountInfo, apps} = this.props;

        if (currentUser != null && apps != null) {
            if (apps.size >0) {
                return (
                    <div className="main">

                        <Page
                            className="page-apps"
                            currentUser={currentUser}
                            accountInfo={accountInfo}
                        >

                            <Container fluid>
                                <Row>

                                    <Col sm={12}>

                                        <div className="header-container">
                                            <div className="text-container">
                                                <h1>Apps</h1>
                                                <p className="subheading">View, add and manage all your apps here.</p>
                                            </div>

                                            <div className="btn-container">

                                                <Button
                                                    variant="primaryNew"
                                                    as={Link}
                                                    to='/apps/new'
                                                >
                                                    <FontAwesomeIcon icon={faPlus}/>
                                                    New App
                                                </Button>

                                            </div>

                                        </div>


                                        <div className="apps-container">
                                            {apps.map(app => {
                                                return (
                                                    <div
                                                        className="app-container"
                                                        key={app.get('uuid')}
                                                    >
                                                        <div
                                                            className="app-container-inner"
                                                            style={{backgroundImage: 'url(' + app.get('image_url') + ')'}}
                                                            onClick={() => this.handleAppClick(app.get('uuid'))}
                                                        />
                                                        <div className="info-container">
                                                            <p className="name">{app.get('name')}</p>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Page>
                    </div>
                )
            }
            else {
                return (
                    <NoAppsPage/>
                )
            }
        }
        else {
            return (
                <LoadingSpinner/>
            )
        }
    }
}


const mapStateToProps = (state) => {
    return {
        apps: state.appReducer.apps
    }
};


export default connect(mapStateToProps)(withRouter(Apps));
