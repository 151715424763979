import React, {Component} from 'react';
import {Link} from "react-router-dom";
import Rocket from '../../images/campaigns/rocket.svg';
import { Button } from 'react-bootstrap';
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Card from "../../Components/Card/Card";
import './stylesNoProjectsCard.scss';

class NoProjectsCard extends Component {
    render() {
        return (
            <Card className="no-projects-card">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="heading-container">
                            <h1>Your first Project!</h1>
                            <p>Improve customer interactions with awesome Pop-ups.<br/> Follow a few simple steps to
                                get
                                started.</p>


                        </div>

                        <div className="img-container" style={{backgroundImage: `url(${Rocket})`}} />


                        <div className="btn-container">
                            <Button
                                variant="primaryNew"
                                as={Link}
                                to='/projects/new'
                            ><FontAwesomeIcon icon={faPlus} /> Create a project</Button>
                        </div>

                    </div>
                </div>
            </Card>
        )
    }
}

export default NoProjectsCard;