import JSZip from "jszip";
import {saveAs} from 'file-saver'
import moment from 'moment';
import {createCanvas} from "../../Screens/ScreenItem/canvasUtils";
import {getMatchingScreenshot, getApproxScreenshot} from "../../Screens/Screen/screenUtils";
import {mergeScreen} from '../../Screens/ScreenItem/mergeScreen';


function getSizeForUUID(sizes, uuid) {
    for (let i = 0; i < sizes.size; i++) {
        if (sizes.getIn([i, 'uuid']) === uuid) {
            return sizes.get(i);
        }
    }
}

function createBlob(params) {
    return new Promise((resolve) => {
        const project = params.project;
        const languageCode = params.languageCode;
        const size = params.size;
        const screen = params.screen;
        const frameImages = params.frameImages;
        const screenshots = params.screenshots;
        const screenIdx = params.screenIdx;


        const matchingScreenshot = getMatchingScreenshot({
            project: project,
            screenshots: screenshots,
            size: size,
            screen: screen
        });

        const approxScreenshot = getApproxScreenshot({
            project: project,
            screenshots: screenshots,
            size: size,
            screen: screen,
            languageCode: project.get('last_language')
        });

        createCanvas({
            project: project,
            size: size,
            screen: screen,
            frameImages: frameImages,
            screenshot: matchingScreenshot || approxScreenshot
        }).then((canvas) => {

            let mimeType = '';
            if (project.get('last_download_format') === 'jpg') {
                mimeType = 'image/jpeg';
            }
            else {
                mimeType = 'image/png';
            }

            canvas.toBlob((blob) => {
                resolve({
                    blob: blob,
                    languageCode: languageCode,
                    size: size,
                    screenIdx: screenIdx
                });
            }, mimeType);
        });
    });
}

export function createScreenshots(params) {
    return new Promise((resolve) => {
        const project = params.project;
        const sizes = params.sizes;
        const frameImages = params.frameImages;
        const screenshots = params.screenshots;

        let promises = [];

        project.get('languages').forEach(languageCode => {

            project.get('sizes').forEach(sizeUUID => {
                const size = getSizeForUUID(sizes, sizeUUID);

                project.getIn(['data', 'screens']).forEach((screen, idx) => {

                    const mergedScreen = mergeScreen(project, screen);

                    promises.push(createBlob({
                        project: project,
                        languageCode: languageCode,
                        size: size,
                        screen: mergedScreen,
                        frameImages: frameImages,
                        screenshots: screenshots,
                        screenIdx: (idx + 1)
                    }));
                });
            });
        });

        Promise.all(promises).then(results => {
            let zip = new JSZip();

            project.get('languages').forEach(languageCode => {
                const languageFolder = zip.folder(languageCode);

                project.get('sizes').forEach(sizeUUID => {
                    const size = getSizeForUUID(sizes, sizeUUID);
                    const sizeFolder = languageFolder.folder(size.get('title'));

                    results.forEach(result => {
                        if (result.languageCode === languageCode && result.size.get('uuid') === sizeUUID) {
                            const filename = result.screenIdx + '_' + result.languageCode + '_' + result.size.get('title') + '.' + project.get('last_download_format');
                            sizeFolder.file(filename, result.blob, {base64: true});
                        }
                    });
                });
            });

            zip.generateAsync({type: "blob"})
                .then(function (content) {
                    let projectName = project.get('name');

                    if (projectName.length === '') {
                        projectName = 'Untitled';
                    }

                    const archiveName = projectName + ' - Screenshots - ' + moment().format('ll') + '.zip';
                    saveAs(content, archiveName);

                    resolve();
                });
        });
    });
}