import React, {Component} from 'react';

const classNames = require('classnames');


class ToolbarLeft extends Component {
    constructor(props) {
        super(props);

        this.items = [
            {name: 'settings', title: 'Project', icon: 'settings'},
            {name: 'globalSettings', title: 'Global Styles', icon: 'style'},
            {name: 'screenshots', title: 'Screenshots', icon: 'insert_photo'},
            {name: 'download', title: 'Download', icon: 'cloud_download'},
        ]
    }



    render() {

        return (
            <div className="toolbar-left">

                {this.items.map(item => {
                    return (
                        <button
                            key={item.name}
                            className={classNames('tool-link', this.props.activeItem === item.name ? 'active' : '')}
                            onClick={() => this.props.onClick(item.name)}
                        >
                            <i className="material-icons">{item.icon}</i>
                            <p>{item.title}</p>

                        </button>
                    )
                })}

            </div>
        );
    }
}


export default ToolbarLeft;
