import React, {Component} from 'react';
import {connect} from 'react-redux'
import {Container, Row, Col} from 'react-bootstrap';
import Card from "../../../Components/Card/Card";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import ProfileInfo from './ProfileInfo';
import EditingProfileInfo from './EditingProfileInfo';
import AccountInfo from './AccountInfo';
import EditingAccountInfo from './EditingAccountInfo';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPencilAlt} from '@fortawesome/free-solid-svg-icons'
import {apiHost} from "../../../constants";
import auth0Client from "../../../Auth";
import {fetchCurrentUserIfNeeded} from "../../../actions/userActions";
import Page from '../../../Components/Page/Page';
import PasswordCard from './PasswordCard';
import { Button } from 'react-bootstrap';



const classNames = require('classnames');

class SettingsGeneral extends Component {
    constructor(props) {
        super(props);

        this.inputOpenFileRef = React.createRef();

        this.state = {
            editingProfileInfo: false,
            editingAccountInfo: false
        };
    }

    handleBtnClick = (btnName) => {
        const {currentUser} = this.props;
        const ssoProvider = currentUser.get('sso_provider');

        switch (btnName) {
            case 'editProfileInfo':
                this.setState({editingProfileInfo: true});
                break;

            case 'updateProfileInfo':
                this.setState({editingProfileInfo: false});
                break;

            case 'updateProfileImage':
                if (ssoProvider === '') {
                    this.inputOpenFileRef.current.click();
                }
                break;

            case 'editAccountInfo':
                this.setState({editingAccountInfo: true});
                break;

            case 'updateAccountInfo':
                this.setState({editingAccountInfo: false});
                break;

            default:
                console.log('Unknown btnName.')
        }
    };

    handleImageUpload = (e) => {
        const file = e.target.files[0];
        let formData = new FormData();
        formData.append('upload', file);

        fetch(apiHost + '/users/update-profile-image', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${auth0Client.getIdToken()}`,
            },
            body: formData
        })
            .then(() => {
                console.log('Upload finished.');
                this.props.dispatch(fetchCurrentUserIfNeeded(true))
                    .then(() => {
                        console.log('fetched currentUser');
                    })
                    .catch(err => {
                        console.error('error:', err);
                    });
            })

            .catch(err => {
                console.error('upload image error:', err);
            });
    };

    render() {
        const {editingProfileInfo, editingAccountInfo} = this.state;
        const {currentUser, accountInfo} = this.props;

        if (currentUser != null) {

            const ssoProvider = currentUser.get('sso_provider');

            return (
                <div className="main">
                    <Sidebar
                        page="settings"
                        subpage="general"
                        currentUser={currentUser}
                        accountInfo={accountInfo}
                    />

                    <Page
                        className="page-settings-general"
                        currentUser={currentUser}
                        accountInfo={accountInfo}
                    >
                        <Container fluid={true}>
                            <Row>
                                <Col sm={12}>
                                    <h1>Settings - General</h1>

                                    <Card header="Profile" className="card-profile">
                                        <Row className="align-items-center">
                                            <Col sm={3}>
                                                <label>Profile picture</label>
                                            </Col>

                                            <Col sm={9}>
                                                <div
                                                    className={classNames('img-profile-container', ssoProvider === '' ? 'img-profile-container-editable' : '')}
                                                    onClick={() => this.handleBtnClick('updateProfileImage')}
                                                >
                                                    <img
                                                        src={currentUser.get('picture')}
                                                        className="img-profile"
                                                        alt="profile_picture"
                                                    />
                                                    <FontAwesomeIcon
                                                        icon={faPencilAlt}
                                                        size="2x"
                                                        className="pen"
                                                    />

                                                    <input
                                                        ref={this.inputOpenFileRef}
                                                        type="file"
                                                        style={{display: "none"}}
                                                        onChange={this.handleImageUpload}
                                                    />
                                                </div>

                                            </Col>
                                        </Row>

                                        {!editingProfileInfo && ssoProvider === 'google-oauth2' && (
                                            <div>
                                                <ProfileInfo currentUser={currentUser}/>
                                                <div className="managed-by-google-container">
                                                    <p>Your profile information is managed by Google</p>
                                                </div>
                                            </div>
                                        )}

                                        {!editingProfileInfo && ssoProvider === '' && (
                                            <div>
                                                <ProfileInfo currentUser={currentUser}/>

                                                <Button
                                                    variant="primaryNew"
                                                    onClick={() => this.handleBtnClick('editProfileInfo')}
                                                >
                                                    Edit
                                                </Button>
                                            </div>
                                        )}

                                        {editingProfileInfo && (
                                            <EditingProfileInfo
                                                currentUser={currentUser}
                                                onUpdateBtnClick={() => this.handleBtnClick('updateProfileInfo')}
                                            />
                                        )}


                                    </Card>

                                    <Card header="Account" className="card-account">

                                        {!editingAccountInfo && (
                                            <div>
                                                <AccountInfo accountInfo={accountInfo}/>
                                                <Button
                                                    variant="primaryNew"
                                                    onClick={() => this.handleBtnClick('editAccountInfo')}
                                                >
                                                    Edit
                                                </Button>
                                            </div>
                                        )}

                                        {editingAccountInfo && (
                                            <EditingAccountInfo
                                                accountInfo={accountInfo}
                                                onUpdateBtnClick={() => this.handleBtnClick('updateAccountInfo')}
                                            />
                                        )}
                                    </Card>

                                    {ssoProvider === '' && (
                                        <PasswordCard
                                            currentUser={currentUser}
                                        />
                                    )}

                                </Col>
                            </Row>

                        </Container>
                    </Page>
                </div>
            );
        } else {
            return (
                <div/>
            )
        }
    }
}

export default connect()(SettingsGeneral);