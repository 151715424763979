import 'whatwg-fetch'
import auth0Client from "../Auth";
import {apiHost} from '../constants';

const { fromJS } = require('immutable');

export const FETCH_LOCATION_DATA = 'FETCH_LOCATION_DATA';
export const RECEIVE_LOCATION_DATA = 'RECEIVE_LOCATION_DATA';
export const SET_COUNTRY_CODE = 'SET_COUNTRY_CODE';


export function requestLocationData() {
    return {
        type: FETCH_LOCATION_DATA
    }
}


export function receiveLocationData(locationData) {
    return {
        type: RECEIVE_LOCATION_DATA,
        locationData
    }
}


function fetchLocationData() {
    return dispatch => {
        dispatch(requestLocationData());
        const url = apiHost + '/users/location';
        return fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${auth0Client.getIdToken()}`
            }
        })
            .then(response => response.json())
            .then(json => {
                const locationData = fromJS(json);
                dispatch(receiveLocationData(locationData))
            })
            .catch(err => {
                console.error('fetchLocationData error:', err);
            });
    }
}


function shouldFetchLocationData(state) {
    const locationReducer = state.locationReducer;
    if (locationReducer.isFetchingLocationData || locationReducer.locationData != null) {
        return false;
    }
    return true;
}


export function fetchLocationDataIfNeeded() {
    return (dispatch, getState) => {
        if (shouldFetchLocationData(getState())) {
            return dispatch(fetchLocationData())
        } else {
            return Promise.resolve()
        }
    }
}


export function setCountryCode(countryCode) {
    return {
        type: SET_COUNTRY_CODE,
        countryCode
    }
}