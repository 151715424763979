import React, {Component} from 'react';
import {connect} from 'react-redux';
import Button from "../../../../Components/Button/Button";
import {maxFileUploadSizeMB} from "../../../../constants";
import {uploadScreenshotAction} from '../../../../actions/screenshotActions';


class ReplaceImage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isUploading: false,
            fileTooLargeError: false
        }
    };

    handleImageUpload = (e) => {
        const {project, size, languageCode, selectedScreenId} = this.props;

        let file = e.target.files[0];

        const sizeMB = file.size / 1000000;

        if (sizeMB > maxFileUploadSizeMB) {
            this.setState({
                fileTooLargeError: true
            });
        } else {
            this.setState({
                fileTooLargeError: false,
                isUploading: true
            });

            const img = new Image();
            const objectUrl = window.URL.createObjectURL(file);
            img.onload = () => {
                let formData = new FormData();
                formData.append('project_uuid', project.get('uuid'));
                formData.append('size_uuid', size.get('uuid'));
                formData.append('upload', file);
                formData.append('width', img.width.toString());
                formData.append('height', img.height.toString());
                formData.append('language', languageCode);
                formData.append('screen_id', selectedScreenId);

                this.props.dispatch(uploadScreenshotAction(formData))
                    .then(() => {
                        this.setState({isUploading: false});
                    })
                    .catch(err => {
                            console.error('upload image error:', err);
                        });

                window.URL.revokeObjectURL(objectUrl);
            };
            img.src = objectUrl;

        }
    };

    render() {
        const {isUploading, fileTooLargeError} = this.state;

        return (
            <div>
                <div className="image-upload-control">
                    <form encType="multipart/form-data" className="upload-btn-wrapper">
                        <Button
                            className="btn-small btn-upload-image"
                            secondary
                            waiting={isUploading}
                        >Replace</Button>

                        <input
                            disabled={isUploading}
                            type="file"
                            accept="image/*"
                            onChange={this.handleImageUpload}
                        />
                    </form>
                </div>

                {fileTooLargeError && (
                    <p className="file-too-large-error">Image was too large. Max file size is 10MB.</p>
                )}
            </div>
        );
    }
}

export default connect()(ReplaceImage);
