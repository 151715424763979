import React, {Component} from 'react';
import Settings from './Sidebars/Settings';
import Screenshots from './Sidebars/Screenshots/Screenshots';
import Download from './Sidebars/Download/Download';
import ScreenSettings from './Sidebars/ScreenSettings/ScreenSettings'
import GlobalSettings from './Sidebars/GlobalSettings/GlobalSettings'

class Sidebar extends Component {
    render() {
        const {accountInfo, project, sizes, screenshots, activeScreen, frameImages} = this.props;

        switch (this.props.name) {

            case 'settings':
                return (
                    <Settings
                        project={project}
                        sizes={sizes}
                        onProjectChange={this.props.onProjectChange}
                    />
                );

            case 'screenshots':
                return (
                    <Screenshots
                        project={project}
                        sizes={sizes}
                        screenshots={screenshots}
                        onProjectChange={this.props.onProjectChange}
                    />
                );

            case 'download':
                return (
                    <Download
                        accountInfo={accountInfo}
                        project={project}
                        sizes={sizes}
                        screenshots={screenshots}
                        frameImages={frameImages}
                        onProjectChange={this.props.onProjectChange}
                        onBtnPurchaseClick={this.props.onBtnPurchaseClick}
                    />
                );

            case 'screenSettings':
                return (
                    <ScreenSettings
                        project={project}
                        activeScreen={activeScreen}
                        onScreenDelete={this.props.onScreenDelete}
                        onProjectChange={this.props.onProjectChange}
                    />
                );

            case 'globalSettings':
                return (
                    <GlobalSettings
                        project={project}
                        onProjectChange={this.props.onProjectChange}
                    />
                );


            default:
                return (
                    <div/>
                );
        }

    }
}


export default Sidebar;
