import 'whatwg-fetch'
import auth0Client from "../Auth";
import {apiHost} from '../constants';

const { fromJS } = require('immutable');

export const FETCH_CURRENT_USER = 'FETCH_CURRENT_USER';
export const RECEIVE_CURRENT_USER = 'RECEIVE_CURRENT_USER';

export const PURCHASE_PLAN = 'PURCHASE_PLAN';
export const CHANGE_PLAN = 'CHANGE_PLAN';

export const SET_LAST_APP = 'SET_LAST_APP';
export const TOGGLE_AUTO_RENEWAL = 'TOGGLE_AUTO_RENEWAL';


//user
export function requestCurrentUser() {
    return {
        type: FETCH_CURRENT_USER
    }
}


export function receiveCurrentUser(currentUser) {
    return {
        type: RECEIVE_CURRENT_USER,
        currentUser
    }
}


function fetchCurrentUser() {
    return dispatch => {
        dispatch(requestCurrentUser());
        return fetch(apiHost + '/users/current', {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${auth0Client.getIdToken()}`
            }
        })
            .then(response => response.json())
            .then(json => {
                const user = fromJS(json);



                dispatch(receiveCurrentUser(user))
            })
            .catch(err => {
                console.error('fetchCurrentUser error:', err);
            });
    }
}


function shouldFetchCurrentUser(state, updateExisting) {
    const appDataReducer = state.userReducer;
    if (appDataReducer.isFetchingCurrentUser || (appDataReducer.currentUser != null && !updateExisting)) {
        return false;
    }
    return true;
}


export function fetchCurrentUserIfNeeded(updateExisting) {
    return (dispatch, getState) => {
        if (shouldFetchCurrentUser(getState(), updateExisting)) {
            return dispatch(fetchCurrentUser())
        }
        else {
            return Promise.resolve()
        }
    }
}


//change plan
// toggle website status

export function changePlan(plan) {
    return {
        type: CHANGE_PLAN,
        plan
    }
}


export function changePlanAction(planUUID) {
    return dispatch => {
        const data = {
            plan_uuid: planUUID
        };
        return fetch(apiHost + '/account/change-plan', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${auth0Client.getIdToken()}`,
                'Content-Type':'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(json => {
                const plan = fromJS(json.plan);
                dispatch(changePlan(plan));
            })
            .catch(err => {
                console.error('setCampaignStatusAction error:', err);
            });
    }
}


//update profile
export function updateProfileAction(profileInfo) {
    return () => {
        const data = {
            profile_info: profileInfo
        };
        return fetch(apiHost + '/users/update-profile', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${auth0Client.getIdToken()}`,
                'Content-Type':'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((response) => {
                console.log('profile updated successfully. status:', response.status)
            })
            .catch(err => {
                console.error('updateProfileAction error:', err);
            });
    }
}





// set last website
export function setLastApp(appUUID) {
    return {
        type: SET_LAST_APP,
        appUUID
    }
}


export function setLastAppAction(appUUID) {
    return dispatch => {
        dispatch(setLastApp(appUUID));
        const data = {
            app_uuid: appUUID,
        };
        return fetch(apiHost + '/users/set-last-app', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${auth0Client.getIdToken()}`,
                'Content-Type':'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(() => {
                //nothing to do
            })
            .catch(err => {
                console.error('setLastAppAction error:', err);
            });
    }
}


//update password
export function updatePasswordAction(password) {
    return () => {
        const data = {
            password: password
        };
        return fetch(apiHost + '/users/update-password', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${auth0Client.getIdToken()}`,
                'Content-Type':'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                console.log('updatePasswordAction:', response);
                return Promise.resolve();
            })
            .catch(err => {
                console.error('updatePasswordAction error:', err);
            });
    }
}


// toggle auto renewal
export function toggleAutoRenewal() {
    return {
        type: TOGGLE_AUTO_RENEWAL
    }
}


export function toggleAutoRenewalAction() {
    return dispatch => {
        dispatch(toggleAutoRenewal());
        return fetch(apiHost + '/account/toggle-auto-renewal', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${auth0Client.getIdToken()}`,
                'Content-Type':'application/json'
            }
        })
            .then(response => {
                console.log('toggleAutoRenewalAction success', response)

            })
            .catch(err => {
                console.error('toggleAutoRenewalAction error:', err);
            });
    }
}