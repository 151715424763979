import React, {Component} from 'react';
import {CardNumberElement, CardExpiryElement, CardCvcElement
} from '@stripe/react-stripe-js';
import {ErrorMessage, Field, Form, Formik} from "formik";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import * as Yup from "yup";
import Card from "../../Components/Card/Card";
import ccIconVisa from "./cc-icon-visa.svg";
import ccIconMastercard from "./cc-icon-mastercard.svg";
import ccIconAmex from "./cc-icon-amex.svg";
import ccIconDiners from "./cc-icon-diners.svg";
import ccIconJcb from "./cc-icon-jcb.svg";
import {ReactComponent as LockIcon} from './lock.svg';
import PurchaseSummary from './PurchaseSummary';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSpinner, faArrowRight} from '@fortawesome/free-solid-svg-icons'


const formSchema = Yup.object().shape({
    name: Yup.string()
        .required('Please enter a name')
});


class PaymentRow extends Component {
    constructor(props) {
        super(props);

        this.state = {
            vatRate: 0,
            cardNumberValid: true,
            cardExpiryValid: true,
            cardCVCValid: true
        }
    }

    componentDidMount() {
        const countryCode = this.props.locationData.get('country_code');
        const vatRate = this.getVatRateForCountry(countryCode);
        this.setState({
            vatRate: vatRate
        });
    }

    getVatRateForCountry = (countryCode) => {
        for (let i = 0; i < this.props.locationData.get('country_list').size; i++) {
            const country = this.props.locationData.getIn(['country_list', i]);
            if (country.get('code') === countryCode) {
                return country.get('rate');
            }
        }
        return null;
    };

    handleCountryChange = (e) => {
        const vatRate = this.getVatRateForCountry(e.target.value);
        this.setState({
            vatRate: vatRate
        });
        this.props.onCountryCodeChange(e.target.value);
    };

    handleChange = (change) => {
        switch (change.elementType) {
            case 'cardNumber':
                this.setState({cardNumberValid: true});
                break;

            case 'cardExpiry':
                this.setState({cardExpiryValid: true});
                break;

            case 'cardCvc':
                this.setState({cardCVCValid: true});
                break;

            default:
                console.log('unknown');
        }
    };

    createOptions = () => {
        return {
            style: {
                base: {
                    fontSize: '14px',
                    color: '#2b5672',
                    fontFamily: 'Roboto, sans-serif',
                    fontWeight: '300',
                    '::placeholder': {
                        color: '#aab7c4',
                    },
                    lineHeight: '36px'
                },
                invalid: {
                    color: '#9e2146',
                },
            }
        }
    };

    handleSubmit = async (values) => {
        const {stripe, elements, locationData} = this.props;
        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardNumberElement),
            billing_details: {
                name: values.name,
                address: {
                    country: locationData.get('country_code')
                }
            }
        });

        return {error: error, paymentMethod: paymentMethod}
    };

    formatPrice = (n) => {
        return parseFloat(n).toFixed(2);
    };

    render() {
        const {plan, frequency, locationData, isPurchasing} = this.props;
        const {vatRate, cardNumberValid, cardExpiryValid, cardCVCValid} = this.state;

        const subtotal = frequency === 'yearly' ? parseFloat(plan.get('price_yearly')) * 12 : parseFloat(plan.get('price_monthly'));

        const vat = subtotal * vatRate / 100;
        const totalDue = subtotal + vat;

        return (
            <div className="payment-form-container">
                <Formik
                    initialValues={{
                        name: '',
                        country: ''
                    }}
                    validationSchema={formSchema}
                    onSubmit={(values, {setSubmitting}) => {

                        setSubmitting(true);

                        this.handleSubmit(values)
                            .then(({error, paymentMethod}) => {
                                if (error != null) {
                                    console.log('*** error', error);
                                    if (error.type === 'validation_error') {
                                        switch (error.code) {
                                            case 'incomplete_number':
                                            case 'invalid_number':
                                                this.setState({cardNumberValid: false});
                                                break;

                                            case 'incomplete_expiry':
                                            case 'invalid_expiry':
                                                this.setState({cardExpiryValid: false});
                                                break;

                                            case 'incomplete_cvc':
                                            case 'invalid_cvc':
                                                this.setState({cardCVCValid: false});
                                                break;

                                            default:
                                                console.log('unknown');
                                        }
                                    }
                                }
                                else {
                                    console.log('success. paymentMethod=', paymentMethod);
                                    this.props.onSubmitForm(paymentMethod.id, values);
                                }

                                setSubmitting(false);
                            });
                    }}
                >
                    {({isSubmitting}) => (
                        <Form>
                            <Row>
                                <Col sm={12}>
                                    <PurchaseSummary
                                        plan={plan}
                                        frequency={frequency}
                                        onChangeFrequency={this.props.onChangeFrequency}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col sm={12}>
                                    <Card>
                                        <div className="total-payment-summary">
                                            {vat === 0 &&
                                            <p><FontAwesomeIcon icon={faArrowRight}/> Total due now: ${this.formatPrice(totalDue)}</p>
                                            }
                                            {vat > 0 &&
                                            <p><FontAwesomeIcon icon={faArrowRight}/> Total due now: ${this.formatPrice(totalDue)} (includes VAT of ${this.formatPrice(vat)})</p>
                                            }
                                        </div>

                                        <div className="cc-icons-container">
                                            <div className="cc-icon"
                                                 style={{backgroundImage: `url(${ccIconVisa})`}}/>
                                            <div className="cc-icon"
                                                 style={{backgroundImage: `url(${ccIconMastercard})`}}/>
                                            <div className="cc-icon"
                                                 style={{backgroundImage: `url(${ccIconAmex})`}}/>
                                            <div className="cc-icon"
                                                 style={{backgroundImage: `url(${ccIconDiners})`}}/>
                                            <div className="cc-icon"
                                                 style={{backgroundImage: `url(${ccIconJcb})`}}/>
                                        </div>

                                        <div>
                                            <Row>
                                                <Col sm={4}>
                                                    <div className="form-group">
                                                        <label>Card number</label>
                                                        <CardNumberElement
                                                            onBlur={this.handleBlur}
                                                            onChange={this.handleChange}
                                                            options={this.createOptions()}
                                                        />
                                                        {!cardNumberValid && (
                                                            <small className="form-text form-text-error">Please
                                                                enter a valid card number</small>
                                                        )}
                                                    </div>
                                                </Col>

                                                <Col sm={4}>
                                                    <div className="form-group">
                                                        <label>Exp Date</label>
                                                        <CardExpiryElement
                                                            onBlur={this.handleBlur}
                                                            onChange={this.handleChange}
                                                            options={this.createOptions()}
                                                        />
                                                        {!cardExpiryValid && (
                                                            <small className="form-text form-text-error">Please
                                                                enter a valid card expiry date</small>
                                                        )}
                                                    </div>
                                                </Col>

                                                <Col sm={4}>
                                                    <div className="form-group">
                                                        <label>Security Code / CVC</label>
                                                        <CardCvcElement
                                                            onBlur={this.handleBlur}
                                                            onChange={this.handleChange}
                                                            options={this.createOptions()}
                                                        />
                                                        {!cardCVCValid && (
                                                            <small className="form-text form-text-error">Please
                                                                enter a valid card security code</small>
                                                        )}
                                                    </div>
                                                </Col>

                                                <Col sm={6}>
                                                    <div className="form-group">
                                                        <label>Name</label>
                                                        <Field type="text" name="name"
                                                               className="form-control"/>
                                                        <ErrorMessage name="name" component="small"
                                                                      className="form-text form-text-error"/>
                                                    </div>
                                                </Col>

                                                <Col sm={6}>
                                                    <div className="form-group">
                                                        <label>Country</label>

                                                        <select
                                                            value={locationData.get('country_code')}
                                                            className="form-control"
                                                            onChange={this.handleCountryChange}
                                                        >
                                                            {locationData.get('country_list').map(country => (
                                                                <option
                                                                    key={country.get('code')}
                                                                    value={country.get('code')}
                                                                >{country.get('name')}</option>
                                                            ))}
                                                        </select>
                                                    </div>

                                                </Col>
                                            </Row>

                                            <div className="btn-container">
                                                <button
                                                    type="submit"
                                                    className="btn btn-primaryNew btn-submit"
                                                        disabled={isSubmitting || isPurchasing}
                                                >
                                                    {(isSubmitting || isPurchasing) &&
                                                    <FontAwesomeIcon icon={faSpinner} spin/>
                                                    }

                                                    {!isSubmitting && !isPurchasing &&
                                                        <div className="btn-inner">
                                                            <LockIcon/>
                                                            Submit Purchase
                                                        </div>
                                                    }

                                                </button>
                                            </div>

                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }
}

export default PaymentRow;