export const gradients = [
    {
        angle: 180,
        stops: [
            {offset: 0.00, color: '#ffe158'},
            {offset: 1.00, color: '#ffa951'}
        ]
    },
    {
        angle: 180,
        stops: [
            {offset: 0.00, color: '#2193b0'},
            {offset: 1.00, color: '#6dd5ed'}
        ]
    },
    {
        angle: 180,
        stops: [
            {offset: 0.00, color: '#C6FFDD'},
            {offset: 0.50, color: '#FBD786'},
            {offset: 1.00, color: '#f7797d'}
        ]
    },
    {
        angle: 180,
        stops: [
            {offset: 0.00, color: '#0F2027'},
            {offset: 0.50, color: '#203A43'},
            {offset: 1.00, color: '#f7797d'}
        ]
    },
    {
        angle: 180,
        stops: [
            {offset: 0.00, color: '#1f4037'},
            {offset: 1.00, color: '#99f2c8'}
        ]
    },
    {
        angle: 180,
        stops: [
            {offset: 0.00, color: '#f953c6'},
            {offset: 1.00, color: '#b91d73'}
        ]
    },
    {
        angle: 180,
        stops: [
            {offset: 0.00, color: '#7F7FD5'},
            {offset: 0.50, color: '#86A8E7'},
            {offset: 1.00, color: '#91EAE4'}
        ]
    },
    {
        angle: 180,
        stops: [
            {offset: 0.00, color: '#c31432'},
            {offset: 1.00, color: '#240b36'}
        ]
    },
    {
        angle: 180,
        stops: [
            {offset: 0.00, color: '#f12711'},
            {offset: 1.00, color: '#f5af19'}
        ]
    },
    {
        angle: 180,
        stops: [
            {offset: 0.00, color: '#659999'},
            {offset: 1.00, color: '#f4791f'}
        ]
    },
    {
        angle: 180,
        stops: [
            {offset: 0.00, color: '#dd3e54'},
            {offset: 1.00, color: '#6be585'}
        ]
    },
    {
        angle: 180,
        stops: [
            {offset: 0.00, color: '#8360c3'},
            {offset: 1.00, color: '#2ebf91'}
        ]
    },
    {
        angle: 180,
        stops: [
            {offset: 0.00, color: '#544a7d'},
            {offset: 1.00, color: '#ffd452'}
        ]
    },
    {
        angle: 180,
        stops: [
            {offset: 0.00, color: '#009FFF'},
            {offset: 1.00, color: '#ec2F4B'}
        ]
    },
    {
        angle: 180,
        stops: [
            {offset: 0.00, color: '#654ea3'},
            {offset: 1.00, color: '#eaafc8'}
        ]
    },
    {
        angle: 180,
        stops: [
            {offset: 0.00, color: '#FF416C'},
            {offset: 1.00, color: '#FF4B2B'}
        ]
    },
    {
        angle: 180,
        stops: [
            {offset: 0.00, color: '#8A2387'},
            {offset: 0.50, color: '#E94057'},
            {offset: 0.00, color: '#F27121'},
        ]
    }

];