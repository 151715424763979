import React, {Component} from 'react';
import {connect} from 'react-redux';
import UploadImage from './UploadImage';
import ReplaceImage from './ReplaceImage';
import Button from "../../../../Components/Button/Button";
import {deleteScreenshotAction} from '../../../../actions/screenshotActions';

class ScreenshotSizeBlock extends Component {

    getScreenshot = () => {
        const {project, screenshots, languageCode, size, selectedScreenId} = this.props;

        for (let i=0; i<screenshots.size; i++) {
            const screenshot = screenshots.get(i);
            if (screenshot.get('project_uuid') === project.get('uuid') && screenshot.get('screen_id') === selectedScreenId && screenshot.get('language') === languageCode && screenshot.get('size_uuid') === size.get('uuid')) {
                return screenshot;
            }
        }
    };

    handleBtnDeleteClick = () => {
        const {screenshot} = this.props;
        this.props.dispatch(deleteScreenshotAction(screenshot.get('uuid')));
    };

    render() {
        const {project, languageCode, size, selectedScreenId} = this.props;
        const screenshot = this.getScreenshot();

        return (
            <div className="screenshot-size-block">
                <div className="header">{size.get('title')}</div>

                <div className="block-container">
                    <div className="preview-container">

                        {!screenshot && (
                            <div className="screenshot-container screenshot-not-available">
                                <i className="material-icons">broken_image</i>
                            </div>
                        )}

                        {screenshot && (
                            <div className="screenshot-container screenshot-available" style={{backgroundImage: 'url(' + screenshot.get('url') + ')' }}/>
                        )}
                    </div>

                    <div className="info-container">
                        {!screenshot && (
                            <UploadImage
                                project={project}
                                size={size}
                                languageCode={languageCode}
                                selectedScreenId={selectedScreenId}
                            />
                        )}

                        {screenshot && (
                            <div>
                                <div className="description-container">
                                    <p>Filename: {screenshot.get('filename')}</p>
                                    <p>Size: {screenshot.get('width') + ' x ' + screenshot.get('height')}</p>

                                </div>

                                <div className="btn-container">
                                    <ReplaceImage
                                        project={project}
                                        size={size}
                                        languageCode={languageCode}
                                        selectedScreenId={selectedScreenId}
                                    />

                                    <Button
                                        secondary
                                        className="btn-small"
                                        onClick={this.handleBtnDeleteClick}
                                    >Remove</Button>
                                </div>
                            </div>
                        )}
                    </div>

                </div>

            </div>


        );
    }
}

export default connect()(ScreenshotSizeBlock);