import React, {Component} from 'react';
import {connect} from 'react-redux'
import * as Yup from "yup";
import {ErrorMessage, Field, Form, Formik} from "formik";
import Button from "../Button/Button";
import {fetchStockImages, triggerDownloadAction} from '../../actions/imageActions';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';


const formSchema = Yup.object().shape({
    query: Yup.string()
        .required('Please enter a search string.')
});


class StockPhotos extends Component {
    requestPage = (page) => {
        const {queryResult} = this.props;

        this.props.dispatch(fetchStockImages(queryResult.get('query'), page))
            .then(() => {
                //nothing to do (I hope!)
            })
            .catch(err => {
                console.error('fetching page error:', err);
            });
    };

    handlePhotoSelect = (result) => {
        this.props.dispatch(triggerDownloadAction(result.get('id')));
        this.props.onPhotoSelected(result);
    };

    render() {
        const {queryResult, isFetchingStockImages} = this.props;

        if (queryResult != null) {
            console.log(queryResult.get('total'))
        }

        return (
            <div className="image-library-page stock-photos-page">

                <h1>Stock Photos<span className="provider">by Unsplash</span></h1>

                <div className="query-container">

                    <Formik
                        initialValues={{query: ''}}
                        validationSchema={formSchema}
                        onSubmit={(values, {setSubmitting}) => {

                            this.props.dispatch(fetchStockImages(values.query, 1))
                                .then(() => {
                                    setSubmitting(false);
                                })
                                .catch(err => {
                                    console.error('fetching stock images error:', err);
                                });
                        }}
                    >
                        {({isSubmitting}) => (
                            <Form>
                                <div className="form-group row">
                                    <div className="col-sm-10">
                                        <Field type="text" name="query" className="form-control"
                                               placeholder="Search photos..."/>
                                        <ErrorMessage name="query" component="small"
                                                      className="form-text form-text-error"/>
                                    </div>
                                    <div className="col-sm-2">
                                        <Button
                                            type="submit"
                                            className="btn-primaryNew"
                                            disabled={isSubmitting}
                                            waiting={isSubmitting}
                                        >
                                            Search
                                        </Button>
                                    </div>
                                </div>


                            </Form>

                        )}
                    </Formik>

                </div>


                {isFetchingStockImages && (
                    <div className="fetching-images-container">
                        <LoadingSpinner/>
                    </div>
                )}

                {!isFetchingStockImages && queryResult != null && (
                    <div className="results-container">
                        <p>{queryResult.get('total')} search results provided by <a
                            href="https://unsplash.com" rel="noopener noreferrer" target="_blank">Unsplash</a>. Showing
                            page {queryResult.get('page')}.</p>
                    </div>
                )}

                {!isFetchingStockImages && queryResult != null && (
                    <div className="images-container">
                        <div className="images-container-inner">
                            {queryResult.get('results').map(result => (
                                <div
                                    key={result.get('id')}
                                    className="image-container"
                                >
                                    <div
                                        className="image-container-inner"
                                        style={{backgroundImage: `url(${result.getIn(['urls', 'thumb'])})`}}
                                    >
                                        <div className="btn-container">
                                            <a
                                                href={result.getIn(['urls', 'regular'])}
                                                rel="noopener noreferrer"
                                                target="_blank"
                                                className="btn btn-primary"
                                            >View</a>

                                            <Button
                                                className="btn-primary"
                                                onClick={()=>this.handlePhotoSelect(result)}
                                            >Use</Button>
                                        </div>
                                    </div>
                                    <div className="info-container">
                                        <p className="photographer">Photo by {result.getIn(['user', 'name'])}</p>
                                        <a
                                            href={result.getIn(['user', 'portfolio_url']) + '?utm_source=app9&utm_medium=referral'}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >Profile</a>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className="page-nav-container">
                            {queryResult.get('page') > 1 && (
                                <Button
                                    className="btn-primary"
                                    onClick={() => this.requestPage(queryResult.get('page') - 1)}
                                >Previous page</Button>
                            )}

                            {queryResult.get('page') < queryResult.get('total_pages') && (
                                <Button
                                    className="btn-primary"
                                    onClick={() => this.requestPage(queryResult.get('page') + 1)}
                                >Next page</Button>
                            )}
                        </div>

                    </div>
                )}

            </div>
        );
    }
}

export default connect()(StockPhotos);