import { combineReducers } from 'redux'
import userReducer from './userReducer';
import appReducer from './appReducer';
import projectReducer from './projectReducer';
import sizeReducer from './sizeReducer';
import editorReducer from './editorReducer';
import screenshotReducer from './screenshotReducer';
import imageReducer from './imageReducer';
import planReducer from './planReducer';
import invoiceReducer from './invoiceReducer';
import locationReducer from './locationReducer';

const rootReducer = () => combineReducers({
    userReducer,
    appReducer,
    projectReducer,
    sizeReducer,
    editorReducer,
    screenshotReducer,
    imageReducer,
    planReducer,
    invoiceReducer,
    locationReducer
});

export default rootReducer