import React, {Component} from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import './styles.scss';


class EditorControls extends Component {

    render() {
        const {zoomFactor} = this.props;

        return (
            <div className="editor-controls">

                <div className="zoom-factor-container">
                    <div className="zoom-factor-container-inner">
                        <label>Zoom:</label>
                        <Slider
                            value={zoomFactor * 100}
                            min={50}
                            max={300}
                            onChange={(v) => this.props.onZoomFactorChange(v / 100)}
                            trackStyle={{backgroundColor: '#7fccf7'}}
                            handleStyle={{
                                borderColor: '#449fed',
                                backgroundColor: '#449fed',
                            }}
                        />
                    </div>

                </div>
            </div>
        )

    }
}


export default EditorControls;
