import 'whatwg-fetch'
import auth0Client from "../Auth";
import {apiHost} from '../constants';

const { fromJS } = require('immutable');

export const FETCH_PLANS = 'FETCH_PLANS';
export const RECEIVE_PLANS = 'RECEIVE_PLANS';


//plans
export function requestPlans() {
    return {
        type: FETCH_PLANS
    }
}


export function receivePlans(plans) {
    return {
        type: RECEIVE_PLANS,
        plans
    }
}


function fetchPlans() {
    return dispatch => {
        dispatch(requestPlans());
        const url = apiHost + '/plans';
        return fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${auth0Client.getIdToken()}`
            }
        })
            .then(response => response.json())
            .then(json => {
                const plans = fromJS(json);
                dispatch(receivePlans(plans))
            })
            .catch(err => {
                console.error('fetchPlans error:', err);
            });
    }
}


function shouldFetchPlans(state) {
    const planReducer = state.planReducer;
    if (planReducer.isFetchingPlans || planReducer.plans != null) {
        return false;
    }
    return true;
}


export function fetchPlansIfNeeded() {
    return (dispatch, getState) => {
        if (shouldFetchPlans(getState())) {
            return dispatch(fetchPlans())
        } else {
            return Promise.resolve()
        }
    }
}