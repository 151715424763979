const { List } = require('immutable');


export function getMatchingScreenshot(params) {
    const project = params.project;
    const screenshots = params.screenshots;
    const size = params.size;
    const screen = params.screen;

    for (let i=0; i<screenshots.size; i++) {
        const screenshot = screenshots.get(i);
        if (screenshot.get('project_uuid') === project.get('uuid') && screenshot.get('screen_id') === screen.get('id') && screenshot.get('language') === project.get('last_language') && screenshot.get('size_uuid') === size.get('uuid')) {
            return screenshot;
        }
    }
}

export function getApproxScreenshot(params) {
    const project = params.project;
    const screenshots = params.screenshots;
    const size = params.size;
    const screen = params.screen;
    const languageCode = params.languageCode;

    // default language, exact match -> this language, approx. match -> default language, approx. match

    let filteredScreenshots = List();
    for (let i=0; i<screenshots.size; i++) {
        if (screenshots.getIn([i, 'project_uuid']) === project.get('uuid') && screenshots.getIn([i, 'screen_id']) === screen.get('id')) {
            filteredScreenshots = filteredScreenshots.push(screenshots.get(i));
        }
    }

    let screenshot;

    // 1 - default language, exact size match
    for (let i=0; i<filteredScreenshots.size; i++) {
        if (filteredScreenshots.getIn([i, 'language']) === project.get('screenshot_default_language') && filteredScreenshots.getIn([i, 'size_uuid']) === size.get('uuid')) {
            screenshot = filteredScreenshots.get(i);
        }
    }

    const sizeAspectRatio = size.get('portrait_width') / size.get('portrait_height');
    let aspectRatioDelta;

    if (screenshot == null) {
        // 2 - this language, approx. match
        aspectRatioDelta = 999;
        for (let i=0; i<filteredScreenshots.size; i++) {
            const _screenshot = filteredScreenshots.get(i);
            if (_screenshot.get('language') === languageCode) {
                const screenshotAspectRatio = Math.min(_screenshot.get('width'), _screenshot.get('height')) / Math.max(_screenshot.get('width'), _screenshot.get('height'));
                const newAspectRatioDelta = Math.abs(screenshotAspectRatio - sizeAspectRatio);
                if (newAspectRatioDelta < aspectRatioDelta) {
                    aspectRatioDelta = newAspectRatioDelta;
                    screenshot = _screenshot;
                }
            }
        }
    }

    if (screenshot == null) {
        // 3 - default language, approx. match
        aspectRatioDelta = 999;
        for (let i=0; i<filteredScreenshots.size; i++) {
            const _screenshot = filteredScreenshots.get(i);
            if (_screenshot.get('language') === project.get('screenshot_default_language')) {
                const screenshotAspectRatio = Math.min(_screenshot.get('width'), _screenshot.get('height')) / Math.max(_screenshot.get('width'), _screenshot.get('height'));
                const newAspectRatioDelta = Math.abs(screenshotAspectRatio - sizeAspectRatio);
                if (newAspectRatioDelta < aspectRatioDelta) {
                    aspectRatioDelta = newAspectRatioDelta;
                    screenshot = _screenshot;
                }
            }
        }
    }

    return screenshot;
};


