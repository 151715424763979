import React, {Component} from 'react';
import {connect} from 'react-redux'
import {withRouter} from "react-router-dom";
import {Formik, Form, Field, ErrorMessage} from 'formik';
import {addAppAction} from "../../actions/appActions";
import {setLastAppAction} from '../../actions/userActions';
import Card from "../../Components/Card/Card";
import Sidebar from "../../Components/Sidebar/Sidebar";
import * as Yup from 'yup';
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import Page from '../../Components/Page/Page';
import Button from '../../Components/Button/Button';
import {Container, Row, Col} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

const formSchema = Yup.object().shape({
    appName: Yup.string()
        .required('Please enter an app name')
});

class AppsNew extends Component {
    render() {
        const {currentUser, accountInfo} = this.props;

        if (currentUser != null) {
            return (
                <div className="main">
                    <Sidebar
                        page="apps"
                        accountInfo={accountInfo}
                    />

                    <Page
                        className="page-apps-index"
                        currentUser={currentUser}
                        accountInfo={accountInfo}
                    >

                        <Container fluid>
                            <Row>

                                <Col sm={12}>

                                    <h1>New App</h1>

                                    <Card>

                                        <Formik
                                            initialValues={{appName: ''}}
                                            validationSchema={formSchema}
                                            onSubmit={(values, {setSubmitting}) => {

                                                this.props.dispatch(addAppAction(values.appName))
                                                    .then(appUUID => {
                                                        this.props.dispatch(setLastAppAction(appUUID))
                                                            .then(() => {
                                                                setSubmitting(false);
                                                                this.props.history.push('/apps/' + appUUID);
                                                            })
                                                            .catch(err => {
                                                                console.error('error:', err);
                                                            });
                                                    })
                                                    .catch(err => {
                                                        console.error('create app error:', err);
                                                    });
                                            }}
                                        >
                                            {({isSubmitting}) => (
                                                <Form>
                                                    <div className="form-group row">
                                                        <label className="col-sm-3 col-form-label">App name</label>
                                                        <div className="col-sm-9">
                                                            <Field type="text" name="appName" className="form-control"
                                                                   placeholder="Please enter an app name..."/>
                                                            <ErrorMessage name="appName" component="small"
                                                                          className="form-text form-text-error"/>
                                                        </div>
                                                    </div>

                                                    <Button
                                                        type='submit'
                                                        className="btn-primaryNew"
                                                        disabled={isSubmitting}
                                                        loading={isSubmitting}
                                                    >
                                                        <FontAwesomeIcon icon={faPlus} />
                                                        Add app
                                                    </Button>

                                                </Form>

                                            )}
                                        </Formik>

                                    </Card>

                                </Col>
                            </Row>
                        </Container>
                    </Page>
                </div>
            )
        }
        else {
            return (
                <LoadingSpinner/>
            )
        }
    }
}


const mapStateToProps = (state) => {
    return {
        apps: state.appReducer.apps
    }
};


export default connect(mapStateToProps)(withRouter(AppsNew));
