import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import {withRouter} from "react-router-dom";
import {fetchApps} from "../../../actions/appActions";
import {setLastAppAction} from "../../../actions/userActions";
import {createProject} from "../../../actions/projectActions";
import LoadingSpinner from '../../../Components/LoadingSpinner/LoadingSpinner';
import Page from '../../../Components/Page/Page';
import Card from "../../../Components/Card/Card";
import { Container, Row, Col, Dropdown } from 'react-bootstrap';
import * as Yup from "yup";
import {ErrorMessage, Field, Form, Formik} from "formik";
import Button from '../../../Components/Button/Button';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";


const formSchema = Yup.object().shape({
    projectName: Yup.string()
        .required('Please enter a project name')
});


class SidebarNewProject extends Component {
    componentDidMount() {
        this.props.dispatch(fetchApps());
    };

    getAppForUUID = (uuid) => {
        const {apps} = this.props;
        for (let i = 0; i < apps.size; i++) {
            if (apps.getIn([i, 'uuid']) === uuid) {
                return apps.get(i);
            }
        }
    };

    getDefaultName = () => {
        const {currentUser} = this.props;

        const lastAppUUID = currentUser.get('last_app_uuid');
        const selectedApp = this.getAppForUUID(lastAppUUID);
        const projectsCount = selectedApp.get('projects').size;

        const defaultName = 'Project #' + (projectsCount + 1);
        return defaultName;
    };

    handleChangeApp = (appUUID) => {
        if (this.props.currentUser.get('last_app_uuid') !== appUUID) {
            this.props.dispatch(setLastAppAction(appUUID));
        }
    };

    render() {
        const {apps, currentUser, accountInfo, page} = this.props;

        if (apps != null && currentUser != null) {
            const lastAppUUID = currentUser.get('last_app_uuid');
            const selectedApp = this.getAppForUUID(lastAppUUID);

            return (
                <div className="main">
                    <Sidebar
                        page="campaignsNew"
                        subpage={page}
                        backlink={{url: '/apps', text: 'Apps'}}
                        accountInfo={accountInfo}
                    />
                    <Page
                        className="page-campaigns-new"
                        currentUser={currentUser}
                        accountInfo={accountInfo}

                    >
                        <Container fluid>
                            <Row>
                                <Col sm={12}>
                                    <h1>New Project</h1>

                                    <div className="filter-container">
                                        <p>Create a project for:</p>

                                        <Dropdown>
                                            <Dropdown.Toggle id="appDropdown">{selectedApp.get('name')}</Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                {apps.map(app => (
                                                    <Dropdown.Item
                                                        key={app.get('uuid')}
                                                        as="button"
                                                        onClick={() => this.handleChangeApp(app.get('uuid'))}
                                                    >
                                                        {app.get('name')}
                                                    </Dropdown.Item>
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>


                                </Col>
                            </Row>

                            <Row>
                                <Col sm={12}>
                                    <Card>

                                        <Formik
                                            initialValues={{projectName: this.getDefaultName()}}
                                            validationSchema={formSchema}
                                            onSubmit={(values, {setSubmitting}) => {

                                                this.props.dispatch(createProject(selectedApp.get('uuid'), values.projectName))
                                                    .then(projectUUID => {
                                                        console.log('project created. uuid:', projectUUID);
                                                        const url = '/editor/project/' + projectUUID;
                                                        setSubmitting(false);
                                                        this.props.history.push(url);
                                                    })
                                                    .catch(err => {
                                                        console.error('project not created. error:', err);
                                                    });
                                            }}
                                        >
                                            {({isSubmitting}) => (
                                                <Form>
                                                    <div className="form-group row">
                                                        <label className="col-sm-2 col-form-label">Name</label>
                                                        <div className="col-sm-10">
                                                            <Field type="text" name="projectName" className="form-control"
                                                                   placeholder="Please enter a project name"/>
                                                            <ErrorMessage name="projectName" component="small"
                                                                          className="form-text form-text-error"/>
                                                        </div>
                                                    </div>
                                                    <Button
                                                        type='submit'
                                                        className="btn-primaryNew"
                                                        disabled={isSubmitting}
                                                        loading={isSubmitting}
                                                    >
                                                        <FontAwesomeIcon icon={faPlus} />
                                                        Add Project
                                                    </Button>

                                                </Form>

                                            )}
                                        </Formik>

                                    </Card>
                                </Col>
                            </Row>

                        </Container>
                    </Page>
                </div>
            );
        } else {
            return (
                <LoadingSpinner/>
            )
        }
    }
}


const mapStateToProps = (state) => {
    return {
        apps: state.appReducer.apps
    }
};


export default withRouter(connect(mapStateToProps)(SidebarNewProject));