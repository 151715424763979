import React, {Component} from 'react';
import {gradients} from './gradients';
import './styles.scss';
const { fromJS } = require('immutable');




class GradientLibrary extends Component {

    getGradientStyle = (gradient) => {
        let val = 'linear-gradient(' + gradient.angle + 'deg';

        gradient.stops.forEach(stop => {
            val += ', ' + stop.color + ' ' + stop.offset * 100 + '%';
        });

        val += ')';

        return {background: val};
    };

    render() {
        return (
            <div className="gradient-library">
                {gradients.map((gradient, idx) => {
                    return (
                        <div
                            key={idx}
                            className="gradient"
                            style={this.getGradientStyle(gradient)}
                            onClick={() => this.props.onGradientChange(fromJS(gradient))}
                        />
                    )
                })}
            </div>
        );
    }
}


export default GradientLibrary;
