import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {loadStripe} from '@stripe/stripe-js';
import {Elements, ElementsConsumer} from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';
import Card from "../../../Components/Card/Card";
import {Col, Container, Row} from "react-bootstrap";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import connect from "react-redux/es/connect/connect";
import Page from '../../../Components/Page/Page';
import {fetchPlansIfNeeded} from "../../../actions/planActions";
import {fetchInvoices} from "../../../actions/invoiceActions";
import LoadingSpinner from "../../../Components/LoadingSpinner/LoadingSpinner";
import PlanCard from './PlanCard';
import PaymentHistoryCard from './PaymentHistoryCard';
import {apiHost, stripeApiKey} from "../../../constants";
import auth0Client from "../../../Auth";
import { Button } from 'react-bootstrap';
import {fetchCurrentUserIfNeeded, toggleAutoRenewalAction} from "../../../actions/userActions";

const stripePromise = loadStripe(stripeApiKey);


class SettingsBilling extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showStripeForm: false,
            isUpdatingPaymentInfo: false
        };
    }

    componentDidMount() {
        this.props.dispatch(fetchPlansIfNeeded());
        this.props.dispatch(fetchInvoices());
    }

    handleChangeCardClick = () => {
        this.setState({showStripeForm: true});
    };

    handleSubmitForm = (paymentMethodId, values) => {
        const data = {
            payment_method_id: paymentMethodId
        };

        this.setState({isUpdatingPaymentInfo: true});

        return fetch(apiHost + '/account/update-payment-details', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${auth0Client.getIdToken()}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((response) => response.json())
            .then(json => {
                    console.log('response:', json);

                    if (json.error == null) {
                        console.log('success');

                        this.props.dispatch(fetchCurrentUserIfNeeded(true))
                            .then(() => {
                                this.setState({
                                    showStripeForm: false,
                                    isUpdatingPaymentInfo: false
                                });
                            });
                    }
                    else {
                        console.log('error');

                        this.setState({
                            showStripeForm: false,
                            isUpdatingPaymentInfo: false
                        });

                        window.alert('Something went wrong. Could not update payment information.');
                    }
            })
            .catch(err => {
                console.error('handleSubmitForm error:', err);
                this.setState({isUpdatingPaymentInfo: false});

                window.alert('Something went wrong. Could not update payment information.');
            });
    };

    handleAutoRenewChange = () => {
        this.props.dispatch(toggleAutoRenewalAction());
    };

    render() {
        const {showStripeForm, isUpdatingPaymentInfo} = this.state;
        const {currentUser, accountInfo, plans, invoices} = this.props;

        if (currentUser != null && plans != null) {
            const planIsTrial = accountInfo.getIn(['plan', 'is_trial']);

            return (
                <div className="main">
                    <Sidebar
                        page="settings"
                        subpage="billing"
                        currentUser={currentUser}
                        accountInfo={accountInfo}
                    />

                    <Page
                        className="page-settings-billing"
                        currentUser={currentUser}
                        accountInfo={accountInfo}
                    >
                        <Container fluid={true}>
                            <Row>
                                <Col sm={12}>
                                    <h1>Settings - Billing</h1>

                                    <PlanCard
                                        accountInfo={accountInfo}
                                        onAutoRenewChange={this.handleAutoRenewChange}
                                    />

                                    {!planIsTrial && (
                                        <Card header="Payment details" className="card-payment-details">
                                            {showStripeForm &&

                                            <Elements stripe={stripePromise}>
                                                <ElementsConsumer>
                                                    {({stripe, elements}) => (
                                                        <CheckoutForm
                                                            stripe={stripe}
                                                            elements={elements}
                                                            isPurchasing={isUpdatingPaymentInfo}
                                                            onSubmitForm={this.handleSubmitForm}
                                                        />
                                                    )}
                                                </ElementsConsumer>
                                            </Elements>

                                            }

                                            {!showStripeForm &&
                                            <div>

                                                <div>
                                                    <p>{'Credit card (' + accountInfo.getIn(['payment_details', 'cc_brand']) + ' ***' + accountInfo.getIn(['payment_details', 'cc_last4']) + ')'}</p>

                                                    <Button
                                                        variant="primaryNew"
                                                        onClick={this.handleChangeCardClick}
                                                    >
                                                        Change credit card
                                                    </Button>
                                                </div>


                                            </div>
                                            }
                                        </Card>
                                    )}

                                    <PaymentHistoryCard
                                        invoices={invoices}
                                    />

                                </Col>
                            </Row>
                        </Container>
                    </Page>
                </div>
            );
        } else {
            return (
                <LoadingSpinner/>
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
        plans: state.planReducer.plans,
        invoices: state.invoiceReducer.invoices
    }
};

export default connect(mapStateToProps)(withRouter(SettingsBilling));