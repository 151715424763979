import React, {Component} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import rocket from "../../../images/campaigns/rocket.svg";
import './stylesThankYou.scss';


class ThankYou extends Component {
    render() {
        return (
            <Container className="thank-you-container">
                <Row>
                    <Col sm={12}>
                        <h1>Purchase Successful</h1>
                        <h2>Have fun making great screenshots!</h2>
                    </Col>
                </Row>

                <Row>
                    <Col sm={12}>
                        <div className="img-container">
                            <img src={rocket} alt="rocket"/>
                        </div>

                        <div className="action-container">
                            <button
                                className="btn btn-primaryNew"
                                onClick={this.props.onCloseModal}
                            >
                                Close
                            </button>
                        </div>

                    </Col>
                </Row>
            </Container>
        );
    }
}


export default ThankYou;