import React, {Component} from 'react';
import './checkbox_list.scss';


class CheckboxList extends Component {
    render() {
        const {options, values} = this.props;
        return (
            <div className="checkbox-list">
                {options.map(option => (
                    <label
                        key={option.value}
                        className="container"
                    >{option.title}
                        <input
                            type="checkbox"
                            checked={values.includes(option.value)}
                            onChange={() => this.props.onChange(option.value)}
                        />
                        <span className="checkmark"/>
                    </label>
                ))}
            </div>
        );
    }
}


export default CheckboxList;