import 'whatwg-fetch'
import React, {Component} from 'react';
import CheckboxList from '../../../Components/CheckboxList/CheckboxList';
import {getDefaultScreen} from '../editorUtils';
import {languages, getLanguageTitle} from '../../../languages';
import SelectControl from '../Components/SelectControl';
const { List } = require('immutable')


class Settings extends Component {
    getScreenOptions = (platform) => {
        const {sizes} = this.props;
        let options = [];

        sizes.forEach(size => {
            if (size.get('platform') === platform) {
                options.push({value: size.get('uuid'), title: size.get('title')})
            }
        });
        return options;
    };

    handleScreenOptionsChange = (value) => {
        const {sizes} = this.props;

        let screenSizes = this.props.project.get('sizes');
        if (screenSizes.includes(value)) {
            screenSizes = screenSizes.delete(screenSizes.indexOf(value));
        }
        else {
            screenSizes = screenSizes.push(value);

            //order
            let orderedScreenSizes = List();
            sizes.forEach(size => {
                screenSizes.forEach(screenSize => {
                    if (screenSize === size.get('uuid')) {
                        orderedScreenSizes = orderedScreenSizes.push(screenSize);
                    }
                });
            });
            screenSizes = orderedScreenSizes;
        }

        const newProject = this.props.project.set('sizes', screenSizes);
        this.props.onProjectChange(newProject);
    };

    getLanguageOptions = () => {
        let options = [];

        languages.forEach(language => {
            options.push({value: language.value, title: language.title})
        });
        return options;
    };

    handleLanguageOptionsChange = (value) => {
        const {project} = this.props;

        let projectLanguages = project.get('languages');
        if (projectLanguages.includes(value)) {
            if (projectLanguages.count() > 1) {
                projectLanguages = projectLanguages.delete(projectLanguages.indexOf(value));
            }
        }
        else {
            projectLanguages = projectLanguages.push(value).sort();
        }

        let newProject = this.props.project.set('languages', projectLanguages);

        // do we have to change last language?
        if (!projectLanguages.includes(project.get('last_language'))) {
            newProject = newProject.set('last_language', projectLanguages.get(0));
        }

        // do we have to change screenshot default language?
        if (!projectLanguages.includes(project.get('screenshot_default_language'))) {
            newProject = newProject.set('screenshot_default_language', projectLanguages.get(0));
        }

        this.props.onProjectChange(newProject);
    };

    getProjectLanguageOptions = () => {
        const {project} = this.props;
        let options = [];

        project.get('languages').forEach(languageCode => {
            options.push({value: languageCode, title: getLanguageTitle(languageCode)})
        });
        return options;
    };

    handleChangeProjectDefaultLanguage = (languageCode) => {
        const {project} = this.props;
        const newProject = project.set('screenshot_default_language', languageCode);
        this.props.onProjectChange(newProject);
    };

    handleAddScreen = () => {
        const {project} = this.props;
        const screens = project.getIn(['data', 'screens']).push(getDefaultScreen());
        const newProject = project.setIn(['data', 'screens'], screens);
        this.props.onProjectChange(newProject);
    };

    render() {
        const {project} = this.props;

        return (
            <div className="editor-sidebar sidebar-position">
                <div className="sidebar-header">
                    <p>Project Settings</p>
                </div>

                <div className="sidebar-section">
                    <p className="heading">iOS Screen Sizes</p>

                    <div className="sidebar-section-inner">

                        <div className="form-group">
                            <div>
                                <CheckboxList
                                    options={this.getScreenOptions('ios')}
                                    values={project.get('sizes')}
                                    onChange={this.handleScreenOptionsChange}
                                />
                            </div>
                        </div>

                    </div>
                </div>

                <div className="sidebar-section">
                    <p className="heading">Android Screen Sizes</p>

                    <div className="sidebar-section-inner">

                        <div className="form-group">
                            <div>
                                <CheckboxList
                                    options={this.getScreenOptions('android')}
                                    values={project.get('sizes')}
                                    onChange={this.handleScreenOptionsChange}
                                />
                            </div>
                        </div>

                    </div>
                </div>

                <div className="sidebar-section">
                    <p className="heading">Languages</p>

                    <div className="sidebar-section-inner">

                        <div className="form-group">
                            <div>
                                <CheckboxList
                                    options={this.getLanguageOptions()}
                                    values={project.get('languages')}
                                    onChange={this.handleLanguageOptionsChange}
                                />
                            </div>
                        </div>

                    </div>
                </div>

                <div className="sidebar-section">
                    <p className="heading">Primary Language</p>

                    <div className="sidebar-section-inner">
                        <p>If screenshots for a language are not available, we will use screenshots for this language instead.</p>

                        <SelectControl
                            value={project.get('screenshot_default_language')}
                            onChange={this.handleChangeProjectDefaultLanguage}
                        >
                            {this.getProjectLanguageOptions().map(language => {
                                return (
                                    <option key={language.value} value={language.value}>
                                        {language.title}
                                    </option>
                                )
                            })}
                        </SelectControl>

                    </div>
                </div>
                
            </div>
        );
    }
}

export default Settings;