import {
    FETCH_SIZES,
    RECEIVE_SIZES
} from '../actions/sizeActions';


function sizeReducer(
    state = {
        isFetchingSizes: false,
        sizes: null
    },
    action
) {
    switch (action.type) {
        case FETCH_SIZES:
            return Object.assign({}, state, {
                isFetchingSizes: true,
                sizes: null
            });

        case RECEIVE_SIZES:
            return Object.assign({}, state, {
                isFetchingSizes: false,
                sizes: action.sizes
            });

        default:
            return state
    }
}


export default sizeReducer;