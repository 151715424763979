import React, {Component} from 'react';
import {connect} from 'react-redux'
import Card from "../../../Components/Card/Card";
import Button from '../../../Components/Button/Button';
import {updatePasswordAction} from "../../../actions/userActions";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";


const formSchema = Yup.object({
    password: Yup.string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters.')
        .matches(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/, {
            message:'Password has to contain at least one lower case letter, one upper case letter, and one number..',
            excludeEmptyString: true
        }),
    passwordConfirmation: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Password confirmation is required')
});


class PasswordCard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showChangePasswordInfo: false
        };
    }

    handleBtnClick = (btnName) => {
        switch (btnName) {
            case 'changePassword':
                this.setState({showChangePasswordInfo: true});
                break;

            default:
                console.log('Unknown btnName.')
        }
    };

    render() {
        const {showChangePasswordInfo} = this.state;


        return (
            <Card header="Password" className="card-profile">
                {showChangePasswordInfo && (
                    <Formik
                        initialValues={{password: '', passwordConfirmation: ''}}
                        validationSchema={formSchema}
                        onSubmit={(values, {setSubmitting}) => {

                            this.props.dispatch(updatePasswordAction(values.password))
                                .then(() => {
                                    setSubmitting(false);
                                    this.setState({showChangePasswordInfo: false});
                                })
                                .catch(err => {
                                    console.error('change password error:', err);
                                });
                        }}
                    >
                        {({isSubmitting}) => (
                            <Form>
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label">Password</label>
                                    <div className="col-sm-9">
                                        <Field type="password" name="password" className="form-control"
                                               placeholder="Please enter a password"/>
                                        <ErrorMessage name="password" component="small"
                                                      className="form-text form-text-error"/>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label">Password confirmation</label>
                                    <div className="col-sm-9">
                                        <Field type="password" name="passwordConfirmation" className="form-control"
                                               placeholder="Please enter a password confirmation"/>
                                        <ErrorMessage name="passwordConfirmation" component="small"
                                                      className="form-text form-text-error"/>
                                    </div>
                                </div>

                                <Button
                                    type="submit"
                                    className="btn-primaryNew"
                                    waiting={isSubmitting}
                                >
                                    Submit
                                </Button>

                            </Form>

                        )}
                    </Formik>
                )}

                {!showChangePasswordInfo && (
                    <Button
                        className="btn-primaryNew"
                        onClick={() => this.handleBtnClick('changePassword')}
                    >
                        Change
                    </Button>
                )}
            </Card>
        );
    }
}

export default connect()(PasswordCard);