import React, {Component} from 'react';
import Card from "../../../Components/Card/Card";
import ToggleSwitch from '../../../Components/ToggleSwitch/ToggleSwitch';
import moment from 'moment';


class PlanCard extends Component {
    render() {
        const {accountInfo} = this.props;

        const planIsTrial = accountInfo.getIn(['plan', 'is_trial']);

        if (planIsTrial) {

            return (
                <Card header="Plan">
                    <p>You are currently on a trial plan.</p>
                </Card>
            );
        } else {
            const renewalDate = moment(accountInfo.get('current_period_end'));

            return (
                <Card header="Plan" className="card-plan">

                    {accountInfo.get('subscription_renews') &&
                    <p>You are currently on a paid plan plan. Your next renewal date
                        is: {renewalDate.format('MMMM Do YYYY')}</p>
                    }

                    {!accountInfo.get('subscription_renews') &&
                    <p>You are currently on a paid plan plan. Your subscription will terminate on: {renewalDate.format('MMMM Do YYYY')}</p>
                    }

                    <div className="auto-renew-container">
                        <p>Auto-renew:</p>
                        <ToggleSwitch
                            checked={accountInfo.get('subscription_renews')}
                            onChange={this.props.onAutoRenewChange}
                        />
                    </div>
                </Card>
            )
        }
    }
}


export default PlanCard;