import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter, Prompt} from 'react-router-dom';
import ToolbarLeft from './ToolbarLeft';
import ToolbarTop from './ToolbarTop';
import Sidebar from './Sidebar';
import {
    fetchProjects,
    updateProject,
    saveProject
} from '../../actions/projectActions';
import {fetchSizesIfNeeded} from "../../actions/sizeActions";
import {
    setActiveToolbarLeftItem,
    setActiveSidebar,
    setActiveScreen,
    setZoomFactor,
    fetchFrameImagesIfNeeded
} from '../../actions/editorActions';
import {fetchScreenshots} from "../../actions/screenshotActions";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import {getProject} from './editorUtils';
import fonts from './fonts';
import ProjectNotValid from './ProjectNotValid/ProjectNotValid';
import Screens from './Screens/Screens';
import ScreenHeaders from './Screens/ScreenHeaders/ScreenHeaders';
import EditorControls from './EditorControls/EditorControls';
import ModalWrapper from '../../Components/ImageLibrary/ModalWrapper';
import Purchase from '../../Pages/Purchase/Purchase';


class Editor extends Component {
    constructor(props) {
        super(props);

        this.state = {
            projectUUID: null,
            isSaving: false,
            projectHasUnsavedChanges: false,
            showPurchaseOption: false
        }
    }

    componentDidMount() {
        const projectUUID = this.props.match.params.projectUUID;
        this.setState({
            projectUUID: projectUUID
        });

        this.props.dispatch(fetchProjects()).then(() => {
            if (this.projectUUIDIsValid(projectUUID)) {
                this.props.dispatch(fetchScreenshots(projectUUID));
            }
        });
        this.props.dispatch(fetchSizesIfNeeded());
        this.props.dispatch(fetchFrameImagesIfNeeded());
        this.props.dispatch(setActiveToolbarLeftItem('settings'));
        this.props.dispatch(setActiveSidebar('settings'));
        this.props.dispatch(setActiveScreen(null));
        this.props.dispatch(setZoomFactor(1.0));

        fonts.loadFonts();

        this._mounted = true;
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    handleProjectChange = (newProject) => {
        this.props.dispatch(updateProject(newProject));

        this.setState({
            projectHasUnsavedChanges: true
        });
    };

    handleToolbarLeftClick = (item) => {
        this.props.dispatch(setActiveToolbarLeftItem(item));
        this.props.dispatch(setActiveSidebar(item));
        this.props.dispatch(setActiveScreen(null));
    };

    handleZoomFactorChange = (zoomFactor) => {
        this.props.dispatch(setZoomFactor(zoomFactor));
    };

    handleBtnClick = (btnName, params) => {
        switch (btnName) {
            case 'save':
                this.save();
                break;

            default:
                console.log('unknown btnName:', btnName);
        }
    };

    save = () => {
        this.setState({isSaving: true});
        const project = getProject(this.props.projects, this.props.match.params.projectUUID);
        this.props.dispatch(saveProject(project))
            .then(status => {
                console.log('done. status:', status);
                this.setState({
                    isSaving: false,
                    projectHasUnsavedChanges: false
                });
            })
            .catch(err => {
                console.error('save error:', err);
            });
    };

    projectUUIDIsValid = (projectUUID) => {
        const {projects} = this.props;
        for (let i=0; i<projects.size; i++) {
            if (projects.getIn([i, 'uuid']) === projectUUID) {
                return true;
            }
        }
        return false;
    };

    handleScreenClick = (screenId) => {
        this.props.dispatch(setActiveToolbarLeftItem(null));
        this.props.dispatch(setActiveSidebar('screenSettings'));
        this.props.dispatch(setActiveScreen(screenId))
    };

    handleScreenDelete = (project, screenIdx) => {
        const screens = project.getIn(['data', 'screens']).delete(screenIdx);
        const newProject = project.setIn(['data', 'screens'], screens);

        this.props.dispatch(setActiveToolbarLeftItem(null));
        this.props.dispatch(setActiveSidebar('settings'));
        this.props.dispatch(setActiveScreen('settings'));

        this.handleProjectChange(newProject);
    };

    showPurchaseModal = (show) => {
        this.setState({
            showPurchaseOption: show
        })
    };

    render() {
        const {projectHasUnsavedChanges, showPurchaseOption} = this.state;
        const {accountInfo, projects, sizes, screenshots, activeToolbarLeftItem, activeSidebar, activeScreen, zoomFactor, frameImages, renderingScreenItems} = this.props;

        if (this._mounted && projects != null) {
            const projectUUID = this.props.match.params.projectUUID;

            if (this.projectUUIDIsValid(projectUUID)) {
                if (sizes != null && screenshots != null && frameImages != null && fonts.fontsLoaded) {
                    const project = getProject(projects, projectUUID);
                    return (
                        <div className="editor">

                            <Prompt
                                when={projectHasUnsavedChanges}
                                message={() => 'You have unsaved changes. Are you sure you want to navigate away?'}
                            />

                            {showPurchaseOption &&
                            <ModalWrapper>
                                <Purchase
                                    onHide={() => this.showPurchaseModal(false)}
                                />
                            </ModalWrapper>
                            }

                            <ToolbarTop
                                project={project}
                                onClick={this.handleBtnClick}
                                onProjectChange={this.handleProjectChange}
                                onScreenClick={this.handleScreenClick}
                                isSaving={this.state.isSaving}
                                projectHasUnsavedChanges={projectHasUnsavedChanges}
                            />

                            <div className="main main-edit">
                                <ToolbarLeft
                                    onClick={this.handleToolbarLeftClick}
                                    activeItem={activeToolbarLeftItem}
                                />

                                <div className="sidebars-container">
                                    <Sidebar
                                        accountInfo={accountInfo}
                                        name={activeSidebar}
                                        project={project}
                                        sizes={sizes}
                                        screenshots={screenshots}
                                        activeScreen={activeScreen}
                                        frameImages={frameImages}
                                        onScreenDelete={(screenIdx) => this.handleScreenDelete(project, screenIdx)}
                                        onProjectChange={this.handleProjectChange}
                                        onBtnPurchaseClick={() => this.showPurchaseModal(true)}
                                    />
                                </div>


                                <div className="main-inner">

                                    <EditorControls
                                        zoomFactor={zoomFactor}
                                        onZoomFactorChange={this.handleZoomFactorChange}
                                    />

                                    <ScreenHeaders
                                        project={project}
                                        zoomFactor={zoomFactor}
                                        onProjectChange={this.handleProjectChange}
                                    />

                                    <Screens
                                        project={project}
                                        sizes={sizes}
                                        screenshots={screenshots}
                                        zoomFactor={zoomFactor}
                                        frameImages={frameImages}
                                        activeScreen={activeScreen}
                                        renderingScreenItems={renderingScreenItems}
                                        onScreenClick={this.handleScreenClick}
                                        onProjectChange={this.handleProjectChange}
                                    />
                                </div>
                            </div>

                        </div>
                    );
                }
                else {
                    return (
                        <LoadingSpinner/>
                    )
                }
            }
            else {
                return (
                    <ProjectNotValid/>
                )
            }
        } else {
            return (
                <LoadingSpinner/>
            )
        }
    }
}


const mapStateToProps = (state) => {
    return {
        currentUser: state.userReducer.currentUser,
        projects: state.projectReducer.projects,
        sizes: state.sizeReducer.sizes,
        screenshots: state.screenshotReducer.screenshots,
        activeToolbarLeftItem: state.editorReducer.activeToolbarLeftItem,
        activeSidebar: state.editorReducer.activeSidebar,
        activeScreen: state.editorReducer.activeScreen,
        zoomFactor: state.editorReducer.zoomFactor,
        frameImages: state.editorReducer.frameImages,
        renderingScreenItems: state.editorReducer.renderingScreenItems
    }
};


export default withRouter(connect(mapStateToProps)(Editor));
