export const imageHost = 'https://app9.s3.us-east-2.amazonaws.com/images/';
export const maxFileUploadSizeMB = 5;

export let apiHost;
export let stripeApiKey;

if (window.location.hostname === 'localhost') {
    apiHost = 'http://localhost:3001';
    stripeApiKey = 'pk_test_sv8KJFOGfNRX1OBawoFLqOci';

}
else {
    apiHost = 'https://api.screenshotbuilder.com';
    stripeApiKey = 'pk_live_uuj6PsRS32Y3q2QYt0cxjuLS';

}