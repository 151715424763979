import React, {Component} from 'react';
import {connect} from 'react-redux';
import {addRenderingScreenItem, removeRenderingScreenItem} from '../../../../actions/editorActions';
import {createCanvas} from './canvasUtils';
import {mergeScreen} from './mergeScreen';

const classNames = require('classnames');


class ScreenItem extends Component {
    constructor(props) {
        super(props);
        this.fgCanvasRef = React.createRef();
        this.lastParamsString = null;
    };

    componentDidMount() {
        this.renderCanvas();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        //if (this.props.project !== prevProps.project) {
            this.renderCanvas();
        //}
    }

    getFrameImageForName = (frameName) => {
        const {frameImages} = this.props;
        for (let i=0; i<frameImages.count(); i++) {
            if (frameImages.getIn([i, 'name']) === frameName) {
                return frameImages.get(i);
            }
        }
    };

    renderCanvas = () => {
        const {project, screen, screenItemId, size, frameImages, screenshot} = this.props;
        const canvas = this.fgCanvasRef.current;
        const mergedScreen = mergeScreen(project, screen);

        const params = {
            size: size,
            screen: mergedScreen,
            frameImages: frameImages,
            screenshot: screenshot
        };
        const paramsString = JSON.stringify(params);
        if (paramsString !== this.lastParamsString) {
            this.lastParamsString = paramsString;

            this.props.dispatch(addRenderingScreenItem(screenItemId));

            const t0 = performance.now();

            createCanvas(params).then((offscreenCanvas) => {
                if (mergedScreen.get('orientation') === 'portrait') {
                    canvas.width = size.get('portrait_width');
                    canvas.height = size.get('portrait_height');
                }
                else {
                    canvas.width = size.get('portrait_height');
                    canvas.height = size.get('portrait_width');
                }

                const ctx = canvas.getContext('2d');
                ctx.drawImage(offscreenCanvas, 0, 0);

                console.log('render time:', performance.now() - t0);

                this.props.dispatch(removeRenderingScreenItem(screenItemId));
            });
        }
    };

    render() {
        return (
            <div className={classNames('screen-item')}>
                <canvas ref={this.fgCanvasRef}/>
            </div>
        )
    };
}


export default connect()(ScreenItem);