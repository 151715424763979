import 'whatwg-fetch'
import auth0Client from "../Auth";
import {apiHost} from '../constants';

const { fromJS } = require('immutable');


export const FETCH_SIZES = 'FETCH_SIZES';
export const RECEIVE_SIZES = 'RECEIVE_SIZES';


export function requestSizes() {
    return {
        type: FETCH_SIZES
    }
}


export function receiveSizes(sizes) {
    return {
        type: RECEIVE_SIZES,
        sizes
    }
}


function fetchSizes() {
    return (dispatch, getState) => {
        const state = getState();

        if (!state.isFetchingSizes) {
            dispatch(requestSizes());
            return fetch(apiHost + '/sizes', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${auth0Client.getIdToken()}`
                }
            })
                .then(response => response.json())
                .then(json => {
                    dispatch(receiveSizes(fromJS(json)));
                })
                .catch(err => {
                    console.error('fetchSizes error:', err);
                });
        }
        else {
            return Promise.resolve()
        }
    }
}

function shouldFetchSizes(state) {
    const sizeReducer = state.sizeReducer;
    if (sizeReducer.isFetchingSizes || sizeReducer.sizes != null) {
        return false;
    }
    return true;
}


export function fetchSizesIfNeeded() {
    return (dispatch, getState) => {
        if (shouldFetchSizes(getState())) {
            return dispatch(fetchSizes())
        } else {
            return Promise.resolve()
        }
    }
}