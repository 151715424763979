import {
    SET_ACTIVE_TOOLBAR_LEFT_ITEM,
    SET_ACTIVE_SIDEBAR,
    SET_ACTIVE_SCREEN,
    SET_ZOOM_FACTOR,
    FETCH_FRAME_IMAGES,
    RECEIVE_FRAME_IMAGES,
    ADD_RENDERING_SCREEN_ITEM,
    REMOVE_RENDERING_SCREEN_ITEM
} from '../actions/editorActions';

const {List} = require('immutable');


function editorReducer(
    state = {
        activeToolbarLeftItem: null,
        activeSidebar: null,
        activeScreen: null,
        selectedLanguage: null,
        zoomFactor: 1.0,
        isFetchingFrameImages: false,
        frameImages: null,
        renderingScreenItems: List()
    },
    action
) {
    let renderingScreenItems;

    switch (action.type) {
        case SET_ACTIVE_TOOLBAR_LEFT_ITEM:
            return Object.assign({}, state, {
                activeToolbarLeftItem: action.item
            });

        case SET_ACTIVE_SIDEBAR:
            return Object.assign({}, state, {
                activeSidebar: action.sidebar
            });

        case SET_ACTIVE_SCREEN:
            return Object.assign({}, state, {
                activeScreen: action.screenId
            });

        case SET_ZOOM_FACTOR:
            return Object.assign({}, state, {
                zoomFactor: action.zoomFactor
            });

        case FETCH_FRAME_IMAGES:
            return Object.assign({}, state, {
                isFetchingFrameImages: true,
                frameImages: null
            });

        case RECEIVE_FRAME_IMAGES:
            return Object.assign({}, state, {
                isFetchingFrameImages: false,
                frameImages: action.frameImages
            });

        case ADD_RENDERING_SCREEN_ITEM:
            renderingScreenItems = state.renderingScreenItems.push(action.screenItemId);
            return Object.assign({}, state, {
                renderingScreenItems: renderingScreenItems
            });

        case REMOVE_RENDERING_SCREEN_ITEM:
            const idx = state.renderingScreenItems.indexOf(action.screenItemId);
            renderingScreenItems = state.renderingScreenItems.delete(idx);
            return Object.assign({}, state, {
                renderingScreenItems: renderingScreenItems
            });

        default:
            return state
    }
}


export default editorReducer;