import 'whatwg-fetch'
import auth0Client from "../Auth";
import {apiHost} from '../constants';

const { fromJS } = require('immutable');

export const FETCH_TEAM_INFO = 'FETCH_TEAM_INFO';
export const RECEIVE_TEAM_INFO = 'RECEIVE_TEAM_INFO';


export function requestTeamInfo() {
    return {
        type: FETCH_TEAM_INFO
    }
}


export function receiveTeamInfo(teamInfo) {
    return {
        type: RECEIVE_TEAM_INFO,
        teamInfo
    }
}


function fetchTeamInfo() {
    return dispatch => {
        dispatch(requestTeamInfo());
        return fetch(apiHost + '/account/team-info', {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${auth0Client.getIdToken()}`
            }
        })
            .then(response => response.json())
            .then(json => {
                const teamInfo = fromJS(json);
                dispatch(receiveTeamInfo(teamInfo));
                return Promise.resolve();
            })
            .catch(err => {
                console.error('fetchTeamInfo error:', err);
            });
    }
}


function shouldFetchTeamInfo(state) {
    const accountReducer = state.accountReducer;
    if (accountReducer.isFetchingTeamInfo) {
        return false;
    }
    return true;
}


export function fetchTeamInfoIfNeeded() {
    return (dispatch, getState) => {
        if (shouldFetchTeamInfo(getState())) {
            return dispatch(fetchTeamInfo())
        }
        else {
            return Promise.resolve()
        }
    }
}


//add team member
export function addTeamMemberAction(email) {
    return () => {
        const data = {
            email: email
        };
        return fetch(apiHost + '/account/add-team-member', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${auth0Client.getIdToken()}`,
                'Content-Type':'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(json => {
                return Promise.resolve(json);
            })
            .catch(err => {
                console.error('addTeamMemberAction error:', err);
            });
    }
}


//remove team member
export function removeTeamMemberAction(email) {
    return () => {
        const data = {
            email: email
        };
        return fetch(apiHost + '/account/remove-team-member', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${auth0Client.getIdToken()}`,
                'Content-Type':'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(() => {
                return Promise.resolve()
            })
            .catch(err => {
                console.error('removeTeamMemberAction error:', err);
            });
    }
}


//join member
export function joinTeamAction() {
    return () => {
        return fetch(apiHost + '/account/join-team', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${auth0Client.getIdToken()}`,
                'Content-Type':'application/json'
            }
        })
            .then(() => {
                return Promise.resolve()
            })
            .catch(err => {
                console.error('joinTeamAction error:', err);
            });
    }
}


//update account
export function updateAccountAction(accountInfo) {
    return () => {
        const data = {
            account_info: accountInfo
        };
        return fetch(apiHost + '/account/update-info', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${auth0Client.getIdToken()}`,
                'Content-Type':'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((response) => {
                console.log('account updated successfully. status:', response.status)
            })
            .catch(err => {
                console.error('updateAccountAction error:', err);
            });
    }
}