export const languages = [
    {value: 'ar', title: 'Arabic'},
    {value: 'en_AU', title: 'English (Australia)'},
    {value: 'en_CA', title: 'English (Canada'},
    {value: 'en_GB', title: 'English (U.K.)'},
    {value: 'en_US', title: 'English (U.S.)'},
    {value: 'es_ES', title: 'Spanish (Spain)'},
    {value: 'es_MX', title: 'Spanish (Mexico)'},
    {value: 'fi', title: 'Finnish'},
    {value: 'fr_CA', title: 'French (Canada)'},
    {value: 'fr_FR', title: 'French (France)'},
    {value: 'pt_BR', title: 'Portuguese (Brazil)'},
    {value: 'pr_PT', title: 'Portuguese (Portugal)'},
    {value: 'uk_UA', title: 'Ukrainian'},
    {value: 'zh_HANS', title: 'Chinese (Simplified)'},
    {value: 'zh_HANT', title: 'Chinese (Traditional'},
    {value: 'hr', title: 'Croatian'},
    {value: 'af', title: 'Afrikaans'},
    {value: 'cs', title: 'Czech'},
    {value: 'am', title: 'Amharic'},
    {value: 'bg', title: 'Bulgarian'},
    {value: 'da', title: 'Danish'},
    {value: 'de', title: 'German'},
    {value: 'ca', title: 'Catalan'},
    {value: 'el', title: 'Greek'},
    {value: 'et', title: 'Estonian'},
    {value: 'he', title: 'Hebrew'},
    {value: 'hi', title: 'Hindi'},
    {value: 'hu', title: 'Hungarian'},
    {value: 'id', title: 'Indonesian'},
    {value: 'it', title: 'Italian'},
    {value: 'is', title: 'Icelandic'},
    {value: 'fil', title: 'Filipino'},
    {value: 'ja', title: 'Japanese'},
    {value: 'ko', title: 'Korean'},
    {value: 'ms', title: 'Malay'},
    {value: 'nl', title: 'Dutch'},
    {value: 'no', title: 'Norwegian'},
    {value: 'lt', title: 'Lithuanian'},
    {value: 'lv', title: 'Latvian'},
    {value: 'pl', title: 'Polish'},
    {value: 'ro', title: 'Romanian'},
    {value: 'ru', title: 'Russian'},
    {value: 'sk', title: 'Slovak'},
    {value: 'sr', title: 'Serbian'},
    {value: 'sw', title: 'Swahili'},
    {value: 'sv', title: 'Swedish'},
    {value: 'sl', title: 'Slovenian'},
    {value: 'th', title: 'Thai'},
    {value: 'tl', title: 'Tagalog'},
    {value: 'tr', title: 'Turkish'},
    {value: 'vi', title: 'Vietnamese'},
    {value: 'zu', title: 'Zulu'},
];


export function getLanguageTitle(languageCode) {
    for (let i=0; i<languages.length; i++) {
        if (languages[i].value === languageCode) {
            return languages[i].title;
        }
    }
}