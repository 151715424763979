import 'whatwg-fetch'
import React, {Component} from 'react';
import SliderControl from '../../Components/SliderControl';
import SelectControl from '../../Components/SelectControl';
import ButtonGroup from "../../Components/ButtonGroup";
import Checkbox from "../../../../Components/Checkbox/Checkbox";
import ColorControl from "../../Components/ColorControl";
import GradientControl from "../../Components/GradientControl/GradientControl";
import GradientLibrary from '../../Components/GradientLibrary/GradientLibrary';
import fonts from '../../fonts';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExchangeAlt, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {Button} from "react-bootstrap";
import './screenSettingsStyles.scss';
import ImageLibrary from '../../../../Components/ImageLibrary/ImageLibrary';


class ScreenSettings extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showImageLibrary: false,
        };
    };

    setImageLibrary = (show) => {
        this.setState({
            showImageLibrary: show
        });
    };

    updateScreen = (path, value) => {
        const {project} = this.props;
        const screen = this.getScreen();
        const screenIdx = this.getScreenIdx();

        const newScreen = screen.setIn(path, value);
        const newProject = project.setIn(['data', 'screens', screenIdx], newScreen);
        this.props.onProjectChange(newProject);
    };

    handleFontStyleChange = (screen, btnName) => {
        const {project} = this.props;
        const screenIdx = this.getScreenIdx();

        let newScreen = screen;

        switch (btnName) {
            case 'italic':
                newScreen = newScreen.setIn(['headingStyles', 'fontStyle'], screen.getIn(['headingStyles', 'fontStyle']) === 'normal' ? 'italic': 'normal');
                break;

            case 'underline':
                newScreen = newScreen.setIn(['headingStyles', 'textDecoration'], screen.getIn(['headingStyles', 'textDecoration']) === 'none' ? 'underline': 'none');
                break;

            default:
                console.log('unknown error');

        }
        const newProject = project.setIn(['data', 'screens', screenIdx], newScreen);
        this.props.onProjectChange(newProject);
    };

    getScreenIdx = () => {
        const {project, activeScreen} = this.props;

        for (let i = 0; i < project.getIn(['data', 'screens']).size; i++) {
            if (project.getIn(['data', 'screens', i, 'id']) === activeScreen) {
                return i;
            }
        }
    };

    getScreen = () => {
        const {project} = this.props;
        return project.getIn(['data', 'screens', this.getScreenIdx()]);
    };

    handleImageSelected = (url) => {
        const {project} = this.props;
        const screenIdx = this.getScreenIdx();
        const newProject = project.setIn(['data', 'screens', screenIdx, 'backgroundColor', 'imageUrl'], url);
        this.props.onProjectChange(newProject);
        this.setState({
            showImageLibrary: false
        });
    };

    handleOverwriteChange = (overwrite) => {
        const {project} = this.props;
        const screenIdx = this.getScreenIdx();
        let screen = this.getScreen();

        if (screen.get('overwrite').includes(overwrite)) {
            screen = screen.set('overwrite', screen.get('overwrite').delete(screen.get('overwrite').indexOf(overwrite)));
            // nothing else to do
        }
        else {
            screen = screen.set('overwrite', screen.get('overwrite').push(overwrite));

            // copy from global values
            switch (overwrite) {
                case 'background':
                    screen = screen.set('backgroundColor', project.getIn(['data', 'global', 'backgroundColor']));
                    break;

                case 'frame':
                    screen = screen.set('frame', project.getIn(['data', 'global', 'frame']));
                    break;

                case 'font':
                    screen = screen.set('headingStyles', project.getIn(['data', 'global', 'headingStyles']));
                    break;

                default:
                    console.log('invalid overwrite:', overwrite);
            }
        }

        const newProject = project.setIn(['data', 'screens', screenIdx], screen);
        this.props.onProjectChange(newProject);
    };

    render() {
        const {showImageLibrary} = this.state;

        const screen = this.getScreen();
        
        const fontFamily = fonts.getFontFamilyValueforName(screen.getIn(['headingStyles', 'fontFamily']));
        const fontWeight = screen.getIn(['headingStyles', 'fontWeight']);
        const fontSize = screen.getIn(['headingStyles', 'fontSize']);
        const letterSpacing = screen.getIn(['headingStyles', 'letterSpacing']);
        const lineHeight = screen.getIn(['headingStyles', 'lineHeight']);
        const fontStyle = screen.getIn(['headingStyles', 'fontStyle']);
        const textDecoration = screen.getIn(['headingStyles', 'textDecoration']);
        const textAlign = screen.getIn(['headingStyles', 'textAlign']);
        const color = screen.getIn(['headingStyles', 'color']);

        return (
            <div className="editor-sidebar sidebar-screen-settings">

                <ImageLibrary
                    show={showImageLibrary}
                    onHide={() => this.setImageLibrary(false)}
                    onImageSelected={this.handleImageSelected}
                />

                <div className="sidebar-header">
                    <p>Screen Settings</p>
                </div>

                <div className="sidebar-section">
                    <p className="heading">Name</p>

                    <div className="sidebar-section-inner">
                        <input
                            className="form-control"
                            value={screen.get('name')}
                            onChange={(e)=>this.updateScreen(['name'], e.target.value)}
                        />

                        <div className="sidebar-subsection">
                            <p className="subsection-heading">Heading</p>

                            <textarea className="form-control" rows="3" value={screen.get('heading')} onChange={(e)=>this.updateScreen(['heading'], e.target.value)}/>

                            <div className="help-text-container">
                                <span className="material-icons">help</span>
                                <a
                                    href="https://app9.freshdesk.com/support/solutions/articles/63000209313-formatting-headings"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >Formatting text with tags</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="sidebar-section">
                    <p className="heading">Background</p>

                    <div className="sidebar-section-inner">

                        <Checkbox
                            title="Use global settings"
                            checked={!screen.get('overwrite').includes('background')}
                            onChange={() => this.handleOverwriteChange('background')}
                        />

                        {screen.get('overwrite').includes('background') &&
                        <div>
                            <SelectControl
                                value={screen.getIn(['backgroundColor', 'type'])}
                                onChange={(value) => this.updateScreen(['backgroundColor', 'type'], value)}
                            >
                                <option key="solid" value="solid">Solid color</option>
                                <option key="gradient" value="gradient">Gradient</option>
                                <option key="image" value="image">Image</option>
                            </SelectControl>

                            {screen.getIn(['backgroundColor', 'type']) === 'solid' &&

                            <ColorControl
                                name="Color"
                                color={screen.getIn(['backgroundColor', 'color'])}
                                disableAlpha={false}
                                onChange={(v) => this.updateScreen(['backgroundColor', 'color'], v)}
                            />

                            }

                            {screen.getIn(['backgroundColor', 'type']) === 'gradient' &&
                            <div>
                                <GradientControl
                                    gradient={screen.getIn(['backgroundColor', 'gradient'])}
                                    onGradientChange={(gradient) => this.updateScreen(['backgroundColor', 'gradient'], gradient)}
                                />

                                <GradientLibrary
                                    onGradientChange={(gradient) => this.updateScreen(['backgroundColor', 'gradient'], gradient)}
                                />
                            </div>
                            }

                            {screen.getIn(['backgroundColor', 'type']) === 'image' &&
                            <div className="image-container">

                                <div
                                    className="img-container"
                                    style={{backgroundImage: 'url(' + screen.getIn(['backgroundColor', 'imageUrl']) + ')'}}
                                />

                                <Button
                                    variant="secondary"
                                    onClick={() => this.setImageLibrary(true)}
                                >
                                    <FontAwesomeIcon icon={faExchangeAlt}/>
                                    Change
                                </Button>
                            </div>
                            }
                        </div>
                        }
                    </div>
                </div>

                <div className="sidebar-section">
                    <p className="heading">Frame</p>

                    <div className="sidebar-section-inner">

                        <Checkbox
                            title="Use global settings"
                            checked={!screen.get('overwrite').includes('frame')}
                            onChange={() => this.handleOverwriteChange('frame')}
                        />

                        {screen.get('overwrite').includes('frame') &&
                        <div>
                            <SelectControl value={screen.getIn(['frame', 'style'])} onChange={(v) => this.updateScreen(['frame', 'style'], v)}>
                                <option key="none" value="none">None</option>
                                <option key="flatWhite" value="flatWhite">Flat (white)</option>
                                <option key="flatBlack" value="flatBlack">Flat (black)</option>
                                <option key="realWhite" value="realWhite">Real (white)</option>
                                <option key="realBlack" value="realBlack">Real (black)</option>
                            </SelectControl>

                            <SelectControl
                                value={screen.get('orientation')}
                                onChange={(v) => this.updateScreen(['orientation'], v)}
                            >
                                <option key="portrait" value="portrait">Portrait</option>
                                <option key="landscapeLeft" value="landscapeLeft">Landscape (left)</option>
                                <option key="landscapeRight" value="landscapeRight">Landscape (right)</option>
                            </SelectControl>

                            <div className="sidebar-subsection">
                                <p className="subsection-heading">Shadow</p>

                                <SelectControl value={screen.getIn(['frame', 'shadow', 'type'])} onChange={(v) => this.updateScreen(['frame', 'shadow', 'type'], v)}>
                                    <option key="none" value="none">None</option>
                                    <option key="real" value="flatWhite">Real</option>
                                </SelectControl>

                                {screen.getIn(['frame', 'shadow', 'type']) === 'real' &&
                                <div>
                                    <SliderControl
                                        name="Offset (x)"
                                        value={screen.getIn(['frame', 'shadow', 'offsetX'])}
                                        min={-100}
                                        max={100}
                                        step={1}
                                        unit="px"
                                        onChange={(v) => this.updateScreen(['frame', 'shadow', 'offsetX'], v)}
                                    />

                                    <SliderControl
                                        name="Offset (y)"
                                        value={screen.getIn(['frame', 'shadow', 'offsetY'])}
                                        min={-100}
                                        max={100}
                                        step={1}
                                        unit="px"
                                        onChange={(v) => this.updateScreen(['frame', 'shadow', 'offsetY'], v)}
                                    />

                                    <SliderControl
                                        name="Blur"
                                        value={screen.getIn(['frame', 'shadow', 'blur'])}
                                        min={0}
                                        max={100}
                                        step={1}
                                        unit="px"
                                        onChange={(v) => this.updateScreen(['frame', 'shadow', 'blur'], v)}
                                    />

                                    <ColorControl
                                        name="Color"
                                        color={screen.getIn(['frame', 'shadow', 'color'])}
                                        disableAlpha={false}
                                        onChange={(v) => this.updateScreen(['frame', 'shadow', 'color'], v)}
                                    />
                                </div>
                                }
                            </div>
                        </div>
                        }
                    </div>
                </div>

                <div className="sidebar-section">
                    <p className="heading">Font</p>

                    <div className="sidebar-section-inner">

                        <Checkbox
                            title="Use global settings"
                            checked={!screen.get('overwrite').includes('font')}
                            onChange={() => this.handleOverwriteChange('font')}
                        />

                        {screen.get('overwrite').includes('font') &&
                        <div>
                            <SelectControl
                                value={fontFamily}
                                onChange={(value) => this.updateScreen(['headingStyles', 'fontFamily'], fonts.getFontFamilyNameForValue(value))}
                            >
                                {fonts.getFontFamilies().map(fontFamily => {
                                    return (
                                        <option key={fontFamily.value} value={fontFamily.value}>
                                            {fontFamily.name}
                                        </option>
                                    )
                                })}
                            </SelectControl>

                            <SelectControl value={fontWeight}
                                           onChange={(v) => this.updateScreen(['headingStyles', 'fontWeight'], v)}>
                                {fonts.getFontFamilyWeightsForValue(fontFamily).map(weight => {
                                    return (
                                        <option key={weight.value} value={weight.value}>
                                            {weight.name}
                                        </option>
                                    )
                                })}
                            </SelectControl>

                            <SliderControl
                                name="Font size"
                                value={fontSize}
                                min={0}
                                max={100}
                                step={1}
                                unit="px"
                                onChange={(v) => this.updateScreen(['headingStyles', 'fontSize'], v)}
                            />

                            <SliderControl
                                name="Letter spacing"
                                value={letterSpacing}
                                min={-100}
                                max={100}
                                step={1}
                                unit="px"
                                onChange={(v) => this.updateScreen(['headingStyles', 'letterSpacing'], v)}
                            />

                            <SliderControl
                                name="Line height"
                                value={lineHeight}
                                min={100}
                                max={300}
                                multiplier={100}
                                step={1}
                                unit="%"
                                onChange={(v) => this.updateScreen(['headingStyles', 'lineHeight'], v)}
                            />

                            <ButtonGroup
                                name="Text style and alignment"
                                buttons={[
                                    {
                                        name: 'italic',
                                        icon: 'format_italic',
                                        active: fontStyle === 'italic'
                                    },
                                    {
                                        name: 'underline',
                                        icon: 'format_underlined',
                                        active: textDecoration.includes('underline')
                                    },
                                ]}
                                onClick={(buttonName) => this.handleFontStyleChange(screen, buttonName)}
                            />

                            <ButtonGroup
                                buttons={[
                                    {
                                        name: 'left',
                                        icon: 'format_align_left',
                                        active: textAlign === 'left'
                                    },
                                    {
                                        name: 'center',
                                        icon: 'format_align_center',
                                        active: textAlign === 'center'
                                    },
                                    {
                                        name: 'right',
                                        icon: 'format_align_right',
                                        active: textAlign === 'right'
                                    }
                                ]}
                                onClick={(buttonName) => this.updateScreen(['headingStyles', 'textAlign'], buttonName)}
                            />

                            <ColorControl
                                name="Color"
                                color={color}
                                disableAlpha={false}
                                onChange={(v) => this.updateScreen(['headingStyles', 'color'], v)}
                            />
                        </div>
                        }
                    </div>
                </div>

                <div className="sidebar-section">
                    <p className="heading">Other Controls</p>

                    <div className="sidebar-section-inner">
                        <div className="btn-delete-container">
                            <Button
                                variant="danger"
                                onClick={() => this.props.onScreenDelete(this.getScreenIdx())}
                            >
                                <FontAwesomeIcon icon={faTrashAlt}/>
                                Delete Screen
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ScreenSettings;