import React, {Component} from 'react';
import reactCSS from 'reactcss'
import {SketchPicker} from 'react-color'


class ColorControl extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayColorPicker: false
        };

    }

    handleChangeComplete = (color) => {
        const {r,g,b,a} = color.rgb;
        this.props.onChange('rgba(' + r + ',' + g + ',' + b + ',' + a + ')');
    };

    handleSwatchClick = () => {
        this.setState({displayColorPicker: !this.state.displayColorPicker})
    };

    render() {
        const {color, disableAlpha, name} = this.props;

        const styles = reactCSS({
            'default': {
                color: {
                    width: '36px',
                    height: '14px',
                    borderRadius: '2px',
                    background: color,
                },
                swatch: {
                    padding: '5px',
                    background: '#fff',
                    borderRadius: '1px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                    display: 'inline-block',
                    cursor: 'pointer',
                },
                popover: {
                    position: 'absolute',
                    zIndex: '2',
                },
                cover: {
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                },
            },
        });

        return (
            <div className="color-control">
                {name && (<label>{name}</label>)}

                <div style={styles.swatch} onClick={this.handleSwatchClick}>
                    <div style={styles.color}/>
                </div>
                {this.state.displayColorPicker ?
                    <SketchPicker
                        color={color}
                        disableAlpha={disableAlpha}
                        onChangeComplete={this.handleChangeComplete}
                    />
                    : null}
            </div>
        );
    }
}


export default ColorControl;
