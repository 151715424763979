import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Route, Switch, withRouter} from 'react-router-dom';
import SecuredRoute from './SecuredRoute/SecuredRoute';
import auth0Client from './Auth';
import Callback from './Callback';
import NotFound from './Pages/NotFound/NotFound';
import Apps from './Pages/Apps/Apps';
import AppsNew from './Pages/Apps/AppsNew';
import AppOverview from './Pages/AppOverview/AppOverview';
import ProjectsNew from './Pages/Projects/New/ProjectsNew';
import Editor from './Pages/Editor/Editor';
import Header from './Header/Header';
import SettingsGeneral from './Pages/Settings/General/SettingsGeneral';
import SettingsBilling from './Pages/Settings/Billing/SettingsBilling';
import SettingsAdvanced from './Pages/Settings/Advanced/SettingsAdvanced';
import './stylesheets/app.scss';
import {fetchCurrentUserIfNeeded} from './actions/userActions';
import LoadingSpinner from './Components/LoadingSpinner/LoadingSpinner';
import ReactGA from 'react-ga';


class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checkingSession: true
        };
        //ReactGA.initialize('UA-171053121-1');

        /*window.Intercom("boot", {
            app_id: "zk67tgk8"
        });*/
    }

    async componentDidMount() {
        if (this.props.pathname === '/callback') {
            this.setState({checkingSession: false});
            return;
        }
        try {
            await auth0Client.silentAuth();
            this.forceUpdate();
            this.props.dispatch(fetchCurrentUserIfNeeded())
                .then(()=> {
                    const user = this.props.currentUser;

                    if (window.location.hostname !== 'localhost') {
                        ReactGA.set({userId: user.get('uuid')});
                    }

                    let intercomParams = {
                        email: user.get('email'),
                        created_at: user.get('created_at')
                    };

                    if (user.get('full_name').length > 0) {
                        intercomParams.name = user.get('full_name');
                    }

                    //window.Intercom('update', intercomParams);
                });

        } catch (err) {
            console.log('auth error1', err, err.error);
            if (err.error !== 'login_required') console.log('auth error', err, err.error);
            else auth0Client.signIn();
        }
        this.setState({checkingSession: false});

        if (window.location.hostname !== 'localhost') {
            //ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    render() {
        if (!auth0Client.isAuthenticated()) {
            return (
                <div>
                    <Route exact path='/callback' component={Callback}/>
                </div>
            );
        } else {
            const {currentUser} = this.props;
            if (currentUser != null) {

                const accountInfo = currentUser.get('account');

                return (
                    <div className="app">
                        <Header
                            currentUser={currentUser}
                            accountInfo={accountInfo}
                        />

                        <Switch>
                            <Route exact path='/callback' component={Callback}/>
                            <SecuredRoute
                                exact path='/'
                                component={Apps}
                                currentUser={currentUser}
                                accountInfo={accountInfo}
                            />
                            <SecuredRoute
                                exact path='/apps'
                                component={Apps}
                                currentUser={currentUser}
                                accountInfo={accountInfo}
                            />
                            <SecuredRoute
                                exact path='/apps/new'
                                component={AppsNew}
                                currentUser={currentUser}
                                accountInfo={accountInfo}
                            />
                            <SecuredRoute
                                exact path='/apps/:appUUID'
                                component={AppOverview}
                                currentUser={currentUser}
                                accountInfo={accountInfo}
                            />
                            <SecuredRoute
                                exact path='/projects/new'
                                component={ProjectsNew}
                                page="gallery"
                                currentUser={currentUser}
                                accountInfo={accountInfo}
                            />
                            <SecuredRoute
                                exact path='/editor/project/:projectUUID'
                                component={Editor}
                                currentUser={currentUser}
                                accountInfo={accountInfo}
                            />
                            <SecuredRoute
                                exact path='/settings'
                                component={SettingsGeneral}
                                currentUser={currentUser}
                                accountInfo={accountInfo}
                            />

                            <SecuredRoute
                                exact path='/settings/billing'
                                component={SettingsBilling}
                                currentUser={currentUser}
                                accountInfo={accountInfo}
                            />
                            <SecuredRoute
                                exact path='/settings/advanced'
                                component={SettingsAdvanced}
                                currentUser={currentUser}
                                accountInfo={accountInfo}
                            />
                            <Route component={NotFound}/>
                        </Switch>
                    </div>

                );
            } else {
                return (
                    <div className="app">
                        <LoadingSpinner/>
                    </div>
                )
            }
        }

    }
}


const mapStateToProps = (state) => {
    return {
        currentUser: state.userReducer.currentUser
    }
};

export default withRouter(connect(mapStateToProps)(App));
