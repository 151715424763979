import React, {Component} from 'react';
const classNames = require('classnames');


class ButtonGroup extends Component {

    buttonIconStyles = (button) => {
        let styles = {};

        if (button.rotate != null) {
            styles.transform = 'rotate(' + button.rotate + ')';
        }

        return styles;
    }

    render() {
        const {name} = this.props;

        return (
            <div className="control-btn-group">

                {name && (<label>{name}</label>)}

                <div className="btn-toolbar">
                    <div className="btn-group">
                        {this.props.buttons.map((button) => {
                            return (
                                <button
                                    key={button.name}
                                    type="button"
                                    className={classNames('btn', button.active ? 'active' : '', button.className)}
                                    onClick={()=>this.props.onClick(button.name)}
                                >
                                    <i
                                        className="material-icons"
                                        style={this.buttonIconStyles(button)}
                                    >{button.icon}</i>
                                </button>
                            )
                        })}
                    </div>
                </div>
            </div>
        );
    }
}


export default ButtonGroup;
