import React, {Component} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSpinner} from '@fortawesome/free-solid-svg-icons'
import './button.scss';

const classNames = require('classnames');


class Button extends Component {

    getButtonClassName = () => {
        if (this.props.primary) {
            if (this.props.outline) {
                return 'btn-primary-outline'
            }
            return 'btn-primary';
        }
        else if (this.props.secondary) {
            if (this.props.outline) {
                return 'btn-secondary-outline'
            }
            return 'btn-secondary';
        }

    };

    render() {

        return (
            <button
                className={classNames('btn', this.getButtonClassName(), this.props.className)}
                disabled={this.props.waiting || this.props.disabled}
                onClick={this.props.onClick}
                type={this.props.type}
            >
                {this.props.children}

                {(this.props.loading || this.props.waiting) &&
                <FontAwesomeIcon icon={faSpinner} spin/>
                }
            </button>
        );
    }
}


export default Button;