export function mergeScreen(project, screen) {
    let newScreen = screen;

    if (!screen.get('overwrite').includes('background')) {
        newScreen = newScreen.set('backgroundColor', project.getIn(['data', 'global', 'backgroundColor']));
    }

    if (!screen.get('overwrite').includes('frame')) {
        newScreen = newScreen.set('frame', project.getIn(['data', 'global', 'frame']));
        newScreen = newScreen.set('orientation', project.getIn(['data', 'global', 'orientation']));
        newScreen = newScreen.set('styles', project.getIn(['data', 'global', 'styles']));
    }

    if (!screen.get('overwrite').includes('font')) {
        newScreen = newScreen.set('headingStyles', project.getIn(['data', 'global', 'headingStyles']));
    }

    return newScreen
}