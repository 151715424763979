import React, {Component} from 'react';
import {connect} from 'react-redux'
import Sidebar from './Sidebar';
import Library from './Library';
import StockPhotos from './StockPhotos';
import UploadImage from './UploadImage';
import './image_library.scss';
import {fetchImages} from '../../actions/imageActions';
import ModalWrapper from './ModalWrapper';


class ImageLibrary extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 'stock'
        }
    };

    componentDidMount() {
        this.props.dispatch(fetchImages());
    }

    handlePageChange = (page) => {
        this.setState({
            page: page
        });
    };

    handleUploadFinished = () => {
        this.props.dispatch(fetchImages());
        this.handlePageChange('library');
    };

    render() {
        const {page} = this.state;
        const {show, isFetchingImages, images, isFetchingStockImages, queryResult} = this.props;

        if (show) {
            return (
                <ModalWrapper>
                    <div className="image-library">

                        <div
                            className="close-btn-container"
                            onClick={this.props.onHide}
                        >
                            <i className="material-icons">close</i>
                        </div>

                        <Sidebar
                            page={page}
                            onPageChange={this.handlePageChange}
                        />

                        {page === 'library' && (
                            <Library
                                isFetchingImages={isFetchingImages}
                                images={images}
                                onImageSelected={(image) => this.props.onImageSelected(image.get('url'))}
                            />
                        )}

                        {page === 'stock' && (
                            <StockPhotos
                                isFetchingStockImages={isFetchingStockImages}
                                queryResult={queryResult}
                                onPhotoSelected={(photo) => this.props.onImageSelected(photo.getIn(['urls', 'regular']))}
                            />
                        )}

                        {page === 'uploadImage' && (
                            <UploadImage
                                onUploadFinished={this.handleUploadFinished}
                            />
                        )}

                    </div>
                </ModalWrapper>
            );
        } else {
            return (
                <div/>
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
        isFetchingImages: state.imageReducer.isFetchingImages,
        images: state.imageReducer.images,
        isFetchingStockImages: state.imageReducer.isFetchingStockImages,
        queryResult: state.imageReducer.queryResult
    }
};

export default connect(mapStateToProps)(ImageLibrary);
