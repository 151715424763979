import React, {Component} from 'react';
import './image_library.scss';


class Sidebar extends Component {
    render() {
        const {page} = this.props;

        return (
            <div className="sidebar">
                <ul>
                    <li
                        className={page === 'stock' ? 'active' : ''}
                        onClick={() => this.props.onPageChange('stock')}
                    >Stock photos
                    </li>

                    <li
                        className={page === 'library' ? 'active' : ''}
                        onClick={() => this.props.onPageChange('library')}
                    >User Library
                    </li>

                    <li
                        className={page === 'uploadImage' ? 'active' : ''}
                        onClick={() => this.props.onPageChange('uploadImage')}
                    >Upload image
                    </li>
                </ul>
            </div>


        );
    }
}

export default Sidebar;
