import React, {Component} from 'react';


class AccountInfo extends Component {
    render() {
        const {accountInfo} = this.props;
        const accountName = accountInfo.get('name') || '';

        return (
            <div className="account-info">
                <form>
                    <div className="form-group row">
                        <label className="col-sm-3 col-form-label">Account name</label>
                        <div className="col-sm-9">
                            <input
                                type="text"
                                className="form-control"
                                value={accountName}
                                disabled={true}
                            />
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export default AccountInfo;