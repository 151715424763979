import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import { NavDropdown } from 'react-bootstrap';
import logo from '../images/global/logo1.png';
import auth0Client from '../Auth';


class Header extends Component {
    getAvatar = () => {
        const {currentUser} = this.props;

        return (
            <span className="profile-container2">
            <img src={currentUser.get('picture')} className="ui avatar image" alt="profile_image"/>
            Account
        </span>
        )
    };

    getAccountHeaderText = () => {
        const {currentUser, accountInfo} = this.props;
        let roleName = '';
        switch (currentUser.get('team_role')) {
            case 'member':
                roleName = 'Team member';
                break;

            case 'admin':
                roleName = 'Administrator';
                break;

            default:
                roleName = 'Unknown';
        }
        return accountInfo.get('name') + ' (' + roleName + ')';
    };

    handleDropdownMenuClick = (btnName) => {
        switch (btnName) {
            case 'settings':
                this.props.history.push('/settings');
                break;

            case 'logout':
                auth0Client.signOut();
                break;

            default:
                console.log('Invalid btnName', btnName);
        }
    };

    render() {
        const {currentUser, accountInfo} = this.props;

        if (currentUser != null && accountInfo != null) {
            return (
                <div id="header">


                    <div className="left">
                        <ul>
                            <li><Link to="/apps" className='nav-link'>My Apps</Link></li>
                        </ul>
                    </div>

                    <div className="logo-container">
                        <Link to='/apps'>
                            <img src={logo} className='logo' alt="logo" />
                        </Link>
                    </div>

                    <div className="right">
                        <ul>
                            <li><a
                                href="https://app9.freshdesk.com/support/home"
                                className="nav-link"
                                target="_blank"
                                rel="noopener noreferrer"
                            >Help</a></li>

                            <li>

                                <NavDropdown title="Account">


                                    <NavDropdown.Item
                                        as="button"
                                        onClick={() => this.handleDropdownMenuClick('settings')}
                                    >
                                        <span className="material-icons">settings</span>
                                        Settings
                                    </NavDropdown.Item>

                                    <NavDropdown.Item
                                        href="https://app9.freshdesk.com/support/home"
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        <span className="material-icons">help</span>
                                        Help
                                    </NavDropdown.Item>


                                    <NavDropdown.Divider/>

                                    <NavDropdown.Item
                                        as="button"
                                        onClick={() => this.handleDropdownMenuClick('logout')}
                                    >
                                        <span className="material-icons">exit_to_app</span>
                                        Log out
                                    </NavDropdown.Item>


                                </NavDropdown>


                            </li>
                        </ul>
                    </div>


                </div>
            );
        } else {
            return (
                <div id="header">


                </div>
            )
        }
    }
}

export default withRouter(Header);
