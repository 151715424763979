import React, {Component} from 'react';
import {connect} from 'react-redux'
import {withRouter} from "react-router-dom";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import {fetchPlansIfNeeded} from "../../actions/planActions";
import {fetchLocationDataIfNeeded, setCountryCode} from "../../actions/locationActions";
import PaymentRow from './PaymentRow';

import {loadStripe} from '@stripe/stripe-js';
import {Elements, ElementsConsumer} from '@stripe/react-stripe-js';

import auth0Client from "../../Auth";
import {apiHost, stripeApiKey} from '../../constants';
import './purchaseModal.scss';
import ThankYou from "./ThankYou/ThankYou";
import {fetchCurrentUserIfNeeded} from "../../actions/userActions";
import ReactGA from 'react-ga';

const stripePromise = loadStripe(stripeApiKey);


class Purchase extends Component {
    constructor(props) {
        super(props);

        this.state = {
            frequency: 'yearly',
            isPurchasing: false,
            purchaseComplete: false
        }
    }

    componentDidMount() {
        this.props.dispatch(fetchPlansIfNeeded());
        this.props.dispatch(fetchLocationDataIfNeeded());

        ReactGA.modalview('/purchase');
    }

    handleChangeFrequency = () => {
        this.setState({
            frequency: this.state.frequency === 'yearly' ? 'monthly' : 'yearly'
        });
    };

    handleSubmitForm = (paymentMethodId, values) => {
        const {locationData} = this.props;
        const data = {
            frequency: this.state.frequency,
            payment_method_id: paymentMethodId,
            name: values.name,
            country_code: locationData.get('country_code')
        };

        this.setState({isPurchasing: true});

        ReactGA.event({
            category: 'Purchase',
            action: 'Submit purchase'
        });

        return fetch(apiHost + '/account/purchase-plan', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${auth0Client.getIdToken()}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((response) => response.json())
            .then(json => {
                console.log('response:', json);

                if (json.error == null) {
                    console.log('success');

                    ReactGA.event({
                        category: 'Purchase',
                        action: 'Purchase successful'
                    });

                    window.Intercom('trackEvent', 'purchase');

                    this.props.dispatch(fetchCurrentUserIfNeeded(true))
                        .then(() => {
                            this.setState({
                                purchaseComplete: true,
                                isPurchasing: false
                            })
                        })
                }
                else {
                    console.log('error');

                    this.setState({isPurchasing: false});

                    window.alert('Something went wrong. Could not complete Purchase.');
                }
            })
            .catch(err => {
                console.error('handleSubmitForm error:', err);

                this.setState({isPurchasing: false});
            });
    };

    handleCountryCodeChange = (countryCode) => {
        this.props.dispatch(setCountryCode(countryCode));
    };

    render() {
        const {plans, locationData} = this.props;
        const {frequency, isPurchasing, purchaseComplete} = this.state;

        if (plans != null && locationData != null && frequency != null) {
            const plan = plans.get(0);

            return (
                <div
                    className="purchase-modal"
                    onClick={this.props.onHide}
                >
                    <div className="purchase-modal-outer">
                        <div
                            className="close-btn-container"
                            onClick={this.props.onHide}
                        >
                            <i className="material-icons">close</i>
                        </div>

                        <div
                            className="purchase-modal-inner"
                            onClick={(e) => {
                                e.stopPropagation()
                            }}
                        >
                            {!purchaseComplete &&
                            <Container>
                                <Row>
                                    <Col sm={12}>
                                        <h1>App9 Plan</h1>
                                        <h2>Transparent Pricing, No Hidden Fees!</h2>

                                        <Elements stripe={stripePromise}>
                                            <ElementsConsumer>
                                                {({stripe, elements}) => (
                                                    <PaymentRow
                                                        stripe={stripe}
                                                        elements={elements}
                                                        locationData={locationData}
                                                        plan={plan}
                                                        frequency={frequency}
                                                        isPurchasing={isPurchasing}
                                                        onSubmitForm={this.handleSubmitForm}
                                                        onChangeFrequency={this.handleChangeFrequency}
                                                        onCountryCodeChange={this.handleCountryCodeChange}
                                                    />
                                                )}
                                            </ElementsConsumer>

                                        </Elements>

                                    </Col>
                                </Row>
                            </Container>
                            }

                            {purchaseComplete &&
                            <ThankYou
                                onCloseModal={this.props.onHide}
                            />
                            }
                        </div>
                    </div>
                </div>
            );

        } else {
            return (
                <div className="purchase-modal">
                    <LoadingSpinner/>
                </div>
            )
        }
    }
}


const mapStateToProps = (state) => {
    return {
        plans: state.planReducer.plans,
        locationData: state.locationReducer.locationData
    }
};


export default connect(mapStateToProps)(withRouter(Purchase));