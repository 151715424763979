import React, {Component} from 'react';
import {Link} from "react-router-dom";
import website from '../../images/global/website.svg';
import Page from '../../Components/Page/Page';
import { Button, Container, Row, Col } from 'react-bootstrap';
import './stylesNoAppsPage.scss';
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class NoAppsPage extends Component {

    render() {
        const {currentUser, accountInfo} = this.props;

        return (
            <Page
                className="page-no-apps"
                currentUser={currentUser}
                accountInfo={accountInfo}
            >

                <Container fluid>
                    <Row>
                        <Col sm={12}>

                            <Row>
                                <Col sm={6} className="no-websites-col">
                                    <div className="no-websites-container">
                                        <h1>Add your first App!</h1>
                                        <p>Add the first app which you would like to create screenshots for.<br/> Follow a few simple
                                            steps to get started.</p>

                                        <div className="btn-container">
                                            <Button
                                                as={Link}
                                                variant="primaryNew"
                                                to="/apps/new"
                                                icon="add"
                                            >
                                                <FontAwesomeIcon icon={faPlus} />
                                                Add an app
                                            </Button>
                                        </div>
                                    </div>
                                </Col>

                                <Col sm={6}>
                                    <img src={website} alt="rocket"/>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Page>
        )
    }
}


export default NoAppsPage;
