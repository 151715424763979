import React, {Component} from 'react';
import './checkbox.scss';


class Checkbox extends Component {
    render() {
        const {title, checked} = this.props;
        return (
            <div className="checkbox">
                <label className="container">{title}
                    <input
                        type="checkbox"
                        checked={checked}
                        onChange={this.props.onChange}
                    />
                    <span className="checkmark"/>
                </label>
            </div>
        );
    }
}


export default Checkbox;