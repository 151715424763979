import React, {Component} from 'react';
import {sortableContainer, sortableElement} from 'react-sortable-hoc';
import ScreenHeader from "./ScreenHeader";
import './screenHeadersStyles.scss';

const defaultWidth = 250;


const SortableItem = sortableElement(({screen}) =>
    <ScreenHeader
        screen={screen}
    />
);

function getContainerStyle (project, zoomFactor) {
    const columnWidth = Math.floor(defaultWidth * zoomFactor) + 'px';
    let columns = [];
    for (let i=0; i<project.getIn(['data', 'screens']).size; i++) {
        columns.push(columnWidth);
    }
    return {gridTemplateColumns: columns.join(' ')};
};

const SortableContainer = sortableContainer(({project, zoomFactor}) => {
    return (
        <div className="screen-headers-container" style={getContainerStyle(project, zoomFactor)}>
            {project.getIn(['data', 'screens']).map((screen, idx) => (
                <SortableItem
                    key={screen.get('id')}
                    index={idx}
                    screen={screen}
                />
            ))}
        </div>
    );
});

const moveItem = (value, oldI, newI) => {
    if (!value || 0 > newI || value.size < newI) return value;

    const srcItem = value.get(oldI);
    return value.splice(oldI, 1).splice(newI, 0, srcItem);
};

class ScreenHeaders extends Component {

    onSortEnd = ({oldIndex, newIndex}) => {
        const {project} = this.props;
        console.log('oldIndex', oldIndex, 'newIndex', newIndex);

        let screens = project.getIn(['data', 'screens']);
        screens = moveItem(screens, oldIndex, newIndex);
        let newProject = project.setIn(['data', 'screens'], screens);
        this.props.onProjectChange(newProject);
    };

    render() {
        const {project, zoomFactor} = this.props;

        return (
            <SortableContainer
                axis="x"
                project={project}
                zoomFactor={zoomFactor}
                onSortEnd={this.onSortEnd}
            />
        )
    }
}

export default ScreenHeaders;