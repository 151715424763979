import React, {Component} from 'react';

const moment = require('moment');


class InvoiceEntry extends Component {
    getDate = (invoice) => {
        return moment(invoice.get('date') * 1000).format('LL');
    };

    getTotal = (invoice) => {
        return '$' + (invoice.get('total') / 100).toFixed(2);
    };

    render() {
        const {invoice} = this.props;
        const invoiceDate = this.getDate(invoice);
        const invoiceTotal = this.getTotal(invoice);

        return (
            <tr>
                <td>{invoice.get('number')}</td>
                <td>{invoiceDate}</td>
                <td>{invoiceTotal}</td>
                <td>
                    <a
                        href={invoice.get('pdf_url')}
                        className="btn btn-primary-outline"
                        target="_blank"
                        rel="noopener noreferrer"
                    >Get PDF</a>
                </td>
            </tr>
        );

    }
}


export default InvoiceEntry;