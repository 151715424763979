import React, {Component} from 'react';
import {getLanguageTitle} from '../../../../languages';
import ScreenshotSizeBlock from './ScreenshotSizeBlock';
import './screenshotLanguageBlock.scss';


class ScreenshotLanguageBlock extends Component {

    getSizeForUUID = (uuid) => {
        const {sizes} = this.props;
        for (let i=0; i<sizes.size; i++) {
            if (sizes.getIn([i, 'uuid']) === uuid) {
                return sizes.get(i);
            }
        }
    };

    render() {
        const {project, languageCode, screenshots, selectedScreenId} = this.props;

        return (
            <div className="sidebar-section">
                <p className="heading">{getLanguageTitle(languageCode)}</p>

                <div className="sidebar-section-inner">

                    <div className="screenshot-language-block">
                        {project.get('sizes').map(sizeUUID => {
                            return (
                                <ScreenshotSizeBlock
                                    key={sizeUUID}
                                    project={project}
                                    size={this.getSizeForUUID(sizeUUID)}
                                    languageCode={languageCode}
                                    screenshots={screenshots}
                                    selectedScreenId={selectedScreenId}
                                />
                            )
                        })}
                    </div>


                </div>
            </div>


        );
    }
}

export default ScreenshotLanguageBlock;