import React, {Component} from 'react';
import {CardNumberElement, CardExpiryElement, CardCvcElement} from '@stripe/react-stripe-js';
import * as Yup from "yup";
import {ErrorMessage, Field, Form, Formik} from "formik";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ccIconVisa from "../../Purchase/cc-icon-visa.svg";
import ccIconMastercard from "../../Purchase/cc-icon-mastercard.svg";
import ccIconAmex from "../../Purchase/cc-icon-amex.svg";
import ccIconDiners from "../../Purchase/cc-icon-diners.svg";
import ccIconJcb from "../../Purchase/cc-icon-jcb.svg";
import {ReactComponent as LockIcon} from "../../Purchase/lock.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";


const formSchema = Yup.object().shape({
    name: Yup.string()
        .required('Please enter a first name')
});


class CheckoutForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            cardNumberValid: true,
            cardExpiryValid: true,
            cardCVCValid: true
        }
    }

    handleChange = (change) => {
        switch (change.elementType) {
            case 'cardNumber':
                this.setState({cardNumberValid: true});
                break;

            case 'cardExpiry':
                this.setState({cardExpiryValid: true});
                break;

            case 'cardCvc':
                this.setState({cardCVCValid: true});
                break;

            default:
                console.log('unknown');
        }
    };

    createOptions = () => {
        return {
            style: {
                base: {
                    fontSize: '14px',
                    color: '#2b5672',
                    fontFamily: 'Roboto, sans-serif',
                    fontWeight: '300',
                    '::placeholder': {
                        color: '#aab7c4',
                    },
                    lineHeight: '36px'
                },
                invalid: {
                    color: '#9e2146',
                },
            }
        }
    };

    handleSubmit = async (values) => {
        const {stripe, elements} = this.props;
        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardNumberElement),
            billing_details: {
                name: values.name
            }
        });

        return {error: error, paymentMethod: paymentMethod}
    };

    render() {
        const {cardNumberValid, cardExpiryValid, cardCVCValid} = this.state;
        const {isPurchasing} = this.props;

        return (
            <div className="payment-form-container">
                <Formik
                    initialValues={{
                        name: ''
                    }}
                    validationSchema={formSchema}
                    onSubmit={(values, {setSubmitting}) => {

                        setSubmitting(true);

                        this.handleSubmit(values)
                            .then(({error, paymentMethod}) => {
                                if (error != null) {
                                    console.log('*** error', error);
                                    if (error.type === 'validation_error') {
                                        switch (error.code) {
                                            case 'incomplete_number':
                                            case 'invalid_number':
                                                this.setState({cardNumberValid: false});
                                                break;

                                            case 'incomplete_expiry':
                                            case 'invalid_expiry':
                                                this.setState({cardExpiryValid: false});
                                                break;

                                            case 'incomplete_cvc':
                                            case 'invalid_cvc':
                                                this.setState({cardCVCValid: false});
                                                break;

                                            default:
                                                console.log('unknown');
                                        }
                                    }
                                }
                                else {
                                    console.log('success. paymentMethod=', paymentMethod);
                                    this.props.onSubmitForm(paymentMethod.id, values);
                                }

                                setSubmitting(false);
                            });
                    }}
                >
                    {({isSubmitting}) => (
                        <Form>
                            <Row>
                                <Col sm={12}>
                                    <div className="cc-icons-container">
                                        <div className="cc-icon"
                                             style={{backgroundImage: `url(${ccIconVisa})`}}/>
                                        <div className="cc-icon"
                                             style={{backgroundImage: `url(${ccIconMastercard})`}}/>
                                        <div className="cc-icon"
                                             style={{backgroundImage: `url(${ccIconAmex})`}}/>
                                        <div className="cc-icon"
                                             style={{backgroundImage: `url(${ccIconDiners})`}}/>
                                        <div className="cc-icon"
                                             style={{backgroundImage: `url(${ccIconJcb})`}}/>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm={12}>

                                    <Row>
                                        <Col sm={4}>
                                            <div className="form-group">
                                                <label>Card number</label>
                                                <CardNumberElement
                                                    onBlur={this.handleBlur}
                                                    onChange={this.handleChange}
                                                    options={this.createOptions()}
                                                />
                                                {!cardNumberValid && (
                                                    <small className="form-text form-text-error">Please
                                                        enter a valid card number</small>
                                                )}
                                            </div>
                                        </Col>

                                        <Col sm={4}>
                                            <div className="form-group">
                                                <label>Exp Date</label>
                                                <CardExpiryElement
                                                    onBlur={this.handleBlur}
                                                    onChange={this.handleChange}
                                                    options={this.createOptions()}
                                                />
                                                {!cardExpiryValid && (
                                                    <small className="form-text form-text-error">Please
                                                        enter a valid card expiry date</small>
                                                )}
                                            </div>
                                        </Col>

                                        <Col sm={4}>
                                            <div className="form-group">
                                                <label>Security Code / CVC</label>
                                                <CardCvcElement
                                                    onBlur={this.handleBlur}
                                                    onChange={this.handleChange}
                                                    options={this.createOptions()}
                                                />
                                                {!cardCVCValid && (
                                                    <small className="form-text form-text-error">Please
                                                        enter a valid card security code</small>
                                                )}
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col sm={4}>
                                            <div className="form-group">
                                                <label>Name</label>
                                                <Field type="text" name="name"
                                                       className="form-control"/>
                                                <ErrorMessage name="name" component="small"
                                                              className="form-text form-text-error"/>
                                            </div>
                                        </Col>
                                    </Row>

                                    <div className="btn-container">
                                        <button
                                            type="submit"
                                            className="btn btn-primaryNew btn-submit"
                                            disabled={isSubmitting || isPurchasing}
                                        >
                                            {(isSubmitting || isPurchasing) &&
                                            <FontAwesomeIcon icon={faSpinner} spin/>
                                            }

                                            {!isSubmitting && !isPurchasing &&
                                            <div className="btn-inner">
                                                <LockIcon/>
                                                Update
                                            </div>
                                            }

                                        </button>
                                    </div>

                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }
}


export default CheckoutForm;
