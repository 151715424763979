import React, {Component} from 'react';

const classNames = require('classnames');


class PurchaseSummary extends Component {
    formatPrice = (n) => {
        return parseFloat(n).toFixed(2);
    };

    handleFrequencyClick = (newFrequency) => {
        const {frequency} = this.props;

        if (newFrequency !== frequency) {
            this.props.onChangeFrequency();
        }
    };

    render() {
        const {frequency, plan} = this.props;

        return (
            <div className="purchase-summary">

                <div className="choice-container">
                    <div
                        className={classNames('choice', frequency === 'monthly' ? 'selected' : '')}
                        onClick={this.handleFrequencyClick}
                    >
                        <p className="frequency">Monthly</p>
                        <p className="price">${this.formatPrice(plan.get('price_monthly'))}</p>
                        <p className="per">Per month</p>
                        <p className="billing-frequency">Billed monthly</p>
                    </div>

                    <div
                        className={classNames('choice', frequency === 'yearly' ? 'selected' : '')}
                        onClick={this.handleFrequencyClick}
                    >
                        <p className="frequency">Yearly</p>
                        <p className="price">${this.formatPrice(plan.get('price_yearly'))}</p>
                        <p className="per">Per month</p>
                        <p className="billing-frequency">Billed yearly</p>
                    </div>
                </div>

            </div>
        );
    }
}

export default PurchaseSummary;