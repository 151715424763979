import React, {Component} from 'react';
import './card.scss';
const classNames = require('classnames');


class Card extends Component {

    render() {
        const {header} = this.props;

        return (
            <div className={classNames('card', this.props.className)} style={this.props.style}>

                {header != null &&
                <div className="card-header">
                    <p>{header}</p>
                </div>
                }


                <div className="card-body">

                    {this.props.children}

                </div>
            </div>
        );
    }
}


export default Card;
