import React, {Component} from 'react';
import './sidebar.scss';
import SidebarAppOverview from './SidebarAppOverview';
import SidebarApps from './SidebarApps';
import SidebarNewProject from './SidebarNewProject';
import SidebarSettings from './SidebarSettings';
import { Button } from 'react-bootstrap';
import ModalWrapper from '../../Components/ImageLibrary/ModalWrapper';
import Purchase from '../../Pages/Purchase/Purchase';

class Sidebar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showPurchaseOption: false
        }
    }

    showPurchaseModal = (show) => {
        this.setState({
            showPurchaseOption: show
        })
    };

    render() {
        const {showPurchaseOption} = this.state;
        const {page, accountInfo} = this.props;
        const isTrialPlan = accountInfo.getIn(['plan', 'is_trial']);

        return (
            <div className="sidebar">

                {showPurchaseOption &&
                <ModalWrapper>
                    <Purchase
                        onHide={() => this.showPurchaseModal(false)}
                    />
                </ModalWrapper>
                }

                {page === 'appOverview' &&
                <SidebarAppOverview {...this.props}/>
                }

                {page === 'apps' &&
                <SidebarApps {...this.props}/>
                }

                {page === 'campaignsNew' &&
                <SidebarNewProject {...this.props}/>
                }

                {page === 'settings' &&
                <SidebarSettings {...this.props}/>
                }

                {isTrialPlan && (
                    <div className="upgrade-container">
                        <Button
                            variant='successNew'
                            onClick={() => this.showPurchaseModal(true)}
                        >
                            Upgrade
                        </Button>

                    </div>
                )}

            </div>
        );
    }
}


export default Sidebar;