import React, {Component} from 'react';
import Button from "../Button/Button";
import {apiHost, maxFileUploadSizeMB} from "../../constants";
import auth0Client from "../../Auth";


class UploadImage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isUploading: false,
            fileTooLargeError: false
        }
    };

    handleImageUpload = (e) => {
        let file = e.target.files[0];

        const sizeMB = file.size / 1000000;

        if (sizeMB > maxFileUploadSizeMB) {
            this.setState({
                fileTooLargeError: true
            });
        } else {
            this.setState({
                fileTooLargeError: false,
                isUploading: true
            });

            let formData = new FormData();
            formData.append('upload', file);

            fetch(apiHost + '/images/upload', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${auth0Client.getIdToken()}`,
                },
                body: formData
            })
                .then(() => {
                    console.log('upload done.');
                    this.setState({isUploading: false});
                    this.props.onUploadFinished();
                })

                .catch(err => {
                    console.error('upload image error:', err);
                });
        }
    };

    render() {
        const {isUploading, fileTooLargeError} = this.state;

        return (
            <div className="image-library-page upload-image-page">
                <h1>Upload Image</h1>

                <div className="image-upload-control">
                    <form encType="multipart/form-data" className="upload-btn-wrapper">
                        <Button
                            className="btn-upload-image"
                            primary
                            waiting={isUploading}
                        >Upload image</Button>

                        <input
                            type="file"
                            accept="image/*"
                            onChange={(e) => this.handleImageUpload(e, 'replace')}
                        />
                    </form>
                </div>

                {fileTooLargeError && (
                    <p className="file-too-large-error">Image was too large. Max file size is 10MB.</p>
                )}
            </div>
        );
    }
}

export default UploadImage;
