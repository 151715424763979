import {apiHost} from "../constants";
import auth0Client from "../Auth";
const { fromJS } = require('immutable');

export const FETCH_FRAME_IMAGES = 'FETCH_FRAME_IMAGES';
export const RECEIVE_FRAME_IMAGES = 'RECEIVE_FRAME_IMAGES';

export const SET_ACTIVE_TOOLBAR_LEFT_ITEM = 'SET_ACTIVE_TOOLBAR_LEFT_ITEM';
export const SET_ACTIVE_SIDEBAR = 'SET_ACTIVE_SIDEBAR';
export const SET_ACTIVE_SCREEN = 'SET_ACTIVE_SCREEN';

export const ADD_RENDERING_SCREEN_ITEM = 'ADD_RENDERING_SCREEN_ITEM';
export const REMOVE_RENDERING_SCREEN_ITEM = 'REMOVE_RENDERING_SCREEN_ITEM';

export const SET_ZOOM_FACTOR = 'SET_ZOOM_FACTOR';


// frame images
export function requestFrameImages() {
    return {
        type: FETCH_FRAME_IMAGES
    }
}


export function receiveFrameImages(frameImages) {
    return {
        type: RECEIVE_FRAME_IMAGES,
        frameImages
    }
}


function fetchFrameImages() {
    return dispatch => {
        dispatch(requestFrameImages());
        const url = apiHost + '/editor/frame-images';
        return fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${auth0Client.getIdToken()}`
            }
        })
            .then(response => response.json())
            .then(json => {
                dispatch(receiveFrameImages(fromJS(json)))
            })
            .catch(err => {
                console.error('fetchFrameImages error:', err);
            });
    }
}


function shouldFetchFrameImages(state) {
    const editorReducer = state.editorReducer;
    if (editorReducer.isFetchingFrameImages || editorReducer.frameImages != null) {
        return false;
    }
    return true;
}


export function fetchFrameImagesIfNeeded() {
    return (dispatch, getState) => {
        if (shouldFetchFrameImages(getState())) {
            return dispatch(fetchFrameImages())
        } else {
            return Promise.resolve()
        }
    }
}

export function setActiveToolbarLeftItem(item) {
    return {
        type: SET_ACTIVE_TOOLBAR_LEFT_ITEM,
        item
    }
}

export function setActiveSidebar(sidebar) {
    return {
        type: SET_ACTIVE_SIDEBAR,
        sidebar
    }
}

export function setActiveScreen(screenId) {
    return {
        type: SET_ACTIVE_SCREEN,
        screenId
    }
}

// rendering screen
export function addRenderingScreenItem(screenItemId) {
    return {
        type: ADD_RENDERING_SCREEN_ITEM,
        screenItemId
    }
}

export function removeRenderingScreenItem(screenItemId) {
    return {
        type: REMOVE_RENDERING_SCREEN_ITEM,
        screenItemId
    }
}


export function setZoomFactor(zoomFactor) {
    return {
        type: SET_ZOOM_FACTOR,
        zoomFactor
    }
}