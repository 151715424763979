import React, {Component} from 'react';
import {SketchPicker} from 'react-color'
import {GradientPickerPopover} from 'react-linear-gradient-picker';
import './styles.scss';
const { fromJS } = require('immutable');


function addOpacityToHex(hex, a = 1) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + a + ')';
    }
    if (/rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)/.test(hex)) { /** RGB color */
        return hex.replace('rgb', 'rgba').replace(')', `, ${a})`);
    }
    throw new Error('Bad Hex');
}

const WrappedSketchPicker = ({onSelect, ...rest}) => (
    <SketchPicker {...rest}
                  color={addOpacityToHex(rest.color, rest.opacity)}
                  onChange={c => {
                      onSelect(c.hex, c.rgb.a);
                  }}/>
);

class GradientControl extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false
        }
    }

    setPalette = (palette) => {
        const {gradient} = this.props;
        const _gradient = gradient.set('stops', fromJS(palette));
        this.props.onGradientChange(_gradient);
    };

    setAngle = (angle) => {
        const {gradient} = this.props;
        const _gradient = gradient.set('angle', angle);
        this.props.onGradientChange(_gradient);
    };

    setOpen = (open) => {
        this.setState({
            open: open
        });
    };

    render() {
        const {open} = this.state;
        const {gradient} = this.props;

        return (
            <div className="gradient-control">
                <GradientPickerPopover {...{
                    open,
                    setOpen: this.setOpen,
                    angle: gradient.get('angle'),
                    setAngle: this.setAngle,
                    showAnglePicker: true,
                    width: 220,
                    maxStops: 3,
                    paletteHeight: 32,
                    palette: gradient.get('stops').toJS(),
                    onPaletteChange: this.setPalette
                }}>
                    <WrappedSketchPicker/>
                </GradientPickerPopover>
            </div>
        );
    }
}


export default GradientControl;