import React, { Component } from 'react';
import {connect} from 'react-redux'
import {Formik, Form, Field, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import Button from '../../../Components/Button/Button';
import {updateProfileAction, fetchCurrentUserIfNeeded} from '../../../actions/userActions';

const formSchema = Yup.object().shape({
	email: Yup.string()
    	.email('Please enter a valid email address.')
    	.required('Email address required'),
});


class EditingProfileInfo extends Component {
	render() {
		const { currentUser } = this.props;

		if (currentUser != null) {
			return (
				<div className="profile-info">

					<Formik
						initialValues={
							{
								firstName: currentUser.get('first_name') || '',
								lastName: currentUser.get('last_name') || '',
								email: currentUser.get('email')
							}
						}
						validationSchema={formSchema}
						onSubmit={(values, { setSubmitting }) => {

							const profileInfo = {
								first_name: values.firstName,
								last_name: values.lastName,
								email: values.email
							};
							
							this.props.dispatch(updateProfileAction(profileInfo))
								.then(() => {
									this.props.dispatch(fetchCurrentUserIfNeeded(true))
										.then(() => {
											setSubmitting(false);
											this.props.onUpdateBtnClick();
										})
										.catch(err => {
											console.error('error:', err);
										});
								})
								.catch(err => {
									console.error('update profile error:', err);
								});
						}}
					>
						{({ isSubmitting }) => (
							<Form>
								<div className="form-group row">
									<label className="col-sm-3 col-form-label">First name</label>
									<div className="col-sm-9">
										<Field type="text" name="firstName" className="form-control"
											placeholder="First name..." />
										<ErrorMessage name="firstName" component="small"
											className="form-text form-text-error" />
									</div>
								</div>

								<div className="form-group row">
									<label className="col-sm-3 col-form-label">Last name</label>
									<div className="col-sm-9">
										<Field type="text" name="lastName" className="form-control"
											placeholder="Last name..." />
										<ErrorMessage name="lastName" component="small"
											className="form-text form-text-error" />
									</div>
								</div>

								<div className="form-group row">
									<label className="col-sm-3 col-form-label">Email</label>
									<div className="col-sm-9">
										<Field type="text" name="email" className="form-control"
											placeholder="Email..." />
										<ErrorMessage name="email" component="small"
											className="form-text form-text-error" />
									</div>
								</div>

						

								<Button
									type="submit"
									className="btn-primary"
									waiting={isSubmitting}
								>
									Update
                                </Button>

							</Form>

						)}
					</Formik>

				</div>
			);
		} else {
			return (
				<div />
			)
		}
	}
}

export default connect()(EditingProfileInfo);