import {
    FETCH_PROJECTS,
    RECEIVE_PROJECTS,
    UPDATE_PROJECT,
    DELETE_PROJECT,
    ADD_PROJECT
} from '../actions/projectActions';


function getProjectIdx(projects, projectUUID) {
    for (let i = 0; i < projects.size; i++) {
        if (projects.getIn([i, 'uuid']) === projectUUID) {
            return  i;
        }
    }
}


function projectReducer(
    state = {
        isFetchingProjects: false,
        appUUID: null,
        projects: null
    },
    action
) {
    let i, projectIdx, projects;
    switch (action.type) {
        case FETCH_PROJECTS:
            return Object.assign({}, state, {
                projects: null,
                isFetchingProjects: true
            });

        case RECEIVE_PROJECTS:
            return Object.assign({}, state, {
                isFetchingProjects: false,
                projects: action.projects,
            });

        case UPDATE_PROJECT:
            projects = state.projects;
            for (i = 0; i < projects.size; i++) {
                if (projects.getIn([i, 'uuid']) === action.newProject.get('uuid')) {
                    projects = projects.set(i, action.newProject);
                    break;
                }
            }

            return Object.assign({}, state, {
                projects: projects,
                projectsHaveUnsavedChanges: true
            });

        case DELETE_PROJECT:
            projectIdx = getProjectIdx(state.projects, action.projectUUID);
            projects = state.projects.delete(projectIdx);

            return Object.assign({}, state, {
                projects: projects
            });

        case ADD_PROJECT:
            return Object.assign({}, state, {
                projects: state.projects.push(action.project)
            });

        default:
            return state
    }
}

export default projectReducer;