import React, { Component } from 'react';



class ProfileInfo extends Component {
	render() {
		const { currentUser } = this.props;
		const firstName = currentUser.get('first_name') || '';
		const lastName = currentUser.get('last_name') || '';
		const email = currentUser.get('email');

			return (
				<div className="profile-info">
					<form>
						<div className="form-group row">
							<label className="col-sm-3 col-form-label">First name</label>
							<div className="col-sm-9">
								<input
									type="text"
									className="form-control"
									value={firstName}
									disabled={true}
								/>
							</div>
						</div>

						<div className="form-group row">
							<label className="col-sm-3 col-form-label">Last name</label>
							<div className="col-sm-9">
								<input
									type="text"
									className="form-control"
									value={lastName}
									disabled={true}
								/>
							</div>
						</div>

						<div className="form-group row">
							<label className="col-sm-3 col-form-label">Email</label>
							<div className="col-sm-9">
								<input
									type="text"
									className="form-control"
									value={email}
									disabled={true}
								/>
							</div>
						</div>
					</form>
				</div>
			);
	}
}

export default ProfileInfo;