import React, {Component} from 'react';
import {connect} from 'react-redux'
import {Formik, Form, Field, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import Button from '../../../Components/Button/Button';
import {updateAccountAction} from '../../../actions/accountActions';
import {fetchCurrentUserIfNeeded} from '../../../actions/userActions';

const formSchema = Yup.object().shape({
    accountName: Yup.string()
        .required('Please enter an account name'),
});


class EditingAccountInfo extends Component {
    render() {
        const {accountInfo} = this.props;

        return (
            <div className="profile-info">

                <Formik
                    initialValues={
                        {
                            accountName: accountInfo.get('name') || ''
                        }
                    }
                    validationSchema={formSchema}
                    onSubmit={(values, {setSubmitting}) => {

                        const accountInfo = {
                            account_name: values.accountName
                        };

                        this.props.dispatch(updateAccountAction(accountInfo))
                            .then(() => {
                                this.props.dispatch(fetchCurrentUserIfNeeded(true))
                                    .then(() => {
                                        setSubmitting(false);
                                        this.props.onUpdateBtnClick();
                                    })
                                    .catch(err => {
                                        console.error('error:', err);
                                    });
                            })
                            .catch(err => {
                                console.error('update profile error:', err);
                            });
                    }}
                >
                    {({isSubmitting}) => (
                        <Form>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">Account name</label>
                                <div className="col-sm-9">
                                    <Field type="text" name="accountName" className="form-control"
                                           placeholder="Account name..."/>
                                    <ErrorMessage name="accountName" component="small"
                                                  className="form-text form-text-error"/>
                                </div>
                            </div>

                            <Button
                                type="submit"
                                className="btn-primary"
                                waiting={isSubmitting}
                            >
                                Update
                            </Button>

                        </Form>

                    )}
                </Formik>

            </div>
        );

    }
}

export default connect()(EditingAccountInfo);