import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from "react-router-dom";
import {Link} from "react-router-dom";
import {fetchApps, updateAppAction} from "../../actions/appActions";
import LoadingSpinner from '../../Components/LoadingSpinner/LoadingSpinner';
import Page from '../../Components/Page/Page';
import Sidebar from "../../Components/Sidebar/Sidebar";
import AppNotValid from './AppNotValid/AppNotValid';
import {
    fetchProjects,
    deleteProjectAction,
    duplicateProjectAction,
    updateProject,
    saveProject
} from "../../actions/projectActions";
import {fetchSizesIfNeeded} from "../../actions/sizeActions";
import './stylesAppOverview.scss';
import { Button, NavDropdown } from 'react-bootstrap';
import {faPlus, faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Card from "../../Components/Card/Card";
import ImageLibrary from '../../Components/ImageLibrary/ImageLibrary';
import NoProjectsCard from './NoProjectsCard';
const { List } = require('immutable');


class AppOverview extends Component {
    constructor(props) {
        super(props);

        this.state = {
            appUUID: null,
            mounted: false,
            showImageLibrary: false
        };

    }

    componentDidMount() {
        this.props.dispatch(fetchApps());
        this.props.dispatch(fetchProjects());
        this.props.dispatch(fetchSizesIfNeeded());

        const appUUID = this.props.match.params.appUUID;
        this.setState({
            appUUID: appUUID,
            mounted: true
        });
    }

    getProjectsForApp = (app) => {
        const {projects} = this.props;
        let filteredProjects = List();
        for (let i = 0; i < projects.size; i++) {
            if (projects.getIn([i, 'app_uuid']) === app.get('uuid')) {
                filteredProjects = filteredProjects.push(projects.get(i));
            }
        }
        return filteredProjects;
    };

    getAppForUUID = (uuid) => {
        const {apps} = this.props;
        for (let i = 0; i < apps.size; i++) {
            if (apps.getIn([i, 'uuid']) === uuid) {
                return apps.get(i);
            }
        }
    };

    getPlatforms = (project) => {
        const {sizes} = this.props;
        let platforms = [];

        sizes.forEach(size => {
           if (project.get('sizes').contains(size.get('uuid'))) {
               let platform = size.get('platform');
               if (!platforms.includes(platform)) {
                   platforms.push(platform);
               }
           }
        });

        return platforms;
    };

    handleAppChange = (newApp) => {
        this.props.dispatch(updateAppAction(newApp));
    };

    handleAppAction = (app, action) => {
        let newApp;
        switch (action) {
            case 'changeImage':
                this.setImageLibrary(true);
                break;

            case 'rename':
                const appName = window.prompt('Please enter a new name for the app', app.get('name'));
                if (appName != null && appName.length > 0) {
                    newApp = app.set('name', appName);
                    this.handleAppChange(newApp);
                }
                break;

            case 'delete':
                if (window.confirm('Do you really want to delete this app: ' + app.get('name'))) {


                }
                break;

            default:
                console.log('unknown action', action);
        }
    };

    handleProjectChange = (newProject) => {
        this.props.dispatch(updateProject(newProject));
        this.props.dispatch(saveProject(newProject));
    };

    handleProjectAction = (project, action) => {
        let newProject, projectName;
        switch (action) {
            case 'rename':
                projectName = window.prompt('Please enter a new name for the project', project.get('name'));
                if (projectName != null && projectName.length > 0) {
                    newProject = project.set('name', projectName);
                    this.handleProjectChange(newProject);
                }
                break;

            case 'delete':
                if (window.confirm('Do you really want to delete this project: ' + project.get('name'))) {
                    this.props.dispatch(deleteProjectAction(project.get('uuid')));
                }
                break;

            case 'duplicate':
                projectName = window.prompt('Please enter a name for the new project', project.get('name') + ' (copy)');
                if (projectName != null && projectName.length > 0) {
                    this.props.dispatch(duplicateProjectAction(project.get('uuid'), projectName));
                }
                break;

            default:
                console.log('unknown action', action);
        }
    };

    setImageLibrary = (show) => {
        this.setState({
            showImageLibrary: show
        });
    };

    handleImageSelected = (image) => {
        const {appUUID} = this.state;
        const app = this.getAppForUUID(appUUID);
        const newApp = app.set('image_url', image.get('url'));
        this.handleAppChange(newApp);

        this.setState({
            showImageLibrary: false
        });
    };

    render() {
        const {apps, projects, currentUser, accountInfo, sizes} = this.props;
        const {appUUID, mounted, showImageLibrary} = this.state;

        if (mounted && currentUser != null && apps != null && projects != null && sizes != null) {

            const app = this.getAppForUUID(appUUID);

            if (app != null) {
                const filteredProjects = this.getProjectsForApp(app);

                return (
                    <div className="main">
                        <Sidebar
                            page="appOverview"
                            accountInfo={accountInfo}
                        />

                        <Page
                            className="page-app-overview"
                            currentUser={currentUser}
                            accountInfo={accountInfo}
                        >

                            <ImageLibrary
                                show={showImageLibrary}
                                onHide={() => this.setImageLibrary(false)}
                                onImageSelected={this.handleImageSelected}
                            />

                            <div>
                                <div className="row">
                                    <div className="col-sm-12">

                                        <div className="header-container">
                                            <div className="app-icon-container" style={{backgroundImage: 'url(' + app.get('image_url') + ')'}}/>
                                            <div className="text-container">
                                                <h1>{app.get('name')}</h1>
                                            </div>


                                        </div>

                                        <div className="app-container">
                                            <Card header="App Summary">
                                                <div className="info-cols">
                                                    <div className="info-col">
                                                        <p>Projects: {filteredProjects.size}</p>

                                                        <NavDropdown title="Options">
                                                            <NavDropdown.Item
                                                                onClick={() => this.handleAppAction(app, 'rename')}
                                                            >Rename</NavDropdown.Item>

                                                            <NavDropdown.Item
                                                                onClick={() => this.handleAppAction(app, 'changeImage')}
                                                            >Change App image</NavDropdown.Item>

                                                            <NavDropdown.Item
                                                                onClick={() => this.handleAppAction(app, 'delete')}
                                                            >Delete</NavDropdown.Item>
                                                        </NavDropdown>
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>

                                        <div className="projects-container">
                                            {filteredProjects.size > 0 &&
                                            <Card header="Projects">
                                                <div className="btn-add-project-container">
                                                    <Button
                                                        variant="primaryOutlineNew"
                                                        as={Link}
                                                        to='/projects/new'
                                                    >
                                                        <FontAwesomeIcon icon={faPlus}/>
                                                        New
                                                    </Button>
                                                </div>

                                                <div className="projects-list">
                                                    {filteredProjects.map(project => {
                                                        return (
                                                            <div className="project-container"
                                                                 key={project.get('uuid')}
                                                            >

                                                                <p className="heading">{project.get('name')}</p>

                                                                <div className="project-cols">
                                                                    <div className="project-col">
                                                                        <p>Platforms: {this.getPlatforms(project)}</p>
                                                                        <p>Screen
                                                                            sizes: {project.get('sizes').count()}</p>

                                                                        <Link
                                                                            to={'/editor/project/' + project.get('uuid')}
                                                                        >
                                                                            <FontAwesomeIcon icon={faArrowRight}/> Go to
                                                                            editor
                                                                        </Link>
                                                                    </div>

                                                                    <div className="project-col">
                                                                        <p>Languages: {this.getPlatforms(project)}</p>

                                                                        <p>Last
                                                                            modified: {this.getPlatforms(project)}</p>

                                                                        <NavDropdown title="Options">
                                                                            <NavDropdown.Item
                                                                                onClick={() => this.handleProjectAction(project, 'rename')}
                                                                            >Rename</NavDropdown.Item>
                                                                            <NavDropdown.Item
                                                                                onClick={() => this.handleProjectAction(project, 'duplicate')}
                                                                            >Duplicate</NavDropdown.Item>
                                                                            <NavDropdown.Item
                                                                                onClick={() => this.handleProjectAction(project, 'delete')}
                                                                            >Delete</NavDropdown.Item>
                                                                        </NavDropdown>

                                                                    </div>


                                                                </div>

                                                            </div>

                                                        )
                                                    })}
                                                </div>
                                            </Card>
                                            }

                                            {filteredProjects.size === 0 &&
                                                <NoProjectsCard/>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Page>
                    </div>
                )

            } else {
                //app doesn't exist
                return (
                    <AppNotValid/>
                )
            }
        } else {
            return (
                <LoadingSpinner/>
            )
        }
    }
}


const mapStateToProps = (state) => {
    return {
        apps: state.appReducer.apps,
        projects: state.projectReducer.projects,
        isFetchingProjects: state.projectReducer.isFetchingProjects,
        sizes: state.sizeReducer.sizes
    }
};

export default withRouter(connect(mapStateToProps)(AppOverview));